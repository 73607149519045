// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

type Props = {
  user: UserState,
  expanded: boolean,
  submitLogin: typeof submitLogin,
  resetLoginFailed: typeof resetLoginFailed
};

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 445
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 445
    },
    margin: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 50
  },
  textField: {
    width: '80%'
  },
  actionWrap: {
    justifyContent: 'space-between'
  },
  submitButton: {
    marginLeft: 'auto'
  },
  progress: {
    margin: theme.spacing(2)
  },
  signInButtonWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
}));

const Login = ({
  user: { loggingIn, loginFailed },
  expanded,
  submitLogin,
  resetLoginFailed
}: Props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    resetLoginFailed();
  }, [resetLoginFailed]);

  if (expanded) {
    return (
      <div>
        <Typography variant="h2">Collapsed Login</Typography>
      </div>
    );
  }
  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        submitLogin(email, password);
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            Login
          </Typography>
          <TextField
            error={loginFailed}
            disabled={loggingIn}
            id="standard-name"
            label="Email"
            className={classes.textField}
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={loginFailed}
            disabled={loggingIn}
            id="standard-name"
            label="Password"
            type="password"
            className={classes.textField}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            margin="normal"
            variant="outlined"
          />
          {loggingIn && <CircularProgress className={classes.progress} />}
        </CardContent>
        <CardActions className={classes.actionWrap}>
          <Grid container spacing={0} justify="space-between">
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Link component={RouterLink} to="/auth/register">
                  Create an account
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link component={RouterLink} to="/auth/reset">
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.signInButtonWrap}>
              <Button
                disabled={loggingIn}
                type="submit"
                className="submitButton"
                size="small"
                color="primary"
                variant="outlined"
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
};

export default Login;
