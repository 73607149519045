/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import { combineEpics, ofType } from 'redux-observable';
import {
  switchMap,
  mergeMap,
  map,
  withLatestFrom,
  delay,
  takeUntil,
  catchError
} from 'rxjs/operators';

import { of, BehaviorSubject } from 'rxjs';

import {
  getServerDoor,
  getShareDoorQueryString
} from '../../utils/serverObjects';

import { store } from '../../App';
import getResponseToMessage from '../../utils/messageHandlers';
import apiService, { REQUEST_TIMED_OUT } from '../../utils/api'; // eslint-disable-line

const { REACT_APP_USE_STAGING_MQTT } = process.env;

const KEEP_UP_BIT = 1;
const AUTO_RETRACT_BIT = 1 << 1;

export const DoorBits = {
  KEEP_UP_BIT,
  AUTO_RETRACT_BIT
};

const host = `wss://iot.hightechpet.com`;
const stagingHost = `ws://staging.iot.hightechpet.com`;
const port = 443;
const stagingPort = 80;
const emitter = require('emitter-io');

const NO_ACTION = 'door/NO_ACTION';

const SELECT_DOOR = 'door/SELECT_DOOR';
const DESELECT_DOOR = 'door/DESELECT_DOOR';

const CONNECT_MQTT_SERVER = 'door/CONNECT_MQTT_SERVER';
const MQTT_CONNECTION_DONE = 'door/MQTT_CONNECTION_DONE';

const CONNECT_REMOTE_DOOR = 'door/CONNECT_REMOTE_DOOR';
const REMOTE_DOOR_CONNECTED = 'door/REMOTE_DOOR_CONNECTED';
const REMOTE_DOOR_CONNECTION_FAILED = 'door/REMOTE_DOOR_CONNECTION_FAILED';
const REMOTE_DOOR_DISCONNECTED = 'door/REMOTE_DOOR_DISCONNECTED';

const REMOTE_DOOR_PRESENT = 'door/REMOTE_DOOR_PRESENT';
const REMOTE_DOOR_NOTPRESENT = 'door/REMOTE_DOOR_NOTPRESENT';

const REMOTE_DOOR_SUBSCRIBE = 'door/REMOTE_DOOR_SUBSCRIBE';
const REMOTE_DOOR_UNSUBSCRIBE = 'door/REMOTE_DOOR_UNSUBSCRIBE';

const OPEN_AND_HOLD = 'door/OPEN_AND_HOLD';
const OPEN_AND_HOLD_SENT = 'door/OPEN_AND_HOLD_SENT';

const OPEN_DOOR = 'door/OPEN_DOOR';
const DOOR_OPEN_SENT = 'door/DOOR_OPEN_SENT';

const CLOSE_DOOR = 'door/CLOSE_DOOR';
const CLOSE_DOOR_SENT = 'door/DOOR_CLOSE_SENT';

const POWER_ON_DOOR = 'door/POWER_ON_DOOR';
const POWER_ON_DOOR_SENT = 'door/POWER_ON_DOOR_SENT';
const POWER_OFF_DOOR = 'door/POWER_OFF_DOOR';
const POWER_OFF_DOOR_SENT = 'door/POWER_OFF_DOOR_SENT';

const RESTART_DOOR = 'door/RESTART_DOOR';
const RESTART_DOOR_SENT = 'door/RESTART_DOOR_SENT';

const CHECK_RESET_REASON = 'door/CHECK_RESET_REASON';
const CHECK_RESET_REASON_SENT = 'door/CHECK_RESET_REASON_SENT';
const CHECK_RESET_REASON_RECEIVED = 'door/CHECK_RESET_REASON_RECEIVED';

const FORCE_WDT_TIMEOUT = 'door/FORCE_WDT_TIMEOUT';
const FORCE_WDT_TIMEOUT_SENT = 'door/FORCE_WDT_TIMEOUT_SENT';

const GET_DOOR_INFO = 'door/GET_DOOR_INFO';
const GET_DOOR_INFO_FAILED = 'door/GET_DOOR_INFO_FAILED';
const STARTED_DOOR_INFO = 'door/STARTED_DOOR_INFO';

const GOT_DOOR_INFO = 'door/GOT_DOOR_INFO';
const INCREMENT_INFO_STATE = 'door/INCREMENT_INFO_STATE';
const DECREMENT_INFO_STATE = 'door/DECREMENT_INFO_STATE';
const INCREMENT_UPDATE_INFO_STATE = 'door/INCREMENT_UPDATE_INFO_STATE';
const DECREMENT_UPDATE_INFO_STATE = 'door/DECREMENT_UPDATE_INFO_STATE';

const ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS =
  'door/ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS';
const ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS =
  'door/ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS';
const DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS =
  'door/DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS';
const DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS =
  'door/DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS';

const ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS =
  'door/ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS';
const ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS =
  'door/ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS';
const DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS =
  'door/DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS';
const DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS =
  'door/DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS';

const ENABLE_LOW_BATTERY_NOTIFICATIONS =
  'door/ENABLE_LOW_BATTERY_NOTIFICATIONS';
const DISABLE_LOW_BATTERY_NOTIFICATIONS =
  'door/DISABLE_LOW_BATTERY_NOTIFICATIONS';

const SET_NOTIFICATIONS = 'door/SET_NOTIFICATIONS';

const SET_HOLD_TIME = 'door/SET_HOLD_TIME';
const GOT_HOLD_TIME = 'door/GOT_HOLD_TIME';

const SET_DOOR_NAME = 'door/SET_DOOR_NAME';
const GET_DOOR_NAME = 'door/GET_DOOR_NAME';
const GOT_DOOR_NAME = 'door/GOT_DOOR_NAME';

const ENABLE_INSIDE = 'door/ENABLE_INSIDE';
const DISABLE_INSIDE = 'door/DISABLE_INSIDE';
const INSIDE_ENABLED = 'door/INSIDE_ENABLED';
const INSIDE_DISABLED = 'door/INSIDE_DISABLED';
const INSIDE_ENABLE_FAILED = 'door/INSIDE_ENABLE_FAILED';
const INSIDE_CHANGED = 'door/INSIDE_CHANGED';
const OUTSIDE_CHANGED = 'door/OUTSIDE_CHANGED';

const ENABLE_OUTSIDE = 'door/ENABLE_OUTSIDE';
const DISABLE_OUTSIDE = 'door/DISABLE_OUTSIDE';
const OUTSIDE_ENABLED = 'door/OUTSIDE_ENABLED';
const OUTSIDE_DISABLED = 'door/OUTSIDE_DISABLED';
const OUTSIDE_ENABLE_FAILED = 'door/OUTSIDE_ENABLE_FAILED';

const GET_DOOR_STATUS = 'door/GET_DOOR_STATUS';
const DOOR_STATUS_UPDATED = 'door/DOOR_STATUS_UPDATED';

const GET_SETTINGS = 'door/GET_SETTINGS';
const GOT_SETTINGS = 'door/GOT_SETTINGS';

const GET_NOTIFICATIONS = 'door/GET_NOTIFICATIONS';
const GOT_NOTIFICATIONS = 'door/GOT_NOTIFICATIONS';

const GET_DOOR_POWER = 'door/GET_DOOR_POWER';
const GOT_DOOR_POWER = 'door/GOT_DOOR_POWER';

const GET_AC_VOLTAGE = 'door/GET_AC_VOLTAGE';
const GOT_AC_VOLTAGE = 'door/GOT_AC_VOLTAGE';

const GET_DOOR_BATTERY = 'door/GET_DOOR_BATTERY';
const GOT_DOOR_BATTERY = 'door/GOT_DOOR_BATTERY';

const GET_SENSORS = 'door/GET_SENSORS';
const GOT_SENSORS = 'door/GOT_SENSORS';

const GET_HW_INFO = 'door/GET_HW_INFO';
const GOT_HW_INFO = 'door/GOT_HW_INFO';

const GET_TIMEZONE = 'door/GET_TIMEZONE';
const SET_TIMEZONE = 'door/SET_TIMEZONE';
const GOT_TIMEZONE = 'door/GOT_TIMEZONE';

const GET_TIME = 'door/GET_TIME';
const SET_TIME = 'door/SET_TIME';
const GOT_TIME = 'door/GOT_TIME';

const GET_TIMERS_ENABLED = 'door/GET_TIMERS_ENABLED';
const GOT_TIMERS_ENABLED = 'door/GOT_TIMERS_ENABLED';

const ENABLE_TIMERS = 'door/ENABLE_TIMERS';
const DISABLE_TIMERS = 'door/DISABLE_TIMERS';

const ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK =
  'door/ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK';
const DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK =
  'door/DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK';

const ENABLE_CMD_LOCKOUT = 'door/ENABLE_CMD_LOCKOUT';
const DISABLE_CMD_LOCKOUT = 'door/DISABLE_CMD_LOCKOUT';

const ENABLE_AUTO_RETRACT = 'door/ENABLE_AUTO_RETRACT';
const DISABLE_AUTO_RETRACT = 'door/DISABLE_AUTO_RETRACT';

const SET_SENSOR_TRIGGER_VOLTAGE = 'door/SET_SENSOR_TRIGGER_VOLTAGE';
const GET_SENSOR_TRIGGER_VOLTAGE = 'door/GET_SENSOR_TRIGGER_VOLTAGE';
const GOT_SENSOR_TRIGGER_VOLTAGE = 'door/GOT_SENSOR_TRIGGER_VOLTAGE';

const SET_SLEEP_SENSOR_TRIGGER_VOLTAGE =
  'door/SET_SLEEP_SENSOR_TRIGGER_VOLTAGE';
const GET_SLEEP_SENSOR_TRIGGER_VOLTAGE =
  'door/GET_SLEEP_SENSOR_TRIGGER_VOLTAGE';
const GOT_SLEEP_SENSOR_TRIGGER_VOLTAGE =
  'door/GOT_SLEEP_SENSOR_TRIGGER_VOLTAGE';

const SET_WAKEUP_COUNT_THRESHOLD = 'door/SET_WAKEUP_COUNT_THRESHOLD';
const GET_WAKEUP_COUNT_THRESHOLD = 'door/GET_WAKEUP_COUNT_THRESHOLD';
const GOT_WAKEUP_COUNT_THRESHOLD = 'door/GOT_WAKEUP_COUNT_THRESHOLD';

const SET_NO_ENERGY_COUNT_THRESHOLD = 'door/SET_NO_ENERGY_COUNT_THRESHOLD';
const GET_NO_ENERGY_COUNT_THRESHOLD = 'door/GET_NO_ENERGY_COUNT_THRESHOLD';
const GOT_NO_ENERGY_COUNT_THRESHOLD = 'door/GOT_NO_ENERGY_COUNT_THRESHOLD';

const SET_TRIGGER_VALID_COUNT = 'door/SET_TRIGGER_VALID_COUNT';
const GET_TRIGGER_VALID_COUNT = 'door/GET_TRIGGER_VALID_COUNT';
const GOT_TRIGGER_VALID_COUNT = 'door/GOT_TRIGGER_VALID_COUNT';

const SET_TIME_C = 'door/SET_TIME_C';
const GET_TIME_C = 'door/GET_TIME_C';
const GOT_TIME_C = 'door/GOT_TIME_C';

const SET_TIME_D = 'door/SET_TIME_D';
const GET_TIME_D = 'door/GET_TIME_D';
const GOT_TIME_D = 'door/GOT_TIME_D';

const SET_TIME_F = 'door/SET_TIME_F';
const GET_TIME_F = 'door/GET_TIME_F';
const GOT_TIME_F = 'door/GOT_TIME_F';

const GET_SCHEDULE_LIST = 'door/GET_SCHEDULE_LIST';
const GET_SCHEDULE = 'door/GET_SCHEDULE';
const GOT_SCHEDULE_LIST = 'door/GOT_SCHEDULE_LIST';
const STAGE_SCHEDULE = 'door/STAGE_SCHEDULE';
const STAGE_SCHEDULE_SUCCESS = 'door/STAGE_SCHEDULE_SUCCESS';
const SET_SCHEDULE = 'door/SET_SCHEDULE';
const SET_SCHEDULE_SUCCESS = 'door/SET_SCHEDULE_SUCCESS';
const STAGE_DELETE_SCHEDULE = 'door/STAGE_DELETE_SCHEDULE';
const DELETE_SCHEDULE = 'door/DELETE_SCHEDULE';
const DELETE_SCHEDULE_SUCCESS = 'door/DELETE_SCHEDULE_SUCCESS';
const GOT_SCHEDULE = 'door/GOT_SCHEDULE';

const CLEAR_SCHEDULES = 'door/CLEAR_SCHEDULES';

const GET_DOOR_HAS_REMOTE_ID = 'door/GET_DOOR_HAS_REMOTE_ID';
const GOT_DOOR_HAS_REMOTE_ID = 'door/GOT_DOOR_HAS_REMOTE_ID';

const SET_DOOR_REMOTE_ID = 'door/SET_DOOR_REMOTE_ID';
const SET_DOOR_REMOTE_ID_SUCCESS = 'door/SET_DOOR_REMOTE_ID_SUCCESS';

const GET_DOOR_HAS_REMOTE_KEY = 'door/GET_DOOR_HAS_REMOTE_KEY';
const GOT_DOOR_HAS_REMOTE_KEY = 'door/GOT_DOOR_HAS_REMOTE_KEY';

const SET_DOOR_REMOTE_KEY = 'door/SET_DOOR_REMOTE_KEY';
const SET_DOOR_REMOTE_KEY_SUCCESS = 'door/SET_DOOR_REMOTE_KEY_SUCCESS';

const GET_DOOR_USE_BETA_FIRMWARE = 'door/GET_DOOR_USE_BETA_FIRMWARE';
const GOT_DOOR_USE_BETA_FIRMWARE = 'door/GOT_DOOR_USE_BETA_FIRMWARE';
const SET_DOOR_USE_BETA_FIRMWARE = 'door/TOGGLE_DOOR_USE_BETA_FIRMWARE';

const CHECK_FIRMWARE_UPDATE = 'door/CHECK_FIRMWARE_UPDATE';

const DOOR_RESPONSE_RECEIVED = 'door/DOOR_RESPONSE_RECEIVED';
const DOOR_RESPONSE_TIMEOUT = 'door/DOOR_RESPONSE_TIMEOUT';
const LOCAL_DOOR_PING = 'door/LOCAL_DOOR_PING';
const DONE_LOCAL_PING = 'door/DONE_LOCAL_PING';
const DOOR_PONG_TIMEOUT = 'door/DOOR_PONG_TIMEOUT';
const DOOR_PONG_RECEIVED = 'door/DOOR_PONG_RECEIVED';

const MQTT_ONLINE = 'door/MQTT_ONLINE';
const MQTT_OFFLINE = 'door/MQTT_OFFLINE';
const MQTT_MESSAGE_RECEIVED = 'door/MQTT_MESSAGE_RECEIVED';
const MQTT_PRESENCE_RECEIVED = 'door/MQTT_PRESENCE_RECEIVED';

const SEND_COMMAND_SUCCESS = 'door/SEND_COMMAND_SUCCESS';
const SEND_COMMAND_FAILED = 'door/SEND_COMMAND_FAILED';

const INVALID_MESSAGE = 'door/INVALID_MESSAGE';
const UNKNOWN_MESSAGE = 'door/UNKNOWN_MESSAGE';

const INCREMENT_MSG_ID = 'door/INCREMENT_MSG_ID';

// SERVER STUFF
const DOORS_NEED_LOAD = 'door/DOORS_NEED_LOAD';
const DOORS_LOAD_SUCCESS = 'door/DOORS_LOAD_SUCCESS';
const DOORS_LOAD_FAILED = 'door/DOORS_LOAD_FAILED';

const SHARED_DOORS_NEED_LOAD = 'door/SHARED_DOORS_NEED_LOAD';
const SHARED_DOORS_LOAD_SUCCESS = 'door/SHARED_DOORS_LOAD_SUCCESS';
const SHARED_DOORS_LOAD_FAILED = 'door/SHARED_DOORS_LOAD_FAILED';

const SHARE_DOOR = 'door/SHARE_DOOR';
const SHARE_DOOR_SUCCESS = 'door/SHARE_DOOR_SUCCESS';
const SHARE_DOOR_FAILED = 'door/SHARE_DOOR_FAILED';

const UNSHARE_DOOR = 'door/UNSHARE_DOOR';
const UNSHARE_DOOR_SUCCESS = 'door/UNSHARE_DOOR_SUCCESS';
const UNSHARE_DOOR_FAILED = 'door/UNSHARE_DOOR_FAILED';

const CREATE_DOOR_ON_SERVER = 'door/CREATE_DOOR_ON_SERVER';
const CREATE_DOOR_ON_SERVER_SUCCESS = 'door/CREATE_DOOR_ON_SERVER_SUCCESS';
const CREATE_DOOR_ON_SERVER_FAILED = 'door/CREATE_DOOR_ON_SERVER_FAILED';

const DELETE_DOOR_ON_SERVER = 'door/DELETE_DOOR_ON_SERVER';
const DELETE_DOOR_ON_SERVER_SUCCESS = 'door/DELETE_DOOR_ON_SERVER_SUCCESS';
const DELETE_DOOR_ON_SERVER_FAILED = 'door/DELETE_DOOR_ON_SERVER_FAILED';

const UPDATE_DOOR_ON_SERVER = 'door/UPDATE_DOOR_ON_SERVER';
const UPDATE_DOOR_ON_SERVER_SUCCESS = 'door/UPDATE_DOOR_ON_SERVER_SUCCESS';
const UPDATE_DOOR_ON_SERVER_FAILED = 'door/UPDATE_DOOR_ON_SERVER_FAILED';

const DOOR_NEEDS_UPDATE = 'door/DOOR_NEEDS_UPDATE';
const DO_UPDATE_DOOR_INFO = 'door/DO_UPDATE_DOOR_INFO';
const STARTED_DOOR_INFO_UPDATE = 'door/STARTED_DOOR_INFO_UPDATE';
const DOOR_INFO_UPDATE_SUCCESS = 'door/DOOR_INFO_UPDATE_SUCCESS';
const DOOR_INFO_UPDATE_FAILED = 'door/DOOR_INFO_UPDATE_FAILED';
const DOOR_INFO_UPDATE_CANCELED = 'door/DOOR_INFO_UPDATE_CANCELED';

const DOOR_GOING_TO_LOW_POWER = 'door/DOOR_GOING_TO_LOW_POWER';
const DOOR_GOING_TO_LOW_POWER_PROMPTED =
  'door/DOOR_GOING_TO_LOW_POWER_PROMPTED';

const FIRMWARE_UPDATE_AVAILABLE = 'door/FIRMWARE_UPDATE_AVAILABLE';
const FIRMWARE_UPDATE_PROMPTED = 'door/FIRMWARE_UPDATE_PROMPTED';
const FIRMWARE_UPDATE_STARTED = 'door/FIRMWARE_UPDATE_STARTED';

const START_FIRMWARE_UPDATE = 'door/START_FIRMWARE_UPDATE';
const CANCEL_FIRMWARE_UPDATE = 'door/CANCEL_FIRMWARE_UPDATE';

const FIRMWARE_UPDATE_SUCCESS = 'door/FIRMWARE_UPDATE_SUCCESS';
const FIRMWARE_UPDATE_SUCCESS_PROMPTED =
  'door/FIRMWARE_UPDATE_SUCCESS_PROMPTED';

const FIRMWARE_UPDATE_FAILED = 'door/FIRMWARE_UPDATE_FAILED';
const FIRMWARE_UPDATE_FAILED_PROMPTED = 'door/FIRMWARE_UPDATE_FAILED_PROMPTED';

const RESET_DOOR_CONNECTION_STATE = 'door/RESET_DOOR_CONNECTION_STATE';
const RESET_DOOR_STATE = 'door/RESET_DOOR_STATE';

export const noAction = (msg?: any) => ({ type: NO_ACTION, payload: msg });

export const selectDoor = door => ({ type: SELECT_DOOR, payload: door });
export const deselectDoor = () => ({ type: DESELECT_DOOR });

export const connectMQTTServer = () => ({ type: CONNECT_MQTT_SERVER });
export const mqttConnectionDone = () => ({ type: MQTT_CONNECTION_DONE });

export const connectRemoteDoor = door => ({
  type: CONNECT_REMOTE_DOOR,
  payload: door
});
export const remoteDoorConnected = (door, success) => ({
  type: REMOTE_DOOR_CONNECTED,
  payload: { door, success }
});
export const remoteDoorDisconnected = () => ({
  type: REMOTE_DOOR_DISCONNECTED
});
export const remoteDoorConnectionFailed = result => ({
  type: REMOTE_DOOR_CONNECTION_FAILED,
  payload: result
});

export const remoteDoorPresent = id => ({
  type: REMOTE_DOOR_PRESENT,
  payload: id
});
export const remoteDoorNotPresent = id => ({
  type: REMOTE_DOOR_NOTPRESENT,
  payload: id
});

export const remoteDoorSubscribe = id => ({
  type: REMOTE_DOOR_SUBSCRIBE,
  payload: id
});
export const remoteDoorUnsubscribe = id => ({
  type: REMOTE_DOOR_UNSUBSCRIBE,
  payload: id
});

export const openAndHold = () => ({ type: OPEN_AND_HOLD });
export const openAndHoldSent = () => ({ type: OPEN_AND_HOLD_SENT });

export const openDoor = () => ({ type: OPEN_DOOR });
export const doorOpenSent = () => ({ type: DOOR_OPEN_SENT });

export const closeDoor = () => ({ type: CLOSE_DOOR });
export const closeDoorSend = () => ({ type: CLOSE_DOOR_SENT });

export const powerOnDoor = () => ({ type: POWER_ON_DOOR });
export const powerOnDoorSent = () => ({ type: POWER_ON_DOOR_SENT });
export const powerOffDoor = () => ({ type: POWER_OFF_DOOR });
export const powerOffDoorSent = () => ({ type: POWER_OFF_DOOR_SENT });
export const restartDoor = () => ({ type: RESTART_DOOR });
export const restartDoorSent = () => ({ type: RESTART_DOOR_SENT });

export const checkResetReason = () => ({ type: CHECK_RESET_REASON });
export const checkResetReasonSent = () => ({ type: CHECK_RESET_REASON_SENT });
export const checkResetReasonReceived = reason => ({
  type: CHECK_RESET_REASON_RECEIVED,
  payload: reason
});

export const forceWDTTimeout = () => ({ type: FORCE_WDT_TIMEOUT });
export const forceWDTTimeoutSent = () => ({ type: FORCE_WDT_TIMEOUT_SENT });

export const recevedDoorPowerState = state => ({
  type: POWER_OFF_DOOR_SENT,
  payload: state
});

export const getDoorStatus = () => ({ type: GET_DOOR_STATUS });
export const doorStatusUpdated = (door, status, delta) => ({
  type: DOOR_STATUS_UPDATED,
  payload: { door, status, delta }
});

export const doorNeedsUpdate = door => ({
  type: DOOR_NEEDS_UPDATE,
  payload: door
});
export const doUpdateDoorInfo = () => ({ type: DO_UPDATE_DOOR_INFO });
export const startedDoorUpdateInfo = () => ({ type: STARTED_DOOR_INFO_UPDATE });
export const doorInfoUpdateFailed = door => ({
  type: DOOR_INFO_UPDATE_FAILED,
  payload: door
});
export const doorInfoUpdateSuccess = door => ({
  type: DOOR_INFO_UPDATE_SUCCESS,
  payload: door
});
export const doorInfoUpdateCanceled = () => ({
  type: DOOR_INFO_UPDATE_CANCELED
});

export const doorGoingToLowPower = minRemaining => ({
  type: DOOR_GOING_TO_LOW_POWER,
  payload: minRemaining
});

export const doorGoingToLowPowerPrompted = () => ({
  type: DOOR_GOING_TO_LOW_POWER_PROMPTED
});

export const firmwareUpdateAvailable = () => ({
  type: FIRMWARE_UPDATE_AVAILABLE
});
export const firmwareUpdateStarted = () => ({ type: FIRMWARE_UPDATE_STARTED });
export const firmwareUpdatePrompted = () => ({
  type: FIRMWARE_UPDATE_PROMPTED
});
export const startFirmwareUpdate = () => ({ type: START_FIRMWARE_UPDATE });
export const cancelFirmwareUpdate = () => ({ type: CANCEL_FIRMWARE_UPDATE });

export const firmwareUpdateSuccess = () => ({ type: FIRMWARE_UPDATE_SUCCESS });
export const firmwareUpdateSuccessPrompted = () => ({
  type: FIRMWARE_UPDATE_SUCCESS_PROMPTED
});

export const firmwareUpdateFailed = () => ({ type: FIRMWARE_UPDATE_FAILED });
export const firmwareUpdateFailedPrompted = () => ({
  type: FIRMWARE_UPDATE_FAILED_PROMPTED
});

export const incrementUpdateInfoState = () => ({
  type: INCREMENT_UPDATE_INFO_STATE
});
export const decrementUpdateInfoState = () => ({
  type: DECREMENT_UPDATE_INFO_STATE
});

export const getDoorInfo = () => ({ type: GET_DOOR_INFO });
export const getDoorInfoFailed = () => ({ type: GET_DOOR_INFO_FAILED });
export const startedDoorInfo = () => ({ type: STARTED_DOOR_INFO });
export const gotDoorInfo = () => ({ type: GOT_DOOR_INFO });
export const incrementInfoState = () => ({ type: INCREMENT_INFO_STATE });
export const decrementInfoState = () => ({ type: DECREMENT_INFO_STATE });

export const enableSensorOnIndoorNotifications = () => ({
  type: ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS
});
export const enableSensorOffIndoorNotifications = () => ({
  type: ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS
});

export const disableSensorOnIndoorNotifications = () => ({
  type: DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS
});
export const disableSensorOffIndoorNotifications = () => ({
  type: DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS
});

export const enableSensorOnOutdoorNotifications = () => ({
  type: ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS
});
export const enableSensorOffOutdoorNotifications = () => ({
  type: ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS
});

export const disableSensorOnOutdoorNotifications = () => ({
  type: DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS
});
export const disableSensorOffOutdoorNotifications = () => ({
  type: DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS
});

export const enableLowBatteryNotifications = () => ({
  type: ENABLE_LOW_BATTERY_NOTIFICATIONS
});

export const disableLowBatteryNotifications = () => ({
  type: DISABLE_LOW_BATTERY_NOTIFICATIONS
});

export const setNotifications = notifications => ({
  type: SET_NOTIFICATIONS,
  payload: notifications
});

export const setHoldTime = time => ({ type: SET_HOLD_TIME, payload: time });

export const gotHoldTime = (door, time) => ({
  type: GOT_HOLD_TIME,
  payload: { door, time }
});

export const setDoorName = name => ({ type: SET_DOOR_NAME, payload: name });
export const getDoorName = () => ({ type: GET_DOOR_NAME });
export const gotDoorName = (door, name) => ({
  type: GOT_DOOR_NAME,
  payload: { door, name }
});

export const enableInside = () => ({ type: ENABLE_INSIDE });
export const disableInside = () => ({ type: DISABLE_INSIDE });
export const insideEnabled = () => ({ type: INSIDE_ENABLED });
export const insideDisabled = () => ({ type: INSIDE_DISABLED });
export const insideEnableFailed = () => ({ type: INSIDE_ENABLE_FAILED });

export const enableOutside = () => ({ type: ENABLE_OUTSIDE });
export const disableOutside = () => ({ type: DISABLE_OUTSIDE });
export const outsideEnabled = () => ({ type: OUTSIDE_ENABLED });
export const outsideDisabled = () => ({ type: OUTSIDE_DISABLED });
export const outsideEnableFailed = () => ({ type: OUTSIDE_ENABLE_FAILED });

export const getDoorPower = () => ({ type: GET_DOOR_POWER });
export const gotDoorPower = (door, power) => ({
  type: GOT_DOOR_POWER,
  payload: { door, power }
});

export const getDoorACVoltage = () => ({ type: GET_AC_VOLTAGE });
export const gotDoorACVoltage = (door, volt) => ({
  type: GOT_AC_VOLTAGE,
  payload: { door, volt }
});

export const getDoorBattery = () => ({ type: GET_DOOR_BATTERY });
export const gotDoorBattery = (
  door,
  batteryPercent,
  acPresent,
  batteryPresent
) => ({
  type: GOT_DOOR_BATTERY,
  payload: { door, batteryPercent, acPresent, batteryPresent }
});

export const getSettings = () => ({ type: GET_SETTINGS });
export const gotSettings = (door, settings) => ({
  type: GOT_SETTINGS,
  payload: { door, settings }
});

export const getNotifications = () => ({ type: GET_NOTIFICATIONS });
export const gotNotifications = (door, notifications) => ({
  type: GOT_NOTIFICATIONS,
  payload: { door, notifications }
});

export const getSensors = () => ({ type: GET_SENSORS });
export const gotSensors = (door, sensors) => ({
  type: GOT_SENSORS,
  payload: { door, sensors }
});

export const insideChanged = (door, status) => ({
  type: INSIDE_CHANGED,
  payload: { door, status }
});
export const outsideChanged = (door, status) => ({
  type: OUTSIDE_CHANGED,
  payload: { door, status }
});

export const getHWInfo = () => ({ type: GET_HW_INFO });
export const gotHWInfo = (door, info) => ({
  type: GOT_HW_INFO,
  payload: { door, info }
});

export const getTimezone = () => ({ type: GET_TIMEZONE });
export const setTimezone = tz => ({ type: SET_TIMEZONE, payload: tz });
export const gotTimezone = (door, timezone) => ({
  type: GOT_TIMEZONE,
  payload: { door, timezone }
});

export const getTime = () => ({ type: GET_TIME });
export const setTime = time => ({ type: SET_TIME, payload: time });
export const gotTime = (door, time) => ({
  type: GOT_TIME,
  payload: { door, time }
});

export const getTimersEnabled = () => ({ type: GET_TIMERS_ENABLED });
export const gotTimersEnabled = (door, enabled) => ({
  type: GOT_TIMERS_ENABLED,
  payload: { door, enabled }
});

export const enableTimers = () => ({ type: ENABLE_TIMERS });
export const disableTimers = () => ({ type: DISABLE_TIMERS });

export const enableOutsideSensorSafetyLock = () => ({
  type: ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK
});
export const disableOutsideSensorSafetyLock = () => ({
  type: DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK
});

export const enableCmdLockout = () => ({ type: ENABLE_CMD_LOCKOUT });
export const disableCmdLockout = () => ({ type: DISABLE_CMD_LOCKOUT });

export const enableAutoRetract = () => ({ type: ENABLE_AUTO_RETRACT });
export const disableAutoRetract = () => ({ type: DISABLE_AUTO_RETRACT });

export const setSensorTriggerVoltage = voltage => ({
  type: SET_SENSOR_TRIGGER_VOLTAGE,
  payload: voltage
});
export const getSensorTriggerVoltage = () => ({
  type: GET_SENSOR_TRIGGER_VOLTAGE
});
export const gotSensorTriggerVoltage = (door, voltage) => ({
  type: GOT_SENSOR_TRIGGER_VOLTAGE,
  payload: { door, voltage }
});

export const setSleepSensorTriggerVoltage = voltage => ({
  type: SET_SLEEP_SENSOR_TRIGGER_VOLTAGE,
  payload: voltage
});
export const getSleepSensorTriggerVoltage = () => ({
  type: GET_SLEEP_SENSOR_TRIGGER_VOLTAGE
});
export const gotSleepSensorTriggerVoltage = (door, voltage) => ({
  type: GOT_SLEEP_SENSOR_TRIGGER_VOLTAGE,
  payload: { door, voltage }
});

export const setWakeupCountThreshold = count => ({
  type: SET_WAKEUP_COUNT_THRESHOLD,
  payload: count
});
export const getWakeupCountThreshold = () => ({
  type: GET_WAKEUP_COUNT_THRESHOLD
});
export const gotWakeupCountThreshold = (door, count) => ({
  type: GOT_WAKEUP_COUNT_THRESHOLD,
  payload: { door, count }
});

export const setNoEnergyCountThreshold = count => ({
  type: SET_NO_ENERGY_COUNT_THRESHOLD,
  payload: count
});
export const getNoEnergyCountThreshold = () => ({
  type: GET_NO_ENERGY_COUNT_THRESHOLD
});
export const gotNoEnergyCountThreshold = (door, count) => ({
  type: GOT_NO_ENERGY_COUNT_THRESHOLD,
  payload: { door, count }
});

export const setTriggerValidCount = count => ({
  type: SET_TRIGGER_VALID_COUNT,
  payload: count
});
export const getTriggerValidCount = () => ({ type: GET_TRIGGER_VALID_COUNT });
export const gotTriggerValidCount = (door, count) => ({
  type: GOT_TRIGGER_VALID_COUNT,
  payload: { door, count }
});

export const setTimeC = timeC => ({ type: SET_TIME_C, payload: timeC });
export const getTimeC = () => ({ type: GET_TIME_C });
export const gotTimeC = (door, timeC) => ({
  type: GOT_TIME_C,
  payload: { door, timeC }
});

export const setTimeD = timeD => ({ type: SET_TIME_D, payload: timeD });
export const getTimeD = () => ({ type: GET_TIME_D });
export const gotTimeD = (door, timeD) => ({
  type: GOT_TIME_D,
  payload: { door, timeD }
});

export const setTimeF = timeF => ({ type: SET_TIME_F, payload: timeF });
export const getTimeF = () => ({ type: GET_TIME_F });
export const gotTimeF = (door, timeF) => ({
  type: GOT_TIME_F,
  payload: { door, timeF }
});

export const getScheduleList = () => ({ type: GET_SCHEDULE_LIST });
export const gotScheduleList = (door, list) => ({
  type: GOT_SCHEDULE_LIST,
  payload: { door, list }
});
export const getSchedule = index => ({ type: GET_SCHEDULE, payload: index });
export const stageSchedule = (index, schedule) => ({
  type: STAGE_SCHEDULE,
  payload: { index, schedule }
});
export const stageScheduleSuccess = schedule => ({
  type: STAGE_SCHEDULE_SUCCESS,
  payload: schedule
});
export const setSchedule = (index, schedule) => ({
  type: SET_SCHEDULE,
  payload: { index, schedule }
});
export const setScheduleSuccess = (door, schedule) => ({
  type: SET_SCHEDULE_SUCCESS,
  payload: { door, schedule }
});
export const stageDeleteSchedule = (door, index) => ({
  type: STAGE_DELETE_SCHEDULE,
  payload: { door, index }
});
export const deleteSchedule = index => ({
  type: DELETE_SCHEDULE,
  payload: index
});
export const deleteScheduleSuccess = (door, index) => ({
  type: DELETE_SCHEDULE_SUCCESS,
  payload: { door, index }
});
export const gotSchedule = (door, schedule) => ({
  type: GOT_SCHEDULE,
  payload: { door, schedule }
});

export const clearSchedules = () => ({ type: CLEAR_SCHEDULES });

export const getDoorHasRemoteID = () => ({ type: GET_DOOR_HAS_REMOTE_ID });
export const gotDoorHasRemoteID = (door, hasID) => ({
  type: GOT_DOOR_HAS_REMOTE_ID,
  payload: { door, hasID }
});

export const setDoorRemoteID = door => ({
  type: SET_DOOR_REMOTE_ID,
  payload: door
});
export const setDoorRemoteIDSucess = door => ({
  type: SET_DOOR_REMOTE_ID_SUCCESS,
  payload: door
});

export const getDoorHasRemoteKey = () => ({ type: GET_DOOR_HAS_REMOTE_KEY });
export const gotDoorHasRemoteKey = (door, hasKey) => ({
  type: GOT_DOOR_HAS_REMOTE_KEY,
  payload: { door, hasKey }
});

export const setDoorRemoteKey = door => ({
  type: SET_DOOR_REMOTE_KEY,
  payload: door
});
export const setDoorRemoteKeySucess = door => ({
  type: SET_DOOR_REMOTE_KEY_SUCCESS,
  payload: door
});

export const getDoorUseBetaFirmware = () => ({
  type: GET_DOOR_USE_BETA_FIRMWARE
});
export const gotDoorUseBetaFirmware = (door, useBeta) => ({
  type: GOT_DOOR_USE_BETA_FIRMWARE,
  payload: { door, useBeta }
});
export const setDoorUseBetaFirmware = useBeta => ({
  type: SET_DOOR_USE_BETA_FIRMWARE,
  payload: useBeta
});

export const checkFirmwareUpdate = () => ({ type: CHECK_FIRMWARE_UPDATE });
export const localDoorPing = time => ({ type: LOCAL_DOOR_PING, payload: time });
export const doneLocalPing = () => ({ type: DONE_LOCAL_PING });

export const doorResponseReceived = response => ({
  type: DOOR_RESPONSE_RECEIVED,
  payload: response
});
export const doorResponseTimeout = () => ({
  type: DOOR_RESPONSE_TIMEOUT
});
export const doorPongReceived = response => ({
  type: DOOR_PONG_RECEIVED,
  payload: response
});
export const doorPongTimeout = response => ({
  type: DOOR_PONG_TIMEOUT,
  payload: response
});

export const mqttOnline = () => ({ type: MQTT_ONLINE });
export const mqttOffline = () => ({ type: MQTT_OFFLINE });
export const mqttMessageReceived = (door, msg) => ({
  type: MQTT_MESSAGE_RECEIVED,
  payload: { door, msg }
});

export const mqttPresenceReceived = (door, msg) => ({
  type: MQTT_PRESENCE_RECEIVED,
  payload: (door, msg)
});
export const sendCmdSuccess = result => ({
  type: SEND_COMMAND_SUCCESS,
  payload: result
});

export const sendCmdFailed = result => ({
  type: SEND_COMMAND_FAILED,
  payload: result
});

export const invalidMessage = msg => ({ type: INVALID_MESSAGE, payload: msg });
export const unknownMessage = () => ({ type: UNKNOWN_MESSAGE });

export const incrementMessageId = () => ({ type: INCREMENT_MSG_ID });

export const doorsNeedLoad = () => ({ type: DOORS_NEED_LOAD });
export const doorsLoadSuccess = user => ({
  type: DOORS_LOAD_SUCCESS,
  payload: user
});
export const doorsLoadFailed = reason => ({
  type: DOORS_LOAD_FAILED,
  payload: reason
});

export const sharedDoorsNeedLoad = () => ({ type: SHARED_DOORS_NEED_LOAD });
export const sharedDoorsLoadSuccess = doors => ({
  type: SHARED_DOORS_LOAD_SUCCESS,
  payload: doors
});
export const sharedDoorsLoadFailed = reason => ({
  type: SHARED_DOORS_LOAD_FAILED,
  payload: reason
});

export const shareDoor = (doorId, email) => ({
  type: SHARE_DOOR,
  payload: { doorId, email }
});
export const shareDoorSuccess = door => ({
  type: SHARE_DOOR_SUCCESS,
  payload: door
});
export const shareDoorFailed = door => ({
  type: SHARE_DOOR_FAILED,
  payload: door
});

export const unshareDoor = (doorId, email) => ({
  type: UNSHARE_DOOR,
  payload: { doorId, email }
});
export const unshareDoorSuccess = door => ({
  type: UNSHARE_DOOR_SUCCESS,
  payload: door
});
export const unshareDoorFailed = door => ({
  type: UNSHARE_DOOR_FAILED,
  payload: door
});

export const createDoorOnServer = door => ({
  type: CREATE_DOOR_ON_SERVER,
  payload: door
});

export const createDoorOnServerSuccess = door => ({
  type: CREATE_DOOR_ON_SERVER_SUCCESS,
  payload: door
});

export const createDoorOnServerFailed = door => ({
  type: CREATE_DOOR_ON_SERVER_FAILED,
  payload: door
});

export const deleteDoorOnServer = door => ({
  type: DELETE_DOOR_ON_SERVER,
  payload: door
});

export const deleteDoorOnServerSuccess = door => ({
  type: DELETE_DOOR_ON_SERVER_SUCCESS,
  payload: door
});

export const deleteDoorOnServerFailed = door => ({
  type: DELETE_DOOR_ON_SERVER_FAILED,
  payload: door
});

export const updateDoorOnServer = door => ({
  type: UPDATE_DOOR_ON_SERVER,
  payload: door
});
export const updateDoorOnServerSuccess = door => ({
  type: UPDATE_DOOR_ON_SERVER_SUCCESS,
  payload: door
});

export const updateDoorOnServerFailed = door => ({
  type: UPDATE_DOOR_ON_SERVER_FAILED,
  payload: door
});

export const resetDoorConnectionState = () => ({
  type: RESET_DOOR_CONNECTION_STATE
});
export const resetDoorState = () => ({ type: RESET_DOOR_STATE });

// Get Info State Machine constants:
const FSM_INIT_GET_INFO = 0;
const FSM_GET_HWINFO = 1;
const FSM_GET_DOOR_SETTINGS = 2;
const FSM_GET_DOOR_NAME = 3;
const FSM_GET_DOOR_STATE = 4;
const FSM_GET_DOOR_POWER = 5;
const FSM_GET_DOOR_BATTERY = 6;
const FSM_GET_DOOR_NOTIFICATION_SETTINGS = 7;
const FSM_GET_DOOR_HAS_REMOTE_ID = 8;
const FSM_GET_DOOR_HAS_REMOTE_KEY = 9;
// const FSM_GET_DOOR_POWER = 2;
// const FSM_GET_SENSORS = 3;
const FSM_GET_SCHEDULE_LIST = 10;
const FSM_PURGE_PENDING_SCHEDULES = 11;
const FSM_GET_SCHEDULES = 12;
const FSM_CHECK_REMOTE_KEYS = 13;
const FSM_CHECK_RESET_REASON = 14;

const FSM_INIT_UPDATE_INFO = 0;
const FSM_UPDATE_DOOR_NAME = 1;
const FSM_UPDATE_DOOR_HOLD_TIME = 2;
const FSM_UPDATE_DOOR_NOTIFICATIONS = 3;
const FSM_UPDATE_DOOR_SCHEDULES = 4;
const FSM_UPDATE_DOOR_TIMEZONE = 5;
const FSM_UPDATE_DOOR_LOCKOUTS = 6;
const FSM_UPDATE_DOOR_CMD_LOCKOUT = 7;
const FSM_UPDATE_SENSOR_TRIGGER_VOLTAGE = 8;
const FSM_UPDATE_SLEEP_SENSOR_TRIGGER_VOLTAGE = 9;
const FSM_UPDATE_DOOR_OPTIONS = 10;

const initialState = {
  doors: [],
  sharedDoors: [],
  serverDoors: [],
  localDoors: [],
  pendingUpdateDoors: [],
  lastResetReason: 0,
  remoteDoorConnected: false,
  remoteDoorPresent: false,
  remoteDoorIds: [],
  localDoorConnected: false,
  fetchingInfo: false,
  fetchingDoors: false,
  fetchedCurrentDoorInfo: false,
  updatingDoorInfo: false,
  lastInfoUpdateFailed: [], // list of door ids that failed updating info
  lastDoorResponse: '',
  localKeepAliveTimer: null,
  lastLocalPingTime: 0,
  localLatency: 0,
  totalPackets: 0,
  packetsDropped: 0,
  waitingPing: false,
  waitingResponse: false,
  mqttOnline: false,
  outgoingMessages: [],
  nextMsgId: 0,
  connectedDoors: [], // id of all connectedDoors
  connectedDoor: '', // id of selected door
  selectedDoor: '', // id of selected door
  doorInfoState: FSM_INIT_GET_INFO,
  doorUpdateInfoState: FSM_INIT_UPDATE_INFO,
  fetchedSchedules: [],
  lastCompletedSchedule: null,
  currentDoorOpenStatus: {},
  statusTimeDelta: {},
  connectedDoorHasRemoteID: undefined,
  connectedDoorHasRemoteKey: undefined,
  waitingServerUpdate: false,
  promptedFirmwareUpdate: false,
  mdnsErrorMsg: '',
  connectedDoorSettings: {},
  currentDoorUsingBetaFirmware: false,
  connectedDoorTime: '',
  connectedDoorACVoltage: -1
};
const getCurrentDoorCopy = (id, state) => {
  const selectedDoorIndex = state.doors.findIndex(d => d.id === id);
  const doorCopy = [...state.doors];
  return { doorCopy, selectedDoorIndex };
};
const door = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DOOR:
      return {
        ...state,
        selectedDoor: action.payload.id
      };
    case DESELECT_DOOR:
      clearInterval(state.doorInfoTimer);
      return {
        ...state,
        selectedDoor: ''
      };
    case REMOTE_DOOR_CONNECTED:
      return {
        ...state,
        msgId: 0,
        connectedDoors: [...state.connectedDoors, action.payload.door.id],
        remoteDoorConnected: true,
        remoteDoorPresent: false,
        remoteDoorIds: [],
        connectedDoor: action.payload.door
      };
    case REMOTE_DOOR_DISCONNECTED:
      return {
        ...state,
        fetchingInfo: false,
        msgId: 0,
        remoteDoorConnected: false,
        remoteDoorPresent: false,
        remoteDoorIds: [],
        promptedFirmwareUpdate: false
      };
    case REMOTE_DOOR_PRESENT:
      return {
        ...state,
        remoteDoorPresent: true,
        remoteDoorIds: [...state.remoteDoorIds, action.payload]
      };
    case REMOTE_DOOR_NOTPRESENT: {
      const newRemoteIds = [
        ...state.remoteDoorIds.slice().filter(d => d !== action.payload)
      ];
      return {
        ...state,
        remoteDoorIds: newRemoteIds,
        remoteDoorPresent: newRemoteIds.length > 0
      };
    }
    case REMOTE_DOOR_SUBSCRIBE: {
      const newRemoteIds = [
        ...state.remoteDoorIds.slice().filter(d => d !== action.payload)
      ];
      return {
        ...state,
        remoteDoorIds: [
          ...newRemoteIds,
          // ...state.remoteDoorIds.slice().filter(d => d !== action.payload),
          action.payload
        ],
        remoteDoorPresent: newRemoteIds.length > 0
      };
    }
    case REMOTE_DOOR_UNSUBSCRIBE: {
      const newRemoteIds = [
        ...state.remoteDoorIds.slice().filter(d => d !== action.payload)
      ];
      return {
        ...state,
        remoteDoorIds: newRemoteIds,
        remoteDoorPresent: newRemoteIds.length > 0
      };
    }
    case GET_DOOR_INFO:
      return {
        ...state,
        fetchingInfo: true,
        waitingResponse: false,
        fetchedSchedules: [],
        doorInfoState: FSM_INIT_GET_INFO
      };
    case GOT_TIME:
      return {
        ...state,
        connectedDoorTime: action.payload
      };
    case GET_DOOR_INFO_FAILED:
    case GOT_DOOR_INFO:
      return {
        ...state,
        fetchingInfo: false,
        fetchedCurrentDoorInfo: true,
        fetchedSchedules: [],
        doorInfoState: FSM_INIT_GET_INFO
      };
    case STARTED_DOOR_INFO:
      return {
        ...state,
        waitingResponse: false
      };
    case INCREMENT_INFO_STATE:
      return {
        ...state,
        doorInfoState: state.doorInfoState + 1
      };
    case DECREMENT_INFO_STATE:
      return {
        ...state,
        doorInfoState: Math.min(0, state.doorInfoState - 1)
      };
    case INCREMENT_UPDATE_INFO_STATE:
      return {
        ...state,
        doorUpdateInfoState: state.doorUpdateInfoState + 1
      };
    case DOOR_STATUS_UPDATED:
      const { currentDoorOpenStatus, statusTimeDelta } = state;
      const currentDoorOpenStatusCopy = {
        ...currentDoorOpenStatus,
        [action.payload.door]: action.payload.status
      };
      const statusTimeDeltaCopy = {
        ...statusTimeDelta,
        [action.payload.door]: action.payload.delta
      };
      return {
        ...state,
        currentDoorOpenStatus: currentDoorOpenStatusCopy,
        statusTimeDelta: statusTimeDeltaCopy
      };
    case GOT_HW_INFO: {
      /* eslint-disable camelcase */
      const { ver, rev, fw_maj, fw_min, fw_pat } = action.payload.info;
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].doorInfo = {
        // ...doorCopy[selectedDoorIndex].doorInfo,
        hwVersion: `${ver}.${rev}`,
        fwVersion: `${fw_maj}.${fw_min}.${fw_pat}`,
        serial: '',
        manufDate: ''
      };
      /* eslint-enable camelcase */
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_DOOR_POWER: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        powerOn: action.payload.power
      };
      // splice selected door with new settings
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_AC_VOLTAGE: {
      return {
        ...state,
        connectedDoorACVoltage: action.payload
      };
    }
    case GOT_DOOR_BATTERY: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        batteryPercent: action.payload.batteryPercent,
        acPresent: action.payload.acPresent,
        batteryPresent: action.payload.batteryPresent
      };
      // splice selected door with new settings
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_TIMEZONE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        timezone: action.payload.timezone
      };
      // splice selected door with new settings
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_TIMERS_ENABLED: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        timersEnabled: action.payload.enabled
      };
      // splice selected door with new settings
      return {
        ...state,
        doors: doorCopy
      };
    }
    case STAGE_SCHEDULE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      if (doorCopy[selectedDoorIndex].pendingSchedules) {
        doorCopy[selectedDoorIndex].schedules = [
          ...doorCopy[selectedDoorIndex].schedules.filter(
            s => s.index !== action.payload.index
          )
        ].sort((p, c) => p.index - c.index);

        doorCopy[selectedDoorIndex].pendingSchedules = [
          ...doorCopy[selectedDoorIndex].pendingSchedules.filter(
            s => s.index !== action.payload.index
          ),
          action.payload.schedule
        ].sort((p, c) => p.index - c.index);
      } else {
        doorCopy[selectedDoorIndex].pendingSchedules = [
          action.payload.schedule
        ];
        doorCopy[selectedDoorIndex].schedules = [
          ...doorCopy[selectedDoorIndex].schedules.filter(
            s => s.index !== action.payload.index
          )
        ].sort((p, c) => p.index - c.index);
      }
      return {
        ...state,
        doors: doorCopy,
        lastCompletedSchedule: null
      };
    }
    case SET_SCHEDULE_SUCCESS:
    case GOT_SCHEDULE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { schedule: payloadSchedule } = action.payload;
      const schedule = {
        ...payloadSchedule,
        // copy entire thing but transform enabled into boolean value
        enabled: payloadSchedule.enabled === 1,
        inside: payloadSchedule.inside === 1,
        outside: payloadSchedule.outside === 1
      };
      doorCopy[selectedDoorIndex].schedules = [
        ...doorCopy[selectedDoorIndex].schedules.filter(
          s => s.index !== payloadSchedule.index
        ),
        schedule
      ].sort((p, c) => p.index - c.index);
      if (doorCopy[selectedDoorIndex].pendingSchedules) {
        doorCopy[selectedDoorIndex].pendingSchedules = doorCopy[
          selectedDoorIndex
        ].pendingSchedules.filter(s => s.index !== payloadSchedule.index);
      }
      return {
        ...state,
        lastCompletedSchedule: payloadSchedule.index,
        doors: doorCopy,
        fetchedSchedules: [
          ...state.fetchedSchedules
            .slice()
            .filter(s => s !== payloadSchedule.index),
          payloadSchedule.index
        ]
      };
    }
    case CLEAR_SCHEDULES: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      doorCopy[selectedDoorIndex].schedules = [];
      doorCopy[selectedDoorIndex].pendingSchedules = [];

      return {
        ...state,
        lastCompletedSchedule: null,
        doors: doorCopy,
        fetchedSchedules: []
      };
    }
    case STAGE_DELETE_SCHEDULE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { index } = action.payload;
      doorCopy[selectedDoorIndex].schedules = [
        ...doorCopy[selectedDoorIndex].schedules.slice().map(s => {
          if (index === s.index) {
            return {
              ...s,
              pendingDelete: true
            };
          }
          return s;
        })
      ].sort((p, c) => p.index - c.index);

      return {
        ...state,
        doors: doorCopy
      };
    }
    case DELETE_SCHEDULE_SUCCESS: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { index } = action.payload;
      doorCopy[selectedDoorIndex].schedules = [
        ...doorCopy[selectedDoorIndex].schedules.filter(s => s.index !== index)
      ].sort((p, c) => p.index - c.index);
      if (doorCopy[selectedDoorIndex].pendingSchedules) {
        doorCopy[selectedDoorIndex].pendingSchedules = [
          ...doorCopy[selectedDoorIndex].pendingSchedules.filter(
            s => s.index !== index
          )
        ].sort((p, c) => p.index - c.index);
      }

      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_SCHEDULE_LIST: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { list } = action.payload;
      doorCopy[selectedDoorIndex].scheduleList = list;
      // remove any schedules that are in the phone but not the door
      doorCopy[selectedDoorIndex].schedules = doorCopy[
        selectedDoorIndex
      ].schedules.filter(sched => list.indexOf(sched.index) !== -1);
      // splice selected door with new settings
      return {
        ...state,
        doors: doorCopy
      };
    }
    case INSIDE_CHANGED: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { status } = action.payload;
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        insideOn: status
      };
      return {
        ...state,
        doors: doorCopy
      };
    }
    case OUTSIDE_CHANGED: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { status } = action.payload;
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        outsideOn: status
      };
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_DOOR_NAME: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].name = action.payload.name;

      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_SETTINGS: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        ...action.payload.settings
      };
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_HOLD_TIME: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings.holdOpenTime =
        action.payload.time;
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_NOTIFICATIONS: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].notifications = {
        ...action.payload.notifications
      };
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_SENSORS: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { sensors } = action.payload;
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        insideOn: sensors.inside,
        outsideOn: sensors.outside
      };
      return {
        ...state,
        doors: doorCopy
      };
    }
    case GOT_SENSOR_TRIGGER_VOLTAGE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      // splice selected door with new settings
      const { voltage } = action.payload;
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        sensorTriggerVoltage: voltage
      };
      return {
        ...state,
        doors: doorCopy,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          sensorTriggerVoltage: voltage
        }
      };
    }
    case GOT_SLEEP_SENSOR_TRIGGER_VOLTAGE: {
      const { doorCopy, selectedDoorIndex } = getCurrentDoorCopy(
        action.payload.door,
        state
      );
      const { voltage } = action.payload;
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].doorSettings = {
        ...doorCopy[selectedDoorIndex].doorSettings,
        sleepSensorTriggerVoltage: voltage
      };
      return {
        ...state,
        doors: doorCopy,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          sleepSensorTriggerVoltage: voltage
        }
      };
    }
    case GOT_WAKEUP_COUNT_THRESHOLD:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          wakeupCountThreshold: action.payload.count
        }
      };
    case GOT_NO_ENERGY_COUNT_THRESHOLD:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          noEnergyCountThreshold: action.payload.count
        }
      };
    case GOT_TRIGGER_VALID_COUNT:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          triggerValidCount: action.payload.count
        }
      };
    case GOT_TIME_C:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          timeC: action.payload.timeC
        }
      };
    case GOT_TIME_D:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          timeD: action.payload.timeD
        }
      };
    case GOT_TIME_F:
      return {
        ...state,
        connectedDoorSettings: {
          ...state.connectedDoorSettings,
          timeF: action.payload.timeF
        }
      };
    case SET_DOOR_REMOTE_ID:
      return {
        ...state,
        connectedDoorHasRemoteID: undefined
      };
    case GOT_DOOR_HAS_REMOTE_ID: {
      return {
        ...state,
        connectedDoorHasRemoteID: action.payload.hasID
      };
    }
    case SET_DOOR_REMOTE_KEY:
      return {
        ...state,
        connectedDoorHasRemoteKey: undefined
      };
    case GOT_DOOR_USE_BETA_FIRMWARE:
      return {
        ...state,
        currentDoorUsingBetaFirmware: action.payload.useBeta
      };
    case GOT_DOOR_HAS_REMOTE_KEY: {
      return {
        ...state,
        connectedDoorHasRemoteKey: action.payload.hasKey
      };
    }
    case LOCAL_DOOR_PING:
      return {
        ...state,
        waitingPing: true,
        totalPackets: state.totalPackets + 1,
        lastLocalPingTime: action.payload
      };
    case DOOR_PONG_RECEIVED:
      return {
        ...state,
        waitingPing: false,
        localLatency: action.payload - state.lastLocalPingTime
      };
    case DOOR_PONG_TIMEOUT:
      return {
        ...state,
        waitingPing: false,
        packetsDropped: state.packetsDropped + 1
      };
    case DOOR_RESPONSE_RECEIVED:
      return {
        ...state,
        waitingResponse: false,
        lastDoorResponse: action.payload
      };
    case DOOR_RESPONSE_TIMEOUT:
      return {
        ...state,
        waitingResponse: false
      };
    case MQTT_ONLINE:
      return {
        ...state,
        mqttOnline: true
      };
    case MQTT_OFFLINE:
      return {
        ...state,
        mqttOnline: false
      };
    case MQTT_MESSAGE_RECEIVED:
      return {
        ...state,
        waitingResponse: false,
        lastDoorResponse: action.payload
      };
    case INCREMENT_MSG_ID:
      return {
        ...state,
        nextMsgId: state.nextMsgId + 1
      };
    case DOOR_OPEN_SENT:
    case ENABLE_INSIDE:
    case DISABLE_INSIDE:
    case ENABLE_OUTSIDE:
    case DISABLE_OUTSIDE:
    case SEND_COMMAND_SUCCESS:
      return {
        ...state,
        waitingResponse: true
      };
    case SEND_COMMAND_FAILED:
      return {
        ...state,
        waitingResponse: false
      };
    case CREATE_DOOR_ON_SERVER_SUCCESS: {
      return {
        ...state,
        doors: [...state.doors, action.payload].sort((p, c) => p.id > c.id)
      };
    }
    case DELETE_DOOR_ON_SERVER_SUCCESS: {
      return {
        ...state,
        doors: [...state.doors, action.payload]
          .filter(d => d.id !== action.payload.id)
          .sort((p, c) => p.id > c.id)
      };
    }
    case DOORS_NEED_LOAD:
      return {
        ...state,
        fetchingDoors: true
      };
    case DOORS_LOAD_SUCCESS: {
      const serverDoorsMerged = action.payload.map(d => {
        const foundDoor = state.doors.find(theDoor => theDoor.id === d.id);
        if (
          !foundDoor ||
          !foundDoor.doorSettings ||
          !foundDoor.schedules ||
          !foundDoor.notifications
        ) {
          return {
            ...d,
            pendingSchedules: []
          };
        }
        const doorScheduleKeys = foundDoor.schedules.map(s => s.index);
        return {
          ...d,
          doorSettings: {
            ...d.doorSettings,
            ...foundDoor.doorSettings
          },
          schedules: [
            ...d.schedules
              .slice()
              .filter(s => doorScheduleKeys.indexOf(s.index) === -1),
            ...foundDoor.schedules
          ],
          notifications: {
            ...d.notifications,
            ...foundDoor.notifications
          },
          pendingSchedules: foundDoor.pendingSchedules
            ? [...foundDoor.pendingSchedules]
            : []
        };
      });
      let { selectedDoor } = state;
      if (!selectedDoor) {
        const [firstDoor] = serverDoorsMerged;
        if (firstDoor && firstDoor.id) {
          selectedDoor = firstDoor.id;
        }
      }
      return {
        ...state,
        doors: [...serverDoorsMerged, ...state.sharedDoors],
        serverDoors: serverDoorsMerged,
        selectedDoor,
        fetchingDoors: false
      };
    }
    case SHARED_DOORS_LOAD_SUCCESS: {
      const serverDoorsMerged = action.payload.map(d => {
        const foundDoor = state.doors.find(theDoor => theDoor.id === d.id);
        if (
          !foundDoor ||
          !foundDoor.doorSettings ||
          !foundDoor.schedules ||
          !foundDoor.notifications
        ) {
          return {
            ...d,
            pendingSchedules: []
          };
        }
        const doorScheduleKeys = foundDoor.schedules.map(s => s.index);
        return {
          ...d,
          doorSettings: {
            ...d.doorSettings,
            ...foundDoor.doorSettings
          },
          schedules: [
            ...d.schedules
              .slice()
              .filter(s => doorScheduleKeys.indexOf(s.index) === -1),
            ...foundDoor.schedules
          ],
          notifications: {
            ...d.notifications,
            ...foundDoor.notifications
          },
          pendingSchedules: foundDoor.pendingSchedules
            ? [...foundDoor.pendingSchedules]
            : []
        };
      });
      let { selectedDoor } = state;
      if (!selectedDoor) {
        const [firstDoor] = serverDoorsMerged;
        if (firstDoor && firstDoor.id) {
          selectedDoor = firstDoor.id;
        }
      }
      // const newDoorIds = action.payload.map(d => d.id);
      // const remainingDoors = state.doors
      //   .filter(d => newDoorIds.findIndex(nd => d.id == nd.id) === -1);
      return {
        ...state,
        doors: [...state.serverDoors, ...serverDoorsMerged],
        sharedDoors: serverDoorsMerged,
        selectedDoor
      };
    }
    case DOORS_LOAD_FAILED:
      return {
        ...state,
        fetchingDoors: false
      };
    case SHARE_DOOR_SUCCESS:
    case UNSHARE_DOOR_SUCCESS: {
      const { doorCopy } = getCurrentDoorCopy(action.payload.door, state);
      const selectedDoorIndex = doorCopy.findIndex(
        d => d.id === action.payload.id
      );
      if (selectedDoorIndex === -1) {
        return {
          ...state
        };
      }
      // splice selected door with new settings
      doorCopy[selectedDoorIndex].sharedUsers = [...action.payload.sharedUsers];
      return {
        ...state,
        doors: doorCopy
      };
    }
    case DOOR_NEEDS_UPDATE: {
      const { pendingUpdateDoors, lastInfoUpdateFailed } = state;
      let pendingUpdateDoorsCopy = [...pendingUpdateDoors];
      let lastInfoUpdateFailedCopy = [...lastInfoUpdateFailed];
      const newDoor = action.payload;

      // splice selected door with new settings
      pendingUpdateDoorsCopy = pendingUpdateDoorsCopy.filter(
        d => d.id !== newDoor.id
      );
      lastInfoUpdateFailedCopy = lastInfoUpdateFailedCopy.filter(
        d => d !== action.payload.id
      );
      return {
        ...state,
        updatingDoorInfo: true,
        doorUpdateInfoState: 0,
        lastInfoUpdateFailed: lastInfoUpdateFailedCopy,
        pendingUpdateDoors: [
          ...pendingUpdateDoorsCopy,
          Object.assign({}, action.payload)
        ]
      };
    }
    case DOOR_INFO_UPDATE_SUCCESS: {
      const { pendingUpdateDoors, lastInfoUpdateFailed } = state;
      let pendingUpdateDoorsCopy = [...pendingUpdateDoors];
      let lastInfoUpdateFailedCopy = [...lastInfoUpdateFailed];

      // splice selected door with new settings
      pendingUpdateDoorsCopy = pendingUpdateDoorsCopy.filter(
        d => d.id !== action.payload.id
      );
      lastInfoUpdateFailedCopy = lastInfoUpdateFailedCopy.filter(
        d => d !== action.payload.id
      );
      return {
        ...state,
        pendingUpdateDoors: pendingUpdateDoorsCopy,
        lastInfoUpdateFailed: lastInfoUpdateFailedCopy,
        updatingDoorInfo: false
      };
    }
    case UPDATE_DOOR_ON_SERVER:
      return {
        ...state,
        waitingServerUpdate: true
      };
    case UPDATE_DOOR_ON_SERVER_SUCCESS: {
      // update the "pending update" door object
      const { pendingUpdateDoors } = state;
      const doorsCopy = [...state.doors];
      let pendingUpdateDoorsCopy = [...pendingUpdateDoors];
      const newDoor = action.payload;
      const doorIndex = doorsCopy.findIndex(d => d.id === action.payload.id);
      // set up the door status stuff

      if (doorIndex !== -1) {
        doorsCopy.splice(doorIndex, 1, newDoor);
        doorsCopy[doorIndex].pendingSchedules = [];
      } else {
        doorsCopy.push(newDoor);
      }
      // splice selected door with new settings
      pendingUpdateDoorsCopy = pendingUpdateDoorsCopy.filter(
        d => d.id !== newDoor.id
      );
      return {
        ...state,
        waitingServerUpdate: false,
        doors: doorsCopy,
        pendingUpdateDoors: [
          ...pendingUpdateDoorsCopy,
          Object.assign({}, action.payload)
        ]
      };
    }
    case UPDATE_DOOR_ON_SERVER_FAILED:
      return {
        ...state,
        waitingServerUpdate: false
      };
    case DOOR_INFO_UPDATE_FAILED: {
      const { lastInfoUpdateFailed } = state;

      const lastInfoUpdateFailedCopy = [...lastInfoUpdateFailed];
      lastInfoUpdateFailedCopy.push(action.payload.id);

      return {
        ...state,
        updatingDoorInfo: false,
        lastInfoUpdateFailed: lastInfoUpdateFailedCopy
      };
    }
    case CHECK_RESET_REASON_RECEIVED:
      return {
        ...state,
        lastResetReason: action.payload
      };
    case FIRMWARE_UPDATE_PROMPTED:
      return {
        ...state,
        promptedFirmwareUpdate: true
      };
    case RESET_DOOR_CONNECTION_STATE:
      return {
        ...state,
        lastResetReason: 0,
        fetchingInfo: false,
        fetchedCurrentDoorInfo: false,
        lastCompletedSchedule: null,
        connectedDoorHasRemoteID: undefined,
        connectedDoorHasRemoteKey: undefined,
        localDoorConnected: false,
        remoteDoorConnected: false,
        remoteDoorPresent: false,
        remoteDoorSubscribeCount: 0,
        currentDoorOpenStatus: {},
        statusTimeDelta: {},
        updatingDoorInfo: false,
        localDoors: [],
        connectedDoors: [],
        mdnsErrorMsg: '',
        mqttOnline: false,
        promptedFirmwareUpdate: false,
        connectedDoorSettings: {},
        currentDoorUsingBetaFirmware: false,
        connectedDoorTime: '',
        connectedDoorACVoltage: -1
      };
    case RESET_DOOR_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
let doorInfoTimer = null;
let responseTimeout = 0;
let responseTimeoutCnt = 0;
let serverDoor = null;

const doGetDoorInfo = () => {
  // return new BehaviorSubject(true);
  const { door: doorState } = store.getState();
  const selectedDoor = [...doorState.doors].find(
    d => d.id === doorState.selectedDoor
  );
  responseTimeout += 1;
  // check timeout door info
  // if timeout
  // s.next(false);
  if (doorState.waitingResponse) {
    // if no response in x seconds,
    // try again
    // console.log("response timeout is:", responseTimeout);
    if (responseTimeout > 100) {
      store.dispatch(doorResponseTimeout());
      if (++responseTimeoutCnt > 3) {
        responseTimeoutCnt = 0;
      }
      store.dispatch(decrementInfoState());
    }
    return new BehaviorSubject(false);
  }

  if (
    !doorState.localDoorConnected &&
    !doorState.remoteDoorPresent &&
    selectedDoor
  ) {
    clearInterval(doorInfoTimer);
    store.dispatch(getDoorInfoFailed());
    return new BehaviorSubject(false);
  }
  if (!selectedDoor) {
    clearInterval(doorInfoTimer);
    store.dispatch(getDoorInfoFailed());
    return new BehaviorSubject(false);
  }
  responseTimeout = 0;
  switch (doorState.doorInfoState) {
    case FSM_INIT_GET_INFO: {
      serverDoor = [...doorState.serverDoors].find(
        d => d.id === doorState.selectedDoor
      );
      if (serverDoor) {
        serverDoor = {
          ...serverDoor
        };
      }
      // setup the timer
      clearInterval(doorInfoTimer);
      doorInfoTimer = setInterval(() => {
        doGetDoorInfo();
      }, 10);
      // get isOnline?
      // store.dispatch(localDoorPing((new Date()).valueOf()));
      store.dispatch(incrementInfoState());
      break;
    }
    case FSM_GET_HWINFO:
      store.dispatch(getHWInfo());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_SETTINGS:
      store.dispatch(getSettings());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_NAME:
      store.dispatch(getDoorName());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_STATE:
      store.dispatch(getDoorStatus());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_POWER:
      store.dispatch(getDoorPower());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_BATTERY:
      store.dispatch(getDoorBattery());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_NOTIFICATION_SETTINGS:
      store.dispatch(getNotifications());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_HAS_REMOTE_ID:
      store.dispatch(getDoorHasRemoteID());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_DOOR_HAS_REMOTE_KEY:
      store.dispatch(getDoorHasRemoteKey());
      store.dispatch(incrementInfoState());
      break;
    case FSM_GET_SCHEDULE_LIST:
      store.dispatch(getScheduleList());
      store.dispatch(incrementInfoState());
      break;
    case FSM_PURGE_PENDING_SCHEDULES: {
      let pendingSchedules = [];
      let pendingDeleteSchedules = [];
      if (selectedDoor.pendingSchedules) {
        ({ pendingSchedules } = selectedDoor);
      }
      if (selectedDoor.schedules) {
        const deleteOnlySchedules = selectedDoor.schedules
          .slice()
          .filter(s => s.pendingDelete);
        if (deleteOnlySchedules) {
          pendingDeleteSchedules = deleteOnlySchedules;
        }
      }
      if (
        pendingDeleteSchedules.length === 0 &&
        pendingSchedules.length === 0
      ) {
        store.dispatch(incrementInfoState());
      } else if (pendingDeleteSchedules && pendingDeleteSchedules.length > 0) {
        const [firstDeleteSchedule] = pendingDeleteSchedules;
        store.dispatch(
          deleteSchedule(firstDeleteSchedule.index, firstDeleteSchedule)
        );
      } else if (pendingSchedules.length > 0) {
        const [firstPendingSchedule] = pendingSchedules;
        store.dispatch(
          setSchedule(firstPendingSchedule.index, firstPendingSchedule)
        );
      }
      break;
    }
    case FSM_GET_SCHEDULES: {
      // start with fetchedSchedules [] and
      // scheduleList [ ... ]
      // go until fetchedSchedules matches scheduleList
      let awaitingSchedules = [];

      if (selectedDoor.scheduleList) {
        awaitingSchedules = selectedDoor.scheduleList
          .slice()
          .filter(s => doorState.fetchedSchedules.indexOf(s) === -1);
      }
      if (awaitingSchedules.length === 0) {
        // get list of schedules with
        store.dispatch(incrementInfoState());
      } else {
        const [firstSchedule] = awaitingSchedules;
        store.dispatch(getSchedule(firstSchedule));
      }
      break;
    }
    case FSM_CHECK_REMOTE_KEYS: {
      if (
        doorState.connectedDoorHasRemoteKey &&
        doorState.connectedDoorHasRemoteID
      ) {
        store.dispatch(incrementInfoState());
        break;
      }
      break;
    }
    case FSM_CHECK_RESET_REASON: {
      store.dispatch(checkResetReason());
      store.dispatch(incrementInfoState());
      break;
    }
    default:
      serverDoor = null;
      clearInterval(doorInfoTimer);
      store.dispatch(gotDoorInfo());
      break;
  }
  return new BehaviorSubject(true);
};

const updateDoorInfo = () => {
  const { door: doorState } = store.getState();
  const { doors, pendingUpdateDoors, selectedDoor } = doorState;

  const pendingDoorObject = Object.assign(
    {},
    [...pendingUpdateDoors].find(d => d.id === selectedDoor)
  );
  const stateDoorObject = Object.assign(
    {},
    [...doors].find(d => d.id === selectedDoor)
  );

  const { doorInfo: stateDoorInfo } = stateDoorObject;
  const { fwVersion } = stateDoorInfo;
  const [major, minor, patch] = fwVersion.split('.').map(v => parseInt(v, 10));

  stateDoorObject.scheduleList = [];
  // stateDoorObject.doorSettings = {
  //   holdOpenTime: stateDoorObject.doorSettings.holdOpenTime,
  // };

  pendingDoorObject.scheduleList = [];
  // pendingDoorObject.doorSettings = {
  //   holdOpenTime: pendingDoorObject.doorSettings.holdOpenTime,
  // };
  const pendingDoorString = JSON.stringify(pendingDoorObject);
  const stateDoorString = JSON.stringify(stateDoorObject);

  responseTimeout += 1;
  // check timeout door info
  // if timeout
  // s.next(false);
  if (doorState.waitingResponse) {
    // if no response in x seconds,
    // try again
    // console.log("response timeout is:", responseTimeout);
    if (responseTimeout > 100) {
      store.dispatch(doorResponseTimeout());
      if (++responseTimeoutCnt > 3) {
        // eslint-disable-line no-plusplus
        responseTimeoutCnt = 0;
      }
      store.dispatch(decrementUpdateInfoState());
    }
    return new BehaviorSubject(false);
  }

  if (doorState.waitingResponse) {
    return new BehaviorSubject(false);
  }
  if (
    !doorState.localDoorConnected &&
    !doorState.remoteDoorConnected &&
    selectedDoor &&
    pendingDoorObject
  ) {
    clearInterval(doorInfoTimer);
    store.dispatch(doorInfoUpdateFailed(pendingDoorObject));
    return new BehaviorSubject(false);
  }
  if (!stateDoorObject) {
    clearInterval(doorInfoTimer);
    store.dispatch(doorInfoUpdateFailed(pendingDoorObject));
    return new BehaviorSubject(false);
  }
  // // add a 1 second delay that's canceled if update attempted multiple times
  // if (start) {
  //   const s = new Subject();

  //   clearInterval(doorInfoTimer);
  //   doorInfoTimer = setTimeout(() => { updateDoorInfo(false); s.next(true); }, 3000);
  //   return s.asObservable();
  // }
  responseTimeout = 0;
  switch (doorState.doorUpdateInfoState) {
    case FSM_INIT_UPDATE_INFO:
      clearInterval(doorInfoTimer);
      doorInfoTimer = setInterval(() => {
        updateDoorInfo();
      }, 10);
      // get isOnline?
      store.dispatch(incrementUpdateInfoState());
      break;
    case FSM_UPDATE_DOOR_NAME:
      if (stateDoorObject.name === pendingDoorObject.name) {
        store.dispatch(incrementUpdateInfoState());
      } else {
        store.dispatch(setDoorName(pendingDoorObject.name));
      }
      break;
    case FSM_UPDATE_DOOR_HOLD_TIME: {
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (stateDoorSettings.holdOpenTime === pendingDoorSettings.holdOpenTime) {
        store.dispatch(incrementUpdateInfoState());
      } else {
        store.dispatch(setHoldTime(pendingDoorSettings.holdOpenTime));
      }
      break;
    }
    case FSM_UPDATE_DOOR_TIMEZONE: {
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (stateDoorSettings.timezone === pendingDoorSettings.timezone) {
        store.dispatch(incrementUpdateInfoState());
      } else {
        store.dispatch(setTimezone(pendingDoorSettings.timezone));
      }
      break;
    }
    case FSM_UPDATE_DOOR_LOCKOUTS: {
      if (major === 1 && minor === 1 && patch < 80) {
        store.dispatch(incrementUpdateInfoState());
        break;
      }
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (
        stateDoorSettings.outsideSensorSafetyLock !==
        pendingDoorSettings.outsideSensorSafetyLock
      ) {
        if (pendingDoorSettings.outsideSensorSafetyLock) {
          store.dispatch(enableOutsideSensorSafetyLock());
        } else {
          store.dispatch(disableOutsideSensorSafetyLock());
        }
      } else {
        store.dispatch(incrementUpdateInfoState());
      }
      break;
    }
    case FSM_UPDATE_DOOR_CMD_LOCKOUT: {
      if (major === 1 && minor === 1 && patch < 90) {
        store.dispatch(incrementUpdateInfoState());
        break;
      }
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (
        stateDoorSettings.allowCmdLockout !==
        pendingDoorSettings.allowCmdLockout
      ) {
        if (pendingDoorSettings.allowCmdLockout) {
          store.dispatch(enableCmdLockout());
        } else {
          store.dispatch(disableCmdLockout());
        }
      } else {
        store.dispatch(incrementUpdateInfoState());
      }
      break;
    }
    case FSM_UPDATE_SENSOR_TRIGGER_VOLTAGE: {
      if (major === 1 && minor === 1 && patch < 100) {
        store.dispatch(incrementUpdateInfoState());
        break;
      }
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (
        stateDoorSettings.sensorTriggerVoltage !==
        pendingDoorSettings.sensorTriggerVoltage
      ) {
        store.dispatch(
          setSensorTriggerVoltage(pendingDoorSettings.sensorTriggerVoltage)
        );
      } else {
        store.dispatch(incrementUpdateInfoState());
      }
      break;
    }
    case FSM_UPDATE_SLEEP_SENSOR_TRIGGER_VOLTAGE: {
      if (major === 1 && minor === 1 && patch < 100) {
        store.dispatch(incrementUpdateInfoState());
        break;
      }
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      if (
        stateDoorSettings.sleepSensorTriggerVoltage !==
        pendingDoorSettings.sleepSensorTriggerVoltage
      ) {
        store.dispatch(
          setSleepSensorTriggerVoltage(
            pendingDoorSettings.sleepSensorTriggerVoltage
          )
        );
      } else {
        store.dispatch(incrementUpdateInfoState());
      }
      break;
    }
    case FSM_UPDATE_DOOR_OPTIONS: {
      if (major === 1 && minor === 1 && patch < 80) {
        store.dispatch(incrementUpdateInfoState());
        break;
      }
      const { doorSettings: stateDoorSettings } = stateDoorObject;
      const { doorSettings: pendingDoorSettings } = pendingDoorObject;
      const { doorOptions: stateOptions } = stateDoorSettings;
      const { doorOptions: pendingOptions } = pendingDoorSettings;
      if (stateOptions !== pendingOptions) {
        if (
          (pendingOptions & DoorBits.AUTO_RETRACT_BIT) !== 0 &&
          (stateOptions & DoorBits.AUTO_RETRACT_BIT) === 0
        ) {
          // should auto retract be on?
          store.dispatch(enableAutoRetract());
        } else if (
          (pendingOptions & DoorBits.AUTO_RETRACT_BIT) === 0 &&
          (stateOptions & DoorBits.AUTO_RETRACT_BIT) !== 0
        ) {
          store.dispatch(disableAutoRetract());
        }
      } else {
        store.dispatch(incrementUpdateInfoState());
      }
      break;
    }
    case FSM_UPDATE_DOOR_NOTIFICATIONS: {
      const { notifications: stateNotifications } = stateDoorObject;
      const { notifications: pendingNotifications } = pendingDoorObject;
      const notificationsString = JSON.stringify(stateNotifications);
      const pendingNotificationsString = JSON.stringify(pendingNotifications);
      if (notificationsString === pendingNotificationsString) {
        store.dispatch(incrementUpdateInfoState());
      } else {
        store.dispatch(setNotifications(pendingNotifications));
      }
      break;
    }
    case FSM_UPDATE_DOOR_SCHEDULES: {
      // const [sched] = pendingSchedules;
      let pendingSchedules = [];
      let pendingDeleteSchedules = [];
      if (stateDoorObject.pendingSchedules) {
        ({ pendingSchedules } = stateDoorObject);
      }
      if (stateDoorObject.schedules) {
        const deleteOnlySchedules = stateDoorObject.schedules
          .slice()
          .filter(s => s.pendingDelete);
        if (deleteOnlySchedules) {
          pendingDeleteSchedules = deleteOnlySchedules;
        }
      }
      if (
        pendingDeleteSchedules.length === 0 &&
        pendingSchedules.length === 0
      ) {
        store.dispatch(incrementUpdateInfoState());
      } else if (pendingDeleteSchedules && pendingDeleteSchedules.length > 0) {
        const [firstDeleteSchedule] = pendingDeleteSchedules;
        store.dispatch(
          deleteSchedule(firstDeleteSchedule.index, firstDeleteSchedule)
        );
      } else if (pendingSchedules.length > 0) {
        const [firstPendingSchedule] = pendingSchedules;
        store.dispatch(
          setSchedule(firstPendingSchedule.index, firstPendingSchedule)
        );
      }
      // if (!sched) {
      // ifstore.dispatch(incrementUpdateInfoState());
      // } // ifelse {
      // ifstore.dispatch(setSchedule(sched.index, sched));
      break;
    }
    default:
      // if not waiting on server, OR timeout
      if (!doorState.waitingServerUpdate) {
        if (pendingDoorString === stateDoorString) {
          clearInterval(doorInfoTimer);
          store.dispatch(doorInfoUpdateSuccess(stateDoorObject));
        } else {
          clearInterval(doorInfoTimer);
          store.dispatch(doorInfoUpdateFailed(stateDoorObject));
        }
      }
      break;
  }
  return new BehaviorSubject(true);
};
// init all MQTT stuff
let mqttClient = null; //  = emitter.connect({ host, port, username: 'Phone' });
// let connectedChannelKey = '';
// let connectedChannelTopic = '';

function setupMQTTClient() {
  // on every message, print it out
  mqttClient.on('message', msg => {
    try {
      const response = JSON.parse(msg.asString());
      const doorId = msg.channel.split('/')[0];
      if (response.dir === 'd2p') {
        store.dispatch(mqttMessageReceived(doorId, JSON.parse(msg.asString())));
      }
    } catch (e) {
      store.dispatch(mqttMessageReceived(e));
    }
  });

  mqttClient.on('presence', msg => {
    switch (msg.event) {
      case 'status': {
        // console.log('PRESENCE MESSAGE:', msg);
        // find one named POWER_PET_DOOR
        // if exists, remote door connected
        const remoteDoors = msg.who.filter(
          d => d.username === 'POWER_PET_DOOR'
        );
        if (remoteDoors && remoteDoors.length > 0) {
          // store.dispatch(mqttPresenceReceived(remoteDoors));
          store.dispatch(remoteDoorPresent(msg.channel.split('/')[0]));
          // } else {
          // } store.dispatch(remoteDoorNotPresent(msg.channel.split('/')[0]));
        }
        break;
      }
      case 'subscribe': {
        // find one named POWER_PET_DOOR
        // if exists, remote door connected
        const { username } = msg.who;
        if (username === 'POWER_PET_DOOR') {
          store.dispatch(remoteDoorSubscribe(msg.channel.split('/')[0]));
        }
        break;
      }
      case 'unsubscribe': {
        // find one named POWER_PET_DOOR
        // if exists, remote door disconnected
        // const { username } = msg;
        // if (username === 'POWER_PET_DOOR') {
        //   store.dispatch(remoteDoorNotPresent());
        // }
        const { username } = msg.who;
        if (username === 'POWER_PET_DOOR') {
          store.dispatch(remoteDoorUnsubscribe(msg.channel.split('/')[0]));
        }
        break;
      }
      default:
        break;
    }
  });

  // on every message, print it out
  mqttClient.on('connect', () => {
    store.dispatch(mqttOnline());
  });
  // on every message, print it out
  mqttClient.on('disconnect', () => {
    // const { door: doorState } = store.getState();
    // const { localDoorConnected: connectedLocal } = doorState;
    // const [doorObj] = doors.filter(d => d.id === selectedDoor);
    // if (!connectedLocal) {
    //   store.dispatch(resetDoorConnectionState());
    // }
  });
  // on every message, print it out
  mqttClient.on('offline', () => {
    store.dispatch(mqttOffline());
    const { door: doorState } = store.getState();
    const { localDoorConnected: connectedLocal } = doorState;
    // const [doorObj] = doors.filter(d => d.id === selectedDoor);
    if (!connectedLocal) {
      store.dispatch(resetDoorConnectionState());
    }
  });
}
function doConnectRemoteDoor(theDoor) {
  // door) {
  const { door: doorState } = store.getState();
  if (doorState.mqttOnline && theDoor.channelKey.length > 0 && mqttClient) {
    // if not already subscribed for this door
    // get door topic for selected door
    // const { channelKey } = theDoor;
    // const doorTopic = 'ctrl';
    const { channelKey } = theDoor;
    const doorTopic = `${theDoor.id}/ctrl`;
    // if (mqttClient) {
    //   mqttClient.unsubscribe({
    //     key: connectedChannelKey,
    //     channel: connectedChannelTopic
    //   });
    //   connectedChannelTopic = '';
    //   connectedChannelKey = '';
    // }
    // connectedChannelKey = channelKey;
    // connectedChannelTopic = doorTopic;

    mqttClient.subscribe({
      key: channelKey,
      channel: doorTopic
    });
    mqttClient.presence({
      key: channelKey,
      channel: doorTopic,
      status: true,
      changes: true
    });
    return new BehaviorSubject({ door: theDoor, success: true });
  }

  return new BehaviorSubject({ door: theDoor, success: false });
}
// function resetMqttConnection() {
//   // door) {
//   if (mqttClient) {
//     mqttClient.unsubscribe({
//       key: connectedChannelKey,
//       channel: connectedChannelTopic
//     });
//     connectedChannelTopic = '';
//     connectedChannelKey = '';
//   }
//   return new BehaviorSubject({ success: true });
// }

function doSendCommand(theCommand) {
  const { door: doorState } = store.getState();
  const cmd = theCommand;
  let result = false;
  if (doorState.remoteDoorConnected) {
    // if MQTT door connected
    // const doorKey = '_fo75dcF6FOtKLMuwoj-GShU2LxR1JVL';
    // get currently selected door
    const [connectedDoor] = doorState.doors.filter(
      d => d.id === doorState.selectedDoor
    );
    const { channelKey } = connectedDoor;
    const doorTopic = `${connectedDoor.id}/ctrl`;
    cmd.msgId = doorState.nextMsgId;
    cmd.dir = 'p2d';

    store.dispatch(incrementMessageId());
    // publish a message to the chat channel
    mqttClient.publish({
      key: channelKey,
      channel: `${doorTopic}`,
      message: `${JSON.stringify(cmd)}`
    });
    result = true;
  }
  return { success: result, command: theCommand };
}
function doLocalPing(value) {
  const s = new BehaviorSubject();
  doSendCommand({ PING: value.toString() });
  setTimeout(() => {
    const { door: theDoor } = store.getState();
    if (theDoor.localDoorConnected && theDoor.lastLocalPingTime === value) {
      store.dispatch(doorPongTimeout());
    }
  }, 4000);
  return s.asObservable();
}

function doPowerOnDoor() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'POWER_ON' }));
}
function doPowerOffDoor() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'POWER_OFF' }));
}
function doRestartDoor() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ cmd: 'RESTART' }));
}
function doCheckResetReason() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ cmd: 'CHECK_RESET_REASON' }));
}
function doForceWDTTimeout() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ cmd: 'FORCE_WDT_TIMEOUT' }));
}
function doOpenAndHold() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ cmd: 'OPEN_AND_HOLD' }));
}
function doOpenDoor() {
  const s = new BehaviorSubject();
  // decide how to send the message
  doSendCommand({ cmd: 'OPEN' });
  return s.asObservable();
}
function doCloseDoor() {
  return new BehaviorSubject(doSendCommand({ cmd: 'CLOSE' }));
}
function doStartFirmwareUpdate() {
  return new BehaviorSubject(doSendCommand({ cmd: 'START_FIRMWARE_UPDATE' }));
}
function doCancelFirmwareUpdate() {
  return new BehaviorSubject(doSendCommand({ cmd: 'CANCEL_FIRMWARE_UPDATE' }));
}
function doEnableSensorOnIndoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS' })
  );
}
function doDisableSensorOnIndoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS' })
  );
}
function doEnableSensorOffIndoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS' })
  );
}
function doDisableSensorOffIndoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS' })
  );
}
function doEnableSensorOnOutdoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS' })
  );
}
function doDisableSensorOnOutdoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS' })
  );
}
function doEnableSensorOffOutdoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS' })
  );
}
function doDisableSensorOffOutdoorNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS' })
  );
}
function doEnableLowBatteryNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_LOW_BATTERY_NOTIFICATIONS' })
  );
}
function doDisableLowBatteryNotifications() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_LOW_BATTERY_NOTIFICATIONS' })
  );
}
function doSetNotifications(notifications) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_NOTIFICATIONS', notifications })
  );
}

function doEnableInside() {
  const s = new BehaviorSubject();
  // decide how to send the message
  doSendCommand({ config: 'ENABLE_INSIDE' });
  return s.asObservable();
}
function doDisableInside() {
  const s = new BehaviorSubject();
  // decide how to send the message
  doSendCommand({ config: 'DISABLE_INSIDE' });
  return s.asObservable();
}
function doEnableOutside() {
  const s = new BehaviorSubject();
  // decide how to send the message
  doSendCommand({ config: 'ENABLE_OUTSIDE' });
  return s.asObservable();
}
function doDisableOutside() {
  const s = new BehaviorSubject();
  // decide how to send the message
  doSendCommand({ config: 'DISABLE_OUTSIDE' });
  return s.asObservable();
}
function doGetDoorStatus() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_DOOR_STATUS' }));
}
function doGetDoorPower() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_POWER' }));
}
function doGetACVoltage() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_AC_VOLTAGE' }));
}
function doGetDoorBattery() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_DOOR_BATTERY' }));
}
function doGetDoorName() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_DOOR_NAME' }));
}
function doSetDoorName(name) {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'SET_DOOR_NAME', name }));
}
function doGetSettings() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_SETTINGS' }));
}
function doGetNotifications() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_NOTIFICATIONS' }));
}
function doGetSensors() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_SENSORS' }));
}
function doSetTimezone(tz) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({
      config: 'SET_TIMEZONE',
      tz
    })
  );
}
function doGetHWInfo() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_HW_INFO' }));
}
function doGetTimezone() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIMEZONE' }));
}
function doSetTime(time) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({
      config: 'SET_TIME',
      time
    })
  );
}
function doGetTime() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIME' }));
}
function doEnableTimers() {
  return new BehaviorSubject(doSendCommand({ config: 'ENABLE_TIMERS' }));
}
function doDisableTimers() {
  return new BehaviorSubject(doSendCommand({ config: 'DISABLE_TIMERS' }));
}
function doEnableOutsideSensorSafetyLock() {
  return new BehaviorSubject(
    doSendCommand({ config: 'ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK' })
  );
}
function doDisableOutsideSensorSafetyLock() {
  return new BehaviorSubject(
    doSendCommand({ config: 'DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK' })
  );
}
function doEnableAllowCmdLockout() {
  return new BehaviorSubject(doSendCommand({ config: 'ENABLE_CMD_LOCKOUT' }));
}
function doDisableAllowCmdLockout() {
  return new BehaviorSubject(doSendCommand({ config: 'DISABLE_CMD_LOCKOUT' }));
}
function doEnableAutoRetract() {
  return new BehaviorSubject(doSendCommand({ config: 'ENABLE_AUTORETRACT' }));
}
function doDisableAutoRetract() {
  return new BehaviorSubject(doSendCommand({ config: 'DISABLE_AUTORETRACT' }));
}
function doGetTimersEnabled() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIMERS_ENABLED' }));
}
function doSetSensorTriggerVoltage(voltage) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_SENSOR_TRIGGER_VOLTAGE', voltage })
  );
}
function doGetSensorTriggerVoltage() {
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_SENSOR_TRIGGER_VOLTAGE' })
  );
}
function doSetSleepSensorTriggerVoltage(voltage) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_SLEEP_SENSOR_TRIGGER_VOLTAGE', voltage })
  );
}
function doGetSleepSensorTriggerVoltage() {
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_SLEEP_SENSOR_TRIGGER_VOLTAGE' })
  );
}
function doSetWakeupCountThreshold(count) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_WAKEUP_COUNT_THRESHOLD', count })
  );
}
function doGetWakeupCountThreshold() {
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_WAKEUP_COUNT_THRESHOLD' })
  );
}
function doSetNoEnergyCountThreshold(count) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_NO_ENERGY_COUNT_THRESHOLD', count })
  );
}
function doGetNoEnergyCountThreshold() {
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_NO_ENERGY_COUNT_THRESHOLD' })
  );
}
function doSetTriggerValidCount(count) {
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_TRIGGER_VALID_COUNT', count })
  );
}
function doGetTriggerValidCount() {
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_TRIGGER_VALID_COUNT' })
  );
}
function doSetTimeC(timeC) {
  return new BehaviorSubject(doSendCommand({ config: 'SET_TIME_C', timeC }));
}
function doGetTimeC() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIME_C' }));
}
function doSetTimeD(timeD) {
  return new BehaviorSubject(doSendCommand({ config: 'SET_TIME_D', timeD }));
}
function doGetTimeD() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIME_D' }));
}
function doSetTimeF(timeF) {
  return new BehaviorSubject(doSendCommand({ config: 'SET_TIME_F', timeF }));
}
function doGetTimeF() {
  return new BehaviorSubject(doSendCommand({ config: 'GET_TIME_F' }));
}
function doGetScheduleList() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_SCHEDULE_LIST' }));
}
function doGetSchedule(index) {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'GET_SCHEDULE', index }));
}
function doSetSchedule({ index, schedule }) {
  return new BehaviorSubject(
    doSendCommand({
      config: 'SET_SCHEDULE',
      index,
      schedule
    })
  );
}
function doDeleteSchedule(index) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'DELETE_SCHEDULE', index })
  );
}
function doSetHoldTime(holdTime) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_HOLD_TIME', holdTime })
  );
}
function doGetDoorHasRemoteID() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'HAS_REMOTE_ID' }));
}
function doSetDoorRemoteID(theDoor) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_DOOR_ID', id: theDoor.id })
  );
}
function doGetDoorHasRemoteKey() {
  // decide how to send the message
  return new BehaviorSubject(doSendCommand({ config: 'HAS_REMOTE_KEY' }));
}
function doSetDoorRemoteKey(theDoor) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_MQTT_KEY', key: theDoor.channelKey })
  );
}
function doGetDoorUseBetaFirmware() {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'GET_USE_BETA_FIRMWARE' })
  );
}
function doSetDoorUseBetaFirmware(useBeta) {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'SET_USE_BETA_FIRMWARE', useBeta })
  );
}
function doCheckFirmwareUpdate() {
  // decide how to send the message
  return new BehaviorSubject(
    doSendCommand({ config: 'CHECK_FIRMWARE_UPDATE' })
  );
}

const connectMQTTServerEpic = action$ =>
  action$.pipe(
    ofType(CONNECT_MQTT_SERVER),
    map(() => {
      const useStagingServer = REACT_APP_USE_STAGING_MQTT === 'true';

      if (mqttClient) {
        mqttClient.disconnect();
      }
      if (!useStagingServer) {
        console.log('Host is:', host);
        mqttClient = emitter.connect({
          host,
          port,
          username: 'Support',
          secure: true
        });
      } else {
        mqttClient = emitter.connect({
          host: stagingHost,
          port: stagingPort,
          username: 'Support',
          secure: false
        });
        console.log('connected to staging server', stagingHost, stagingPort);
      }
      if (mqttClient) {
        setupMQTTClient();
      }
      return mqttConnectionDone();
    })
  );
const connectRemoteDoorEpic = action$ =>
  action$.pipe(
    ofType(CONNECT_REMOTE_DOOR),
    mergeMap(action =>
      doConnectRemoteDoor(action.payload).pipe(
        map(result => {
          if (result && result.success) {
            return remoteDoorConnected(result.door);
          }
          return remoteDoorConnectionFailed(result);
        })
      )
    )
  );

const doorConnectedEpic = (action$, state$) =>
  action$.pipe(
    // ofType(LOCAL_DOOR_CONNECTED, REMOTE_DOOR_SUBSCRIBE, REMOTE_DOOR_PRESENT),
    ofType(REMOTE_DOOR_PRESENT),
    withLatestFrom(state$),
    map(([, state]) => {
      // const { door: doorState } = state;
      // if (!doorState.fetchingInfo && !doorState.fetchedCurrentDoorInfo) {
      //   return getDoorInfo();
      // }
      return noAction();
    })
  );
// const selectDoorEpic = action$ =>
//   action$.pipe(
//     ofType(SELECT_DOOR),
//     mergeMap(() =>
//       resetMqttConnection().pipe(map(() => resetDoorConnectionState()))
//     )
//   );

// const selectDoorEpic = action$ =>
//   action$.pipe(
//     ofType(SELECT_DOOR),
//     mergeMap(() =>
//       resetMqttConnection().pipe(map(() => resetDoorConnectionState()))
//     )
//   );
// const deselectDoorEpic = action$ =>
//   action$.pipe(
//     ofType(DESELECT_DOOR),
//     map(() => resetDoorConnectionState())
//   );

/** ******* DOOR COMMANDS ******* */
const localPingEpic = action$ =>
  action$.pipe(
    ofType(LOCAL_DOOR_PING),
    mergeMap(action =>
      doLocalPing(action.payload).pipe(map(() => doneLocalPing()))
    )
  );
const powerOnDoorEpic = action$ =>
  action$.pipe(
    ofType(POWER_ON_DOOR),
    mergeMap(() => doPowerOnDoor().pipe(map(() => powerOnDoorSent())))
  );
const powerOffDoorEpic = action$ =>
  action$.pipe(
    ofType(POWER_OFF_DOOR),
    mergeMap(() => doPowerOffDoor().pipe(map(() => powerOffDoorSent())))
  );
const restartDoorEpic = action$ =>
  action$.pipe(
    ofType(RESTART_DOOR),
    mergeMap(() => doRestartDoor().pipe(map(() => restartDoorSent())))
  );
const checkResetReasonEpic = action$ =>
  action$.pipe(
    ofType(CHECK_RESET_REASON),
    mergeMap(() => doCheckResetReason().pipe(map(() => checkResetReasonSent())))
  );
const forceWDTTimeoutEpic = action$ =>
  action$.pipe(
    ofType(FORCE_WDT_TIMEOUT),
    mergeMap(() => doForceWDTTimeout().pipe(map(() => forceWDTTimeoutSent())))
  );
const openAndHoldEpic = action$ =>
  action$.pipe(
    ofType(OPEN_AND_HOLD),
    mergeMap(() =>
      doOpenAndHold().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const openDoorEpic = action$ =>
  action$.pipe(
    ofType(OPEN_DOOR),
    mergeMap(() => doOpenDoor().pipe(map(() => doorOpenSent())))
  );
const closeDoorEpic = action$ =>
  action$.pipe(
    ofType(CLOSE_DOOR),
    mergeMap(() =>
      doCloseDoor().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
/* PUSH NOTIFICATION ENABLE/DISABLE */
const enableSensorOnIndoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doEnableSensorOnIndoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableSensorOffIndoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doEnableSensorOffIndoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const disableSensorOffIndoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doDisableSensorOffIndoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableSensorOnIndoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doDisableSensorOnIndoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableSensorOnOutdoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doEnableSensorOnOutdoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableSensorOffOutdoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doEnableSensorOffOutdoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const disableSensorOffOutdoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doDisableSensorOffOutdoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableSensorOnOutdoorNotificationsEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS),
    mergeMap(() =>
      doDisableSensorOnOutdoorNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableLowBatteryNotificationsEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_LOW_BATTERY_NOTIFICATIONS),
    mergeMap(() =>
      doEnableLowBatteryNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const disableLowBatteryNotificationsEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_LOW_BATTERY_NOTIFICATIONS),
    mergeMap(() =>
      doDisableLowBatteryNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setNotificationsEpic = action$ =>
  action$.pipe(
    ofType(SET_NOTIFICATIONS),
    mergeMap(action =>
      doSetNotifications(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
/* END PUSH NOTIFICATION ENABLE/DISABLE */

const enableInsideEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_INSIDE),
    mergeMap(() => doEnableInside().pipe(map(() => insideEnabled())))
  );
const disableInsideEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_INSIDE),
    mergeMap(() => doDisableInside().pipe(map(() => insideDisabled())))
  );
const enableOutsideEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_OUTSIDE),
    mergeMap(() => doEnableOutside().pipe(map(() => outsideEnabled())))
  );
const disableOutsideEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_OUTSIDE),
    mergeMap(() => doDisableOutside().pipe(map(() => outsideDisabled())))
  );

/** ******* END DOOR COMMANDS ******* */

/** **** GET DOOR INFO STUFF ******** */
const getDoorInfoEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_INFO),
    mergeMap(() =>
      doGetDoorInfo().pipe(
        map(result => {
          if (result) {
            return startedDoorInfo();
          }
          return getDoorInfoFailed();
        })
      )
    )
  );
const doorNeedsUpdateEpic = action$ =>
  action$.pipe(
    ofType(DOOR_NEEDS_UPDATE),
    mergeMap(action =>
      updateDoorInfo().pipe(
        map(result => {
          if (result) {
            return startedDoorUpdateInfo();
          }
          return doorInfoUpdateFailed(action.payload);
        })
      )
    )
  );

const startFirmwareUpdateEpic = action$ =>
  action$.pipe(
    ofType(START_FIRMWARE_UPDATE),
    mergeMap(() =>
      doStartFirmwareUpdate().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const cancelFirmwareUpdateEpic = action$ =>
  action$.pipe(
    ofType(CANCEL_FIRMWARE_UPDATE),
    mergeMap(() =>
      doCancelFirmwareUpdate().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
// const firmwareUpdateSuccessEpic = action$ =>
//   action$.pipe(
//     ofType(FIRMWARE_UPDATE_SUCCESS),
//     mergeMap(() =>
//       doPromptFirmwareUpdateSuccess().pipe(
//         map(() => firmwareUpdateSuccessPrompted()),
//       ),
//     ),
//   );
// const firmwareUpdateFailedEpic = action$ =>
//   action$.pipe(
//     ofType(FIRMWARE_UPDATE_FAILED),
//     mergeMap(() =>
//       doPromptFirmwareUpdateFailed().pipe(
//         map(() => firmwareUpdateFailedPrompted()),
//       ),
//     ),
//   );
const getDoorStatusEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_STATUS),
    mergeMap(() =>
      doGetDoorStatus().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getDoorPowerEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_POWER),
    mergeMap(() =>
      doGetDoorPower().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getDoorACVoltageEpic = action$ =>
  action$.pipe(
    ofType(GET_AC_VOLTAGE),
    mergeMap(() =>
      doGetACVoltage().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getDoorBatteryEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_BATTERY),
    mergeMap(() =>
      doGetDoorBattery().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getDoorNameEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_NAME),
    mergeMap(() =>
      doGetDoorName().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setDoorNameEpic = action$ =>
  action$.pipe(
    ofType(SET_DOOR_NAME),
    mergeMap(action =>
      doSetDoorName(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getSettingsEpic = action$ =>
  action$.pipe(
    ofType(GET_SETTINGS),
    mergeMap(() =>
      doGetSettings().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getNotificationsEpic = action$ =>
  action$.pipe(
    ofType(GET_NOTIFICATIONS),
    mergeMap(() =>
      doGetNotifications().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getSensorsEpic = action$ =>
  action$.pipe(
    ofType(GET_SENSORS),
    mergeMap(() =>
      doGetSensors().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getHWInfoEpic = action$ =>
  action$.pipe(
    ofType(GET_HW_INFO),
    mergeMap(() =>
      doGetHWInfo().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimezoneEpic = action$ =>
  action$.pipe(
    ofType(GET_TIMEZONE),
    mergeMap(() =>
      doGetTimezone().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTimezoneEpic = action$ =>
  action$.pipe(
    ofType(SET_TIMEZONE),
    mergeMap(action =>
      doSetTimezone(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimeEpic = action$ =>
  action$.pipe(
    ofType(GET_TIME),
    mergeMap(() =>
      doGetTime().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTimeEpic = action$ =>
  action$.pipe(
    ofType(SET_TIME),
    mergeMap(action =>
      doSetTime(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableTimersEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_TIMERS),
    mergeMap(() =>
      doEnableTimers().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableTimersEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_TIMERS),
    mergeMap(() =>
      doDisableTimers().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableOutsideSensorSafetyLockEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK),
    mergeMap(() =>
      doEnableOutsideSensorSafetyLock().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableOutsideSensorSafetyLockEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK),
    mergeMap(() =>
      doDisableOutsideSensorSafetyLock().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableAllowCmdLockoutEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_CMD_LOCKOUT),
    mergeMap(() =>
      doEnableAllowCmdLockout().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableAllowCmdLockoutEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_CMD_LOCKOUT),
    mergeMap(() =>
      doDisableAllowCmdLockout().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const enableAutoRetractEpic = action$ =>
  action$.pipe(
    ofType(ENABLE_AUTO_RETRACT),
    mergeMap(() =>
      doEnableAutoRetract().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const disableAutoRetractEpic = action$ =>
  action$.pipe(
    ofType(DISABLE_AUTO_RETRACT),
    mergeMap(() =>
      doDisableAutoRetract().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimersEnabledEpic = action$ =>
  action$.pipe(
    ofType(GET_TIMERS_ENABLED),
    mergeMap(() =>
      doGetTimersEnabled().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const setSensorTriggerVoltageEpic = action$ =>
  action$.pipe(
    ofType(SET_SENSOR_TRIGGER_VOLTAGE),
    mergeMap(action =>
      doSetSensorTriggerVoltage(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getSensorTriggerVoltageEpic = action$ =>
  action$.pipe(
    ofType(GET_SENSOR_TRIGGER_VOLTAGE),
    mergeMap(() =>
      doGetSensorTriggerVoltage().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setSleepSensorTriggerVoltageEpic = action$ =>
  action$.pipe(
    ofType(SET_SLEEP_SENSOR_TRIGGER_VOLTAGE),
    mergeMap(action =>
      doSetSleepSensorTriggerVoltage(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getSleepSensorTriggerVoltageEpic = action$ =>
  action$.pipe(
    ofType(GET_SLEEP_SENSOR_TRIGGER_VOLTAGE),
    mergeMap(() =>
      doGetSleepSensorTriggerVoltage().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setWakeupCountThresholdEpic = action$ =>
  action$.pipe(
    ofType(SET_WAKEUP_COUNT_THRESHOLD),
    mergeMap(action =>
      doSetWakeupCountThreshold(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getWakeupCountThresholdEpic = action$ =>
  action$.pipe(
    ofType(GET_WAKEUP_COUNT_THRESHOLD),
    mergeMap(() =>
      doGetWakeupCountThreshold().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setNoEnergyCountThresholdEpic = action$ =>
  action$.pipe(
    ofType(SET_NO_ENERGY_COUNT_THRESHOLD),
    mergeMap(action =>
      doSetNoEnergyCountThreshold(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getNoEnergyCountThresholdEpic = action$ =>
  action$.pipe(
    ofType(GET_NO_ENERGY_COUNT_THRESHOLD),
    mergeMap(() =>
      doGetNoEnergyCountThreshold().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTriggerValidCountEpic = action$ =>
  action$.pipe(
    ofType(SET_TRIGGER_VALID_COUNT),
    mergeMap(action =>
      doSetTriggerValidCount(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTriggerValidCountEpic = action$ =>
  action$.pipe(
    ofType(GET_TRIGGER_VALID_COUNT),
    mergeMap(() =>
      doGetTriggerValidCount().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTimeCEpic = action$ =>
  action$.pipe(
    ofType(SET_TIME_C),
    mergeMap(action =>
      doSetTimeC(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimeCEpic = action$ =>
  action$.pipe(
    ofType(GET_TIME_C),
    mergeMap(() =>
      doGetTimeC().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTimeDEpic = action$ =>
  action$.pipe(
    ofType(SET_TIME_D),
    mergeMap(action =>
      doSetTimeD(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimeDEpic = action$ =>
  action$.pipe(
    ofType(GET_TIME_D),
    mergeMap(() =>
      doGetTimeD().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setTimeFEpic = action$ =>
  action$.pipe(
    ofType(SET_TIME_F),
    mergeMap(action =>
      doSetTimeF(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getTimeFEpic = action$ =>
  action$.pipe(
    ofType(GET_TIME_F),
    mergeMap(() =>
      doGetTimeF().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const getScheduleListEpic = action$ =>
  action$.pipe(
    ofType(GET_SCHEDULE_LIST),
    mergeMap(() =>
      doGetScheduleList().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getScheduleEpic = action$ =>
  action$.pipe(
    ofType(GET_SCHEDULE),
    mergeMap(action =>
      doGetSchedule(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setScheduleEpic = action$ =>
  action$.pipe(
    ofType(SET_SCHEDULE),
    mergeMap(action =>
      doSetSchedule(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const setScheduleSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_SCHEDULE_SUCCESS),
    withLatestFrom(state$),
    // we need to pad this with an observable so we can merge with takeUntil
    // this prevents cancelling the entire epic, I'm sure there's a better way
    mergeMap(([, state]) =>
      new BehaviorSubject(true).pipe(
        delay(3000),
        map(() => {
          const { door: doorState } = state;
          const { doors, selectedDoor } = doorState;
          const selectedDoorObject = doors.find(d => d.id === selectedDoor);
          if (selectedDoorObject) {
            return doorNeedsUpdate(selectedDoorObject);
          }
          return noAction({
            doorState,
            selectedDoor,
            doors,
            selectedDoorObject
          });
        }),
        takeUntil(action$.pipe(ofType(SET_SCHEDULE)))
      )
    )
  );
const deleteScheduleEpic = action$ =>
  action$.pipe(
    ofType(DELETE_SCHEDULE),
    mergeMap(action =>
      doDeleteSchedule(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setHoldTimeEpic = action$ =>
  action$.pipe(
    ofType(SET_HOLD_TIME),
    mergeMap(action =>
      doSetHoldTime(action.payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const getDoorHasRemoteIDEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_HAS_REMOTE_ID),
    mergeMap(() =>
      doGetDoorHasRemoteID().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setDoorRemoteIDEpic = action$ =>
  action$.pipe(
    ofType(SET_DOOR_REMOTE_ID),
    mergeMap(({ payload }) =>
      doSetDoorRemoteID(payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const getDoorHasRemoteKeyEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_HAS_REMOTE_KEY),
    mergeMap(() =>
      doGetDoorHasRemoteKey().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setDoorRemoteKeyEpic = action$ =>
  action$.pipe(
    ofType(SET_DOOR_REMOTE_KEY),
    mergeMap(({ payload }) =>
      doSetDoorRemoteKey(payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );

const getDoorUseBetaFirmwareEpic = action$ =>
  action$.pipe(
    ofType(GET_DOOR_USE_BETA_FIRMWARE),
    mergeMap(() =>
      doGetDoorUseBetaFirmware().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const setDoorUseBetaFirmwareEpic = action$ =>
  action$.pipe(
    ofType(SET_DOOR_USE_BETA_FIRMWARE),
    mergeMap(({ payload }) =>
      doSetDoorUseBetaFirmware(payload).pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
const checkFirmwareUpdateEpic = action$ =>
  action$.pipe(
    ofType(CHECK_FIRMWARE_UPDATE),
    mergeMap(() =>
      doCheckFirmwareUpdate().pipe(
        map(result => {
          if (result.success) {
            return sendCmdSuccess(result);
          }
          return sendCmdFailed(result);
        })
      )
    )
  );
/** **** END GET DOOR INFO STUFF ******** */

// message handling
const handleMessageEpic = action$ =>
  action$.pipe(
    ofType(MQTT_MESSAGE_RECEIVED, DOOR_RESPONSE_RECEIVED),
    mergeMap(({ payload: { door, msg } }) => getResponseToMessage(door, msg))
  );
// SERVER STUFF
const loadDoorsEpic = action$ =>
  action$.pipe(
    ofType(DOORS_NEED_LOAD),
    switchMap(() =>
      apiService.sendRequest('petdoors/', 'GET').pipe(
        map(result => {
          const { data } = result;
          if (data && Array.isArray(data)) {
            return doorsLoadSuccess(data);
          }
          // TEMPORARY LOAD A FAKE DOOR EVERY FAILURE
          // return doorsLoadSuccess(require('../../testdoor.json'));
          return doorsLoadFailed(data);
        }),
        catchError(err => of(doorsLoadFailed(err)))
      )
    )
  );
const loadSharedDoorsEpic = action$ =>
  action$.pipe(
    ofType(SHARED_DOORS_NEED_LOAD),
    switchMap(() =>
      apiService.sendRequest('petdoors/sharedDoors', 'GET').pipe(
        map(result => {
          const { data } = result;
          if (data) {
            return sharedDoorsLoadSuccess(data);
          }
          // TEMPORARY LOAD A FAKE DOOR EVERY FAILURE
          // return doorsLoadSuccess(require('../../testdoor.json'));
          return sharedDoorsLoadFailed(data);
        }),
        catchError(err => of(sharedDoorsLoadFailed(err)))
      )
    )
  );
const shareDoorEpic = action$ =>
  action$.pipe(
    ofType(SHARE_DOOR),
    switchMap(({ payload }) =>
      apiService
        .sendRequest(
          getShareDoorQueryString(payload.doorId, payload.email),
          'POST',
          {}
        )
        .pipe(
          map(result => {
            const { data } = result;
            if (data) {
              return shareDoorSuccess(data);
            }
            // TEMPORARY LOAD A FAKE DOOR EVERY FAILURE
            // return doorsLoadSuccess(require('../../testdoor.json'));
            return shareDoorFailed(data);
          }),
          catchError(err => of(shareDoorFailed(err)))
        )
    )
  );
const unshareDoorEpic = action$ =>
  action$.pipe(
    ofType(UNSHARE_DOOR),
    switchMap(({ payload }) =>
      apiService
        .sendRequest(
          getShareDoorQueryString(payload.doorId, payload.email),
          'DELETE',
          {}
        )
        .pipe(
          map(result => {
            const { data } = result;
            return unshareDoorSuccess(data);
          }),
          catchError(err => of(unshareDoorFailed(err)))
        )
    )
  );
const updateDoorOnServerEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_DOOR_ON_SERVER),
    switchMap(({ payload }) =>
      apiService
        .sendRequest(`petdoors/${payload.id}`, 'PATCH', getServerDoor(payload))
        .pipe(
          map(result => {
            const { data } = result;
            if (data) {
              return updateDoorOnServerSuccess(data);
            }
            return updateDoorOnServerFailed(data);
          }),
          catchError(err => of(updateDoorOnServerFailed(err)))
        )
    )
  );
export const doorEpics = combineEpics(
  connectMQTTServerEpic,
  connectRemoteDoorEpic,
  doorConnectedEpic,
  // selectDoorEpic,
  // deselectDoorEpic,
  getDoorInfoEpic,
  doorNeedsUpdateEpic,

  startFirmwareUpdateEpic,
  cancelFirmwareUpdateEpic,

  // firmwareUpdateStartedEpic,
  // firmwareUpdateSuccessEpic,
  // firmwareUpdateFailedEpic,

  // COMMAND / CONFIG EPICS
  powerOnDoorEpic,
  powerOffDoorEpic,
  restartDoorEpic,
  checkResetReasonEpic,
  forceWDTTimeoutEpic,
  openAndHoldEpic,
  openDoorEpic,
  closeDoorEpic,
  localPingEpic,

  enableSensorOnIndoorNotificationsEpic,
  enableSensorOffIndoorNotificationsEpic,
  disableSensorOnIndoorNotificationsEpic,
  disableSensorOffIndoorNotificationsEpic,
  enableSensorOnOutdoorNotificationsEpic,
  enableSensorOffOutdoorNotificationsEpic,
  disableSensorOnOutdoorNotificationsEpic,
  disableSensorOffOutdoorNotificationsEpic,
  enableLowBatteryNotificationsEpic,
  disableLowBatteryNotificationsEpic,
  setNotificationsEpic,

  enableInsideEpic,
  disableInsideEpic,
  enableOutsideEpic,
  disableOutsideEpic,
  getHWInfoEpic,
  getDoorStatusEpic,
  getDoorPowerEpic,
  getDoorACVoltageEpic,
  getDoorBatteryEpic,
  getDoorNameEpic,
  setDoorNameEpic,
  getSettingsEpic,
  getNotificationsEpic,
  getSensorsEpic,
  getTimezoneEpic,
  setTimezoneEpic,
  getTimeEpic,
  setTimeEpic,

  enableTimersEpic,
  disableTimersEpic,
  enableOutsideSensorSafetyLockEpic,
  disableOutsideSensorSafetyLockEpic,
  enableAllowCmdLockoutEpic,
  disableAllowCmdLockoutEpic,
  enableAutoRetractEpic,
  disableAutoRetractEpic,
  getTimersEnabledEpic,

  // debug stuff
  setSensorTriggerVoltageEpic,
  getSensorTriggerVoltageEpic,
  setSleepSensorTriggerVoltageEpic,
  getSleepSensorTriggerVoltageEpic,
  setWakeupCountThresholdEpic,
  getWakeupCountThresholdEpic,
  setNoEnergyCountThresholdEpic,
  getNoEnergyCountThresholdEpic,

  setTriggerValidCountEpic,
  getTriggerValidCountEpic,
  setTimeCEpic,
  getTimeCEpic,
  setTimeDEpic,
  getTimeDEpic,
  setTimeFEpic,
  getTimeFEpic,

  getScheduleListEpic,
  getScheduleEpic,

  setScheduleEpic,
  setScheduleSuccessEpic,
  deleteScheduleEpic,
  setHoldTimeEpic,
  getDoorHasRemoteIDEpic,
  setDoorRemoteIDEpic,
  getDoorHasRemoteKeyEpic,
  setDoorRemoteKeyEpic,

  getDoorUseBetaFirmwareEpic,
  setDoorUseBetaFirmwareEpic,
  checkFirmwareUpdateEpic,
  // handle messaging
  handleMessageEpic,
  // SERVER STUFF
  loadDoorsEpic,
  loadSharedDoorsEpic,
  shareDoorEpic,
  unshareDoorEpic,
  updateDoorOnServerEpic
);
export default door;
