// @format
/* @flow */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DoorBits } from '../../redux/modules/door';
const USZones = [
  { name: 'Pacific', tz: 'PST8PDT,M3.2.0,M11.1.0' },
  { name: 'Mountain Standard', tz: 'MST7' },
  { name: 'Mountain Daylight', tz: 'MST7MDT,M3.2.0,M11.1.0' },
  { name: 'Central', tz: 'CST6CDT,M3.2.0,M11.1.0' },
  { name: 'Eastern', tz: 'EST5EDT,M3.2.0,M11.1.0' },
  { name: 'Hawaii', tz: 'HST10' },
  { name: 'Alaska', tz: 'AKST9AKDT,M3.2.0,M11.1.0' },
  { name: 'Other', tz: '' }
];

type Props = {
  door: DoorState,
  selectedDoor: Door,
  enableCmdLockout: typeof actionCreatorNoParams,
  disableCmdLockout: typeof actionCreatorNoParams,
  enableAutoRetract: typeof actionCreatorNoParams,
  disableAutoRetract: typeof actionCreatorNoParams,
  enableOutsideSensorSafetyLock: typeof actionCreatorNoParams,
  disableOutsideSensorSafetyLock: typeof actionCreatorNoParams,
  enableLowBatteryNotifications: typeof actionCreatorNoParams,
  disableLowBatteryNotifications: typeof actionCreatorNoParams,
  enableSensorOnIndoorNotifications: typeof actionCreatorNoParams,
  disableSensorOnIndoorNotifications: typeof actionCreatorNoParams,
  enableSensorOffIndoorNotifications: typeof actionCreatorNoParams,
  disableSensorOffIndoorNotifications: typeof actionCreatorNoParams,
  enableSensorOnOutdoorNotifications: typeof actionCreatorNoParams,
  disableSensorOnOutdoorNotifications: typeof actionCreatorNoParams,
  enableSensorOffOutdoorNotifications: typeof actionCreatorNoParams,
  disableSensorOffOutdoorNotifications: typeof actionCreatorNoParams,

  setHoldTime: typeof setHoldTime,
  setTimezone: typeof setTimezone,
  waitingResponse: boolean
};

const useStyles = makeStyles(theme => ({
  backButton: {
    flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '40.00%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const Settings = ({
  door,
  waitingResponse,
  selectedDoor,
  enableCmdLockout,
  disableCmdLockout,
  enableAutoRetract,
  disableAutoRetract,
  enableOutsideSensorSafetyLock,
  disableOutsideSensorSafetyLock,
  setHoldTime,
  setTimezone,
  enableLowBatteryNotifications,
  disableLowBatteryNotifications,
  enableSensorOnIndoorNotifications,
  disableSensorOnIndoorNotifications,
  enableSensorOffIndoorNotifications,
  disableSensorOffIndoorNotifications,
  enableSensorOnOutdoorNotifications,
  disableSensorOnOutdoorNotifications,
  enableSensorOffOutdoorNotifications,
  disableSensorOffOutdoorNotifications
}: Props) => {
  const classes = useStyles();
  const [expanded, updateExpanded] = useState('');

  const handleExpand = pane => (event, isExpanded) => {
    updateExpanded(isExpanded ? pane : '');
  };
  const { doorSettings, notifications } = selectedDoor;
  const {
    allowCmdLockout,
    holdOpenTime,
    doorOptions,
    outsideSensorSafetyLock,
    timezone
  } = doorSettings;

  const autoRetractOn = (doorOptions & DoorBits.AUTO_RETRACT_BIT) !== 0;

  const [holdValue, updateHoldValue] = useState(holdOpenTime);
  const [timezoneValue, updateTimezone] = useState(timezone);

  const {
    sensorOnIndoorNotificationsEnabled,
    sensorOffIndoorNotificationsEnabled,
    sensorOnOutdoorNotificationsEnabled,
    sensorOffOutdoorNotificationsEnabled,
    lowBatteryNotificationsEnabled
  } = notifications;

  useEffect(() => {
    if (!waitingResponse) {
      if (holdValue !== holdOpenTime) {
        setHoldTime(holdValue);
      }

      if (timezoneValue !== timezone) {
        console.log('timezones not equal:', timezoneValue, timezone);
        setTimezone(timezoneValue);
      }
    }
  }, [
    holdOpenTime,
    holdValue,
    setHoldTime,
    timezoneValue,
    timezone,
    setTimezone,
    waitingResponse
  ]);

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item md={2} />
      <Grid item xs={12} sm={12} md={8}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <ExpansionPanel
              expanded={expanded === 'general'}
              onChange={handleExpand('general')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="general-content"
                id="general-header"
              >
                <Typography className={classes.heading}>
                  General settings
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Operational Settings
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Allow my pet to keep door open
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={allowCmdLockout}
                              onChange={() =>
                                allowCmdLockout
                                  ? disableCmdLockout()
                                  : enableCmdLockout()
                              }
                              value="allowCmdLockout"
                            />
                          }
                          label={allowCmdLockout ? 'On' : 'Off'}
                        />
                      </FormGroup>
                      <FormHelperText>
                        Your pet may cause the door to stay open as long as they
                        are facing the pet door.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      alignItems="flex-end"
                      alignContent="flex-end"
                      justify="flex-end"
                    >
                      {waitingResponse && (
                        <CircularProgress className={classes.progress} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>Timezone</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      alignItems="flex-end"
                      alignContent="flex-end"
                      justify="flex-end"
                    >
                      <Select
                        disabled={waitingResponse}
                        value={timezoneValue}
                        onChange={event => {
                          const {
                            target: { value }
                          } = event;
                          updateTimezone(value);
                          // setHoldTime(value);
                        }}
                        inputProps={{
                          name: 'timezone',
                          id: 'timezone'
                        }}
                      >
                        {USZones.map(z => (
                          <MenuItem key={z.tz} value={z.tz}>
                            {z.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>Hold Open Time</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      alignItems="flex-end"
                      alignContent="flex-end"
                      justify="flex-end"
                    >
                      <Select
                        disabled={waitingResponse}
                        value={holdValue}
                        onChange={event => {
                          const {
                            target: { value }
                          } = event;
                          updateHoldValue(value);
                          // setHoldTime(value);
                        }}
                        inputProps={{
                          name: 'holdOpenTime',
                          id: 'hold-open-time'
                        }}
                      >
                        <MenuItem value={200}>2 Seconds</MenuItem>
                        <MenuItem value={400}>4 Seconds</MenuItem>
                        <MenuItem value={600}>6 Seconds</MenuItem>
                        <MenuItem value={800}>8 Seconds</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'safety'}
              onChange={handleExpand('safety')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="safety-content"
                id="safety-header"
              >
                <Typography className={classes.heading}>
                  Safety settings
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Protect Your Pet
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Safety Auto Retract
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={autoRetractOn}
                              onChange={() =>
                                autoRetractOn
                                  ? disableAutoRetract()
                                  : enableAutoRetract()
                              }
                              value="autoRetractOn"
                            />
                          }
                          label={autoRetractOn ? 'On' : 'Off'}
                        />
                      </FormGroup>
                      <FormHelperText>
                        Your door will open if an obstruction is detected
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      alignItems="flex-end"
                      alignContent="flex-end"
                      justify="flex-end"
                    >
                      {waitingResponse && (
                        <CircularProgress className={classes.progress} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Always let my pets back inside
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={outsideSensorSafetyLock}
                              onChange={() =>
                                outsideSensorSafetyLock
                                  ? disableOutsideSensorSafetyLock()
                                  : enableOutsideSensorSafetyLock()
                              }
                              value="allowCmdLockout"
                            />
                          }
                          label={outsideSensorSafetyLock ? 'On' : 'Off'}
                        />
                      </FormGroup>
                      <FormHelperText>
                        Forces outside sensor to be enabled. This overrides any
                        timer settings you have set.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'notifications'}
              onChange={handleExpand('notifications')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="notifications-content"
                id="notifications-header"
              >
                <Typography className={classes.heading}>
                  Notification settings
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Your Alerts
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Notify Me When My Door's Battery is Low
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={lowBatteryNotificationsEnabled}
                              onChange={() =>
                                lowBatteryNotificationsEnabled
                                  ? disableLowBatteryNotifications()
                                  : enableLowBatteryNotifications()
                              }
                              value="lowBatteryNotificationsEnabled"
                            />
                          }
                          label={lowBatteryNotificationsEnabled ? 'On' : 'Off'}
                        />
                      </FormGroup>
                      <FormHelperText>
                        You will be notified when you need to charge your door's
                        battery.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      alignItems="flex-end"
                      alignContent="flex-end"
                      justify="flex-end"
                    >
                      {waitingResponse && (
                        <CircularProgress className={classes.progress} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Notify Me When My Pet Goes Outside
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={sensorOnIndoorNotificationsEnabled}
                              onChange={() =>
                                sensorOnIndoorNotificationsEnabled
                                  ? disableSensorOnIndoorNotifications()
                                  : enableSensorOnIndoorNotifications()
                              }
                              value="sensorOnIndoorNotificationsEnabled"
                            />
                          }
                          label={
                            sensorOnIndoorNotificationsEnabled ? 'On' : 'Off'
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        You will be notified when your pet goes outside.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Notify Me When My Pet Wants Outside
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={sensorOffIndoorNotificationsEnabled}
                              onChange={() =>
                                sensorOffIndoorNotificationsEnabled
                                  ? disableSensorOffIndoorNotifications()
                                  : enableSensorOffIndoorNotifications()
                              }
                              value="sensorOffIndoorNotificationsEnabled"
                            />
                          }
                          label={
                            sensorOffIndoorNotificationsEnabled ? 'On' : 'Off'
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        Get notified if your sensor is disabled but your pet
                        wants outside
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Notify Me When My Pet Comes Inside
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={sensorOnOutdoorNotificationsEnabled}
                              onChange={() =>
                                sensorOnOutdoorNotificationsEnabled
                                  ? disableSensorOnOutdoorNotifications()
                                  : enableSensorOnOutdoorNotifications()
                              }
                              value="sensorOnOutdoorNotificationsEnabled"
                            />
                          }
                          label={
                            sensorOnOutdoorNotificationsEnabled ? 'On' : 'Off'
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        You will be notified when your pet comes inside.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Notify Me When My Pet Wants Inside
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={waitingResponse}
                              checked={sensorOffOutdoorNotificationsEnabled}
                              onChange={() =>
                                sensorOffOutdoorNotificationsEnabled
                                  ? disableSensorOffOutdoorNotifications()
                                  : enableSensorOffOutdoorNotifications()
                              }
                              value="sensorOffOutdoorNotificationsEnabled"
                            />
                          }
                          label={
                            sensorOffOutdoorNotificationsEnabled ? 'On' : 'Off'
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        Get notified if your sensor is disabled but your pet
                        wants inside
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
