// @format
/* @flow */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonLight from './ButtonLight';
import DoorGraphic from './DoorGraphic';

type Props = {
  selectedDoor: Door,
  doorStatus: string,
  powerOnDoor: typeof actionCreatorNoParams,
  powerOffDoor: typeof actionCreatorNoParams,
  openDoor: typeof actionCreatorNoParams,
  enableInside: typeof actionCreatorNoParams,
  disableInside: typeof actionCreatorNoParams,
  enableOutside: typeof actionCreatorNoParams,
  disableOutside: typeof actionCreatorNoParams,
  enableTimers: typeof actionCreatorNoParams,
  disableTimers: typeof actionCreatorNoParams
};

const useStyles = makeStyles(theme => ({
  container: {},
  powerSection: {
    marginBottom: 10
  },
  batterySection: {
    marginBottom: 10
  },
  sensorSection: {
    marginBottom: 10
  }
}));
const Remote = ({
  selectedDoor,
  doorStatus,
  openDoor,
  powerOnDoor,
  powerOffDoor,
  enableTimers,
  disableTimers,
  enableInside,
  disableInside,
  enableOutside,
  disableOutside
}: Props) => {
  const classes = useStyles();
  if (selectedDoor === undefined) {
    return (
      <Container className={classes.container}>
        <CircularProgress className={classes.progress} />
      </Container>
    );
  }
  const { doorSettings } = selectedDoor;
  const {
    insideOn,
    outsideOn,
    timersEnabled,
    powerOn,
    // acPresent,
    batteryPresent,
    batteryPercent
  } = doorSettings;

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          className={classes.powerSection}
        >
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems="center" direction="column">
              <Grid container spacing={0} alignItems="center" justify="center">
                <Grid item xs={3} sm={3}>
                  <ButtonLight
                    name="Power"
                    colorOn="rgba(40, 210, 40, 1)"
                    colorOff="red"
                    enabled={powerOn}
                    onClick={() => (powerOn ? powerOffDoor() : powerOnDoor())}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <ButtonLight
                    enabled
                    name="Open Door"
                    colorOn="blue"
                    colorOff="grey"
                    hideLight
                    onClick={() => openDoor()}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <ButtonLight
                    name={timersEnabled ? 'Timers' : 'Timers'}
                    colorOn="blue"
                    colorOff="grey"
                    enabled={timersEnabled}
                    onClick={() =>
                      timersEnabled ? disableTimers() : enableTimers()
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={classes.batterySection}
      >
        <Grid item xs={6}>
          <DoorGraphic doorStatus={doorStatus} />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems="center" direction="column">
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  direction="column"
                >
                  <Typography>Battery</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={6}>
                  <ButtonLight
                    name="OK"
                    colorOn="rgba(40, 210, 40, 1)"
                    colorOff="grey"
                    hideButton
                    enabled={batteryPresent && batteryPercent > 20}
                    onClick={() => {}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonLight
                    name="Low"
                    colorOn="red"
                    colorOff="grey"
                    hideButton
                    enabled={batteryPresent && batteryPercent <= 20}
                    onClick={() => {}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems="center" direction="column">
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  direction="column"
                >
                  <Typography>Sensors</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="flex-start"
              >
                <Grid item xs={6}>
                  <ButtonLight
                    name={insideOn ? 'Inside' : 'Inside'}
                    colorOn="rgba(40, 210, 40, 1)"
                    colorOff="grey"
                    enabled={insideOn}
                    onClick={() =>
                      insideOn ? disableInside() : enableInside()
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonLight
                    name={outsideOn ? 'Outside' : 'Outside'}
                    colorOn="rgba(40, 210, 40, 1)"
                    colorOff="grey"
                    enabled={outsideOn}
                    onClick={() =>
                      outsideOn ? disableOutside() : enableOutside()
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Remote;
