import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './modules/root'; // eslint-disable-line

const composeEnhancers =
  process.env.REACT_APP_REDUX_DEBUG !== 'false' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose; // eslint-disable-line
const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: 'bfwebappacustomerdash',
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept(() => {
        const nextRootReducer = require('./modules/root').default; // eslint-disable-line
        store.replaceReducer(persistReducer(persistConfig, nextRootReducer)); // eslint-disable-line
      });
    }
  }
  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic);

  return { store, persistor };
}
