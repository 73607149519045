// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import configureStore from './redux/configureStore'; // eslint-disable-line

import LoginGate from './components/auth/LoginGate';
import Header from './components/header/Header';
import Auth from './components/auth/Auth';
import Home from './components/home/Home';
import PowerPetContainer from './components/powerpet/PowerPetContainer';
import BluefangContainer from './components/bluefang/BluefangContainer';
import theme from './theme';
// import logo from './logo.svg';

import history from './history';

import mainLoop from './mainloop';

import './App.css';

const useStyles = makeStyles(theTheme => ({
  loadingScreen: {
    zIndex: 99,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    transition: 'all 1.0s'
  },
  progress: {
    margin: theTheme.spacing(2)
  },
  pageContent: {
    flexGrow: 1,
    paddingTop: theTheme.spacing(8)
  }
}));

export const { store, persistor } = configureStore(); // eslint-disable-line

type LoadingProps = {
  // setTimeout: (cb: () => void, delay: number) => void,
  bootstrapped: boolean
};

const LoadingScreen = ({ bootstrapped }: LoadingProps) => {
  const classes = useStyles();
  const [opacity, setOpacity] = useState(1.0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (visible && bootstrapped) {
      setOpacity(0.0);
      setTimeout(() => {
        setVisible(false);
      }, 1000);
    }
  }, [bootstrapped, visible]);
  if (!visible) {
    return null;
  }
  return (
    <div className={classes.loadingScreen} style={{ opacity }}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

const App = () => {
  const classes = useStyles();
  useEffect(() => {
    mainLoop();
  }, []); // eslint-disable-line
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {(bootstrapped: boolean) => {
          return (
            <ThemeProvider theme={theme}>
              <LoadingScreen bootstrapped={bootstrapped} />
              {bootstrapped && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Router history={history} basename="/web">
                    {bootstrapped && <LoginGate />}
                    <Header />
                    <Container className={classes.pageContent}>
                      <Route exact path="/" component={Home} />
                      <Route path="/powerpet" component={PowerPetContainer} />
                      <Route path="/bluefang" component={BluefangContainer} />
                      <Route path="/manuals" component={Home} />
                      <Route path="/support" component={Home} />
                      <Route path="/auth" component={Auth} />
                    </Container>
                  </Router>
                </MuiPickersUtilsProvider>
              )}
            </ThemeProvider>
          );
        }}
      </PersistGate>
    </Provider>
  );
};

export default App;
