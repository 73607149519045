import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F3B143',
      dark: '#F3B143',
      contrastText: '#fff'
    },
    secondary: {
      main: '#3C98D7',
      dark: '#fff',
      contrastText: '#fff'
    }
  },
  transitions: {
    duration: {
      slowFade: '1.0s'
    }
  }
});

export default theme;
