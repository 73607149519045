// @format
/* @flow */
import { connect } from 'react-redux';

import Remote from './Remote';

import {
  powerOnDoor,
  powerOffDoor,
  openDoor,
  enableInside,
  disableInside,
  enableOutside,
  disableOutside,
  enableTimers,
  disableTimers
} from '../../redux/modules/door';

const mapStateToProps = ({ user, door }) => ({
  user,
  door
});
const mapDispatchToProps = dispatch => ({
  powerOnDoor: () => dispatch(powerOnDoor()),
  powerOffDoor: () => dispatch(powerOffDoor()),
  openDoor: () => dispatch(openDoor()),
  enableInside: () => dispatch(enableInside()),
  disableInside: () => dispatch(disableInside()),
  enableOutside: () => dispatch(enableOutside()),
  disableOutside: () => dispatch(disableOutside()),
  enableTimers: () => dispatch(enableTimers()),
  disableTimers: () => dispatch(disableTimers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Remote);
