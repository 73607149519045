// @flow
export function getServerDoor(door: Door) {
  return {
    ...door,
    doorSettings: JSON.stringify(door.doorSettings),
    doorStats: JSON.stringify(door.doorStats),
    doorInfo: JSON.stringify(door.doorInfo),
    schedules: JSON.stringify(door.schedules),
    notifications: JSON.stringify(door.notifications)
  };
}
export function getServerUser(user: UserProfile) {
  return {
    FirstName: user.firstname,
    LastName: user.lastname,
    Email: user.email,
    Timezone: user.timezone,
    IOSPetDoorFBNotificationID: JSON.stringify(user.iosPetDoorFbNotificationId),
    AndroidPetDoorFBNotificationID: JSON.stringify(
      user.androidPetDoorFbNotificationId
    )
  };
}
export function getShareDoorQueryString(doorId: string, email: string) {
  return `petdoors/${doorId}/shareDoor?email=${email}`;
}
export default {
  getServerDoor,
  getServerUser
};
