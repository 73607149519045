// @format
/* @flow */
import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import ActivityContainer from './ActivityContainer';
// import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  collar: Collar
};

// const useStyles = makeStyles(() => ({}));

const Dashboard = ({ collar }: Props) => {
  // const classes = useStyles();
  return (
    <>
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item xs={12} sm={12}>
          <ActivityContainer selectedCollar={collar.id} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
