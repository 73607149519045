// @format
/* @flow */
import { connect } from 'react-redux';

import PowerPet from './PowerPet';

import {
  selectDoor,
  deselectDoor,
  getDoorInfo
} from '../../redux/modules/door';

const mapStateToProps = ({ user, door }) => ({
  user,
  door
});
const mapDispatchToProps = dispatch => ({
  selectDoor: (door: Door) => dispatch(selectDoor(door)),
  deselectDoor: () => dispatch(deselectDoor()),
  getDoorInfo: () => dispatch(getDoorInfo())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PowerPet);
