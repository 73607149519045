// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

type Props = {
  expanded: boolean,
  user: UserState,
  submitRegister: typeof submitRegister,
  resetRegisterFailed: typeof resetRegisterFailed
};

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 445
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 445
    },
    margin: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 50
  },
  textField: {
    width: '80%'
  },
  actionWrap: {
    justifyContent: 'space-between'
  },
  submitButton: {
    marginLeft: 'auto'
  },
  progress: {
    margin: theme.spacing(2)
  }
}));
const Register = ({
  expanded,
  submitRegister,
  user: { registering, registerSuccess, registerFailed },
  resetRegisterFailed
}: Props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  useEffect(() => {
    resetRegisterFailed();
  }, [resetRegisterFailed]);

  if (expanded) {
    return (
      <div>
        <Typography variant="h2">Collapsed Register</Typography>
      </div>
    );
  }
  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        submitRegister(firstName, lastName, email, password);
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            Register
          </Typography>
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="First Name"
            className={classes.textField}
            targetue={firstName}
            onChange={({ target }) => setFirstName(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="Last Name"
            className={classes.textField}
            targetue={lastName}
            onChange={({ target }) => setLastName(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="Email"
            className={classes.textField}
            type="email"
            targetue={email}
            onChange={({ target }) => setEmail(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="Email Confirmation"
            className={classes.textField}
            type="email"
            targetue={emailConfirm}
            onChange={({ target }) => setEmailConfirm(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="Password"
            type="password"
            className={classes.textField}
            targetue={password}
            onChange={({ target }) => setPassword(target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            error={registerFailed}
            disabled={registering}
            id="standard-name"
            label="Password Confirmation"
            type="password"
            className={classes.textField}
            targetue={passwordConfirm}
            onChange={({ target }) => setPasswordConfirm(target.value)}
            margin="normal"
            variant="outlined"
          />
          {registering && <CircularProgress className={classes.progress} />}
        </CardContent>
        <CardActions className={classes.actionWrap}>
          <Link component={RouterLink} to="/auth/login">
            Already have an account?
          </Link>
          <Button
            type="submit"
            disabled={registering}
            className="submitButton"
            size="small"
            color="primary"
            variant="outlined"
          >
            Create Account
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default Register;
