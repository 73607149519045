// @format
/* @flow */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

type Props = {
  name: string,
  colorOn: string,
  colorOff: string,
  enabled: boolean,
  hideButton?: boolean,
  hideLabel?: boolean,
  hideLight?: boolean,
  onClick: () => void
};

const buttonSize = 30;

const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer'
  },
  containerNoPointer: {
    cursor: 'default'
  },
  lightWrap: {
    width: buttonSize,
    height: buttonSize,
    borderRadius: buttonSize
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: 10,
    marginTop: 25,
    marginBottom: 15,
    width: buttonSize * 1.5,
    height: buttonSize * 1.5,
    borderRadius: buttonSize / 4,
    backgroundColor: 'rgba(245, 245, 245, 1)',
    boxShadow: '0px 3px 7px 0px rgba(0,0,0,0.41)'
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.4,
    // shadowRadius: 1.41,
  },
  buttonDown: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.0,
    shadowRadius: 1.41,
    elevation: 0
  }
}));

const ButtonLight = ({
  name,
  colorOn,
  colorOff,
  enabled,
  hideButton,
  hideLabel,
  hideLight,
  onClick
}: Props) => {
  const classes = useStyles();
  const [buttonDown, updateButtonDown] = useState(false);
  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      disabled={!enabled}
      className={hideButton ? classes.containerNoPointer : classes.container}
      justify="space-between"
    >
      <Grid item xs={12} className={classes.row}>
        <Typography>{name}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        {!hideLight && (
          <div
            className={classes.lightWrap}
            style={{ backgroundColor: enabled ? colorOn : colorOff }}
          />
        )}
        {hideLight && <div className={classes.lightWrap} />}
      </Grid>
      <Grid item xs={12} className={classes.row}>
        {!hideButton && (
          <div
            className={clsx(classes.button, buttonDown && classes.buttonDown)}
            onClick={onClick}
            onMouseDown={() => updateButtonDown(true)}
            onMouseUp={() => updateButtonDown(false)}
            onMouseLeave={() => updateButtonDown(false)}
          >
            <svg width="100%" height="100%">
              <defs>
                <radialGradient
                  id="grad"
                  cx="50%"
                  cy="50%"
                  rx="50%"
                  ry="50%"
                  fx="50%"
                  fy="50%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stopColor="#fff" stopOpacity="0.2" />
                  <stop offset="100%" stopColor="#ccc" stopOpacity="0.4" />
                </radialGradient>
                <radialGradient
                  id="shadow"
                  cx="50%"
                  cy="50%"
                  rx="50%"
                  ry="50%"
                  fx="50%"
                  fy="50%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stopColor="#fff" stopOpacity="0.85" />
                  <stop offset="100%" stopColor="#000" stopOpacity="0.1" />
                </radialGradient>
                <radialGradient
                  id="fullshadow"
                  cx="100%"
                  cy="100%"
                  rx="100%"
                  ry="100%"
                  fx="100%"
                  fy="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stopColor="#ccc" stopOpacity="0.55" />
                  <stop offset="100%" stopColor="#000" stopOpacity="0" />
                </radialGradient>
                <mask id="shadowMask">
                  <rect x="0" y="0" width="100%" height="100%" fill="black" />
                  <rect
                    x="40%"
                    y="40%"
                    width="100%"
                    height="50%"
                    fill="url(#fullshadow)"
                  />
                  <circle cx="50%" cy="50%" r="20%" fill="black" />
                </mask>
              </defs>
              <g>
                <circle cx="50%" cy="50%" r="47%" fill="url(#grad)" />
                <circle
                  cx="50%"
                  cy="50%"
                  r="35%"
                  fill="url(#shadow)"
                  mask="url(#shadowMask)"
                />
              </g>
            </svg>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

ButtonLight.defaultProps = {
  hideButton: false,
  hideLabel: false,
  hideLight: false
};
export default ButtonLight;
