// @format
/* @flow */
import React from 'react';
// import { XYPlot, ArcSeries } from 'react-vis';
// import { RadialChart } from 'react-vis';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import clsx from 'clsx';

// import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import './ActivityCircleWidget.css';

import {
  getDay,
  getDate,
  getDaysInMonth,
  getISOWeeksInYear,
  parse,
  format
} from 'date-fns';

type Props = {
  showVerb: boolean,
  Icon: any,
  verb: string,
  progress?: number,
  percentage?: number,
  loading?: boolean,
  small: boolean
};

const useStyles = makeStyles(() => ({
  graphWrap: {
    position: 'relative'
  },
  graphSeriesWrap: {
    position: 'relative'
  },
  graph: {
    position: 'relative',
    margin: 'auto'
  },
  iconWrap: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    color: 'black',
    fontSize: 55
  },
  progressLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  verbWrap: {
    fontSize: 32,
    textAlign: 'center'
  },
  smallIcon: {
    fontSize: 24
  },
  smallVerb: {
    fontSize: 18
  },
  donut: {},
  donutHole: {},
  donutRing: {},
  donutSegment: {
    animation: 'dash 1s linear alternate infinite'
  }
}));

const ActivityCircleWidget = ({
  showVerb,
  verb,
  Icon,
  progress = 0,
  percentage = 0,
  loading = false,
  small
}: Props) => {
  const classes = useStyles();
  const angle = (percentage / 100) * 2 * Math.PI + Math.PI;
  const data = [
    { angle: percentage, color: 'blue' },
    { angle: 100 - percentage, color: '' }
  ];
  // data just needs x and y properties
  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.graphWrap}
        alignItems="center"
        justify="center"
      >
        {loading && <CircularProgress className={classes.progressLoader} />}
        <Grid item xs={6} className={classes.graphSeriesWrap}>
          <svg width="100%" viewBox="0 0 42 42" className="donut">
            <circle
              className="donutHole"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="#fff"
            />
            <circle
              className="donutRing"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="rgba(230, 230, 230, 0.5)"
              strokeWidth="3"
            />

            <circle
              className="donutSegment"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="rgba(120, 232, 120, 1.0)"
              strokeWidth="3"
              style={{
                strokeDasharray: `${percentage} ${100 - percentage}`
              }}
              strokeDasharray={`${percentage} ${100 - percentage}`}
              strokeDashoffset="-25"
              strokeLinecap="round"
            />
          </svg>
          <div className={classes.iconWrap}>
            <Icon className={clsx(small && classes.smallIcon, classes.icon)} />
          </div>
        </Grid>
        <Grid item xs={12}>
          {showVerb && (
            <Typography
              className={clsx(classes.verbWrap, small && classes.smallVerb)}
            >{`${progress} ${verb}`}</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ActivityCircleWidget;
