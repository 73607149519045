// @format
/* @flow */
import React, { useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import HearingIcon from '@material-ui/icons/Hearing';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Dashboard from './Dashboard';
import ActivityContainer from './ActivityContainer';
import RunTracking from './RunTracking';
import BarkHistory from './BarkHistory';
import ContainmentHistory from './ContainmentHistory';

import history from '../../history';
// import powerpetdoor from '../../assets/img/powerpet.jpg';

type ListProps = {
  collar: CollarState,
  user: UserState,
  match: Match
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    alignSelf: 'center'
  },
  backButton: {
    flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  locationWrap: {
    marginBottom: 20
  }
}));

const Detail = ({
  match: { params },
  collar: { collars, fetchingInfo },
  user: { user }
}: ListProps) => {
  const classes = useStyles();
  const { id } = params;
  const [selectedCollar] = collars.filter(d => d.id === id);
  const [selectedTab, changeTab] = useState(0);

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2} sm={1}>
          <IconButton
            color="inherit"
            aria-label="Back"
            onClick={() => history.goBack()}
            edge="start"
            className={classes.backButton}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h5">{`${selectedCollar.firstname}${
            selectedCollar.lastname
          }`}</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justify="space-between"
          >
            {fetchingInfo && (
              <>
                <Typography>Fetching Collar Info...</Typography>
                <CircularProgress className={classes.progress} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Paper square className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={(e, val) => changeTab(val)}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          scrollButtons="on"
        >
          <Tab icon={<DashboardIcon />} label="Dashboard" />
          <Tab icon={<FitnessCenterIcon />} label="Activity" />
          <Tab icon={<DirectionsRun />} label="Run Tracking" />
          <Tab icon={<HearingIcon />} label="Bark History" />
          <Tab
            icon={<CheckBoxOutlineBlankIcon />}
            label="Containment History"
          />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <Dashboard collar={selectedCollar} />}
      {selectedTab === 1 && <ActivityContainer selectedCollar={id} />}
      {selectedTab === 2 && <RunTracking collar={selectedCollar} />}
      {selectedTab === 3 && <BarkHistory collar={selectedCollar} />}
      {selectedTab === 4 && <ContainmentHistory collar={selectedCollar} />}
    </>
  );
};

export default Detail;
