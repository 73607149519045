// @format
/* @flow */
import React, { useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// import powerpetdoor from '../../assets/img/powerpet.jpg';

import CollarListItem from './CollarListItem';

type ListProps = {
  collar: CollarState,
  user: UserState,
  collarsNeedLoad: typeof actionCreatorNoParams
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  pageHeader: {
    textAlign: 'center'
  },
  listHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const List = ({
  collar: { collars, selectedCollar },
  user: { user },
  selectCollar,
  deselectCollar,
  collarsNeedLoad
}: ListProps) => {
  const classes = useStyles();
  const hasSharedCollars = collars.filter(c => c.userid !== user.id).length > 0;
  useEffect(() => {
    collarsNeedLoad();
  }, [collarsNeedLoad]);
  return (
    <Container>
      <Typography variant="h5" className={classes.listHeader}>
        Collars I Own
      </Typography>
      <Grid container spacing={4}>
        {collars
          .filter(c => c.userid === user.id)
          .map(c => (
            <CollarListItem collar={c} key={c.id} isOwner />
          ))}
      </Grid>
      {hasSharedCollars && (
        <>
          <Typography variant="h5" className={classes.listHeader}>
            Doors Shared With Me
          </Typography>
          <Grid container spacing={4}>
            {collars
              .filter(c => c.userid !== user.id)
              .map(c => (
                <CollarListItem collar={c} key={c.id} isOwner={false} />
              ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default List;
