// @format
/* @flow */
import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// import powerpetdoor from '../../assets/img/powerpet.jpg';

import DoorListItem from './DoorListItem';
type ListProps = {
  door: DoorState,
  user: UserState
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  pageHeader: {
    textAlign: 'center'
  },
  listHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const List = ({
  door: { currentDoorOpenStatus, remoteDoorIds, doors },
  user: { user },
  selectDoor,
  deselectDoor
}: ListProps) => {
  const classes = useStyles();
  const hasSharedDoors = doors.filter(d => d.userid !== user.id).length > 0;
  return (
    <Container>
      <Typography variant="h5" className={classes.listHeader}>
        Doors I Own
      </Typography>
      <Grid container spacing={4}>
        {doors
          .filter(d => d.userid === user.id)
          .map(d => (
            <DoorListItem
              door={d}
              key={d.id}
              status={currentDoorOpenStatus[d.id]}
              connected={remoteDoorIds.indexOf(d.id) !== -1}
            />
          ))}
      </Grid>
      {hasSharedDoors && (
        <>
          <Typography variant="h5" className={classes.listHeader}>
            Doors Shared With Me
          </Typography>
          <Grid container spacing={4}>
            {doors
              .filter(d => d.userid !== user.id)
              .map(d => (
                <DoorListItem
                  door={d}
                  key={d.id}
                  isOwner={false}
                  status={currentDoorOpenStatus[d.id]}
                  connected={remoteDoorIds.indexOf(d.id) !== -1}
                />
              ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default List;
