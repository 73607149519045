import { BehaviorSubject, Subject } from 'rxjs';

import { store } from '../App'; // eslint-disable-line

import {
  // eslint-disable-line
  refreshToken,
  loginExpired
  // resetUserState,
} from '../redux/modules/user';

export const REQUEST_TIMED_OUT = 'api/REQUEST_TIMED_OUT';

const { REACT_APP_API_URL } = process.env;

const jwtDecode = require('jwt-decode');

const getErrorMessage = err =>
  err.toLowerCase().includes('request took longer') ? 'Request Timed Out' : err;
/* eslint-disable class-methods-use-this */
class Api {
  constructor() {
    // set api URL based on environment
    this.apiURL = REACT_APP_API_URL;
    // this.apiURL = 'http://192.168.88.183:8000/';
    this.awaitingRequest = false;
    this.timeout = null;
  }

  updateApiURL() {
    this.apiURL = REACT_APP_API_URL;
  }

  sendUnauthenticatedRequest(url, method = '', data = null) {
    const reqURL = this.apiURL + url;
    switch (method) {
      case 'GET':
        return this.getRequest(reqURL);
      case 'POST':
        return this.postRequest(reqURL, data);
      case 'PATCH':
        return this.patchRequest(reqURL, data);
      case 'DELETE':
        return this.deleteRequest(reqURL);
      default:
        return new BehaviorSubject({ code: -1, msg: 'No HTTP Method present' });
    }
  }

  sendRequest(url, method = '', data = null, retry = false) {
    const reqURL = this.apiURL + url;
    if (!Api.checkAuthToken()) {
      clearTimeout(this.timeout);
      if (retry === false) {
        const s = new Subject();
        setTimeout(() => {
          this.sendRequest(url, method, data, true).subscribe(res =>
            s.next(res)
          );
          // .catch(err => s.error(err))
        }, 1000);
        // return new BehaviorSubject({ code: -1, msg: 'AUTH TOKEN EXPIRED' });
        return s.asObservable();
      }
      return new BehaviorSubject({ code: -1, msg: 'Something went wrong' });
    }
    switch (method) {
      case 'GET':
        return this.getRequest(reqURL);
      case 'POST':
        return this.postRequest(reqURL, data);
      case 'PATCH':
        return this.patchRequest(reqURL, data);
      case 'DELETE':
        return this.deleteRequest(reqURL);
      default:
        return new BehaviorSubject({ code: -1, msg: 'No HTTP Method present' });
    }
  }

  static checkAuthToken() {
    const { user: userState } = store.getState();
    const tokenValid = Api.tokenValid(userState.authToken);
    // if this is expired we need to dispatch "NEEDS AUTH TOKEN REFRESH"
    if (tokenValid === false) {
      store.dispatch(refreshToken());
    }
    return tokenValid;
  }

  static checkRefreshToken() {
    const { user: userState } = store.getState();
    const tokenValid = Api.tokenValid(userState.refreshToken);
    // if this is expired we need to dispatch "NEEDS LOGIN"
    if (tokenValid === false) {
      store.dispatch(loginExpired());
      // store.dispatch(resetUserState());
    }
    return tokenValid;
  }

  getRefreshToken() {
    const s = new Subject();
    const { user: userState } = store.getState();
    Api.checkRefreshToken();
    // this.timeout = setTimeout(() => store.dispatch(requestTimedOut()), 5000);
    fetch(`${this.apiURL}refreshToken`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `RefreshToken ${userState.refreshToken}`
      }
    })
      .then(r => r.json().then(data => ({ data, headers: r.headers, code: 1 })))
      .then(res => s.next(res))
      .catch(reqError =>
        s.next({ code: -1, msg: getErrorMessage(reqError.message) })
      );

    return s.asObservable();
  }

  getRequest(url) {
    const s = new Subject();
    const { user: userState } = store.getState();
    // this.timeout = setTimeout(() => store.dispatch(requestTimedOut()), 5000);
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/application/json',
        Authorization: `Bearer ${userState.authToken}`
      }
    })
      .then(r => r.json().then(data => ({ data, headers: r.headers })))
      .then(res => s.next(res))
      .catch(reqError =>
        s.next({ code: -1, msg: getErrorMessage(reqError.message) })
      );

    return s.asObservable();
  }

  postRequest(url, body) {
    const s = new Subject();
    const { user: userState } = store.getState();
    const formBody = Api.getFormBody(body);
    // this.timeout = setTimeout(() => store.dispatch(requestTimedOut()), 5000);

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${userState.authToken}`
      },
      body: formBody
    })
      .then(r => r.json().then(data => ({ data, headers: r.headers })))
      .then(res => s.next(res))
      .catch(reqError =>
        s.next({ code: -1, msg: getErrorMessage(reqError.message) })
      );

    return s.asObservable();
  }

  patchRequest(url, body) {
    const s = new Subject();
    const { user: userState } = store.getState();
    const formBody = Api.getFormBody(body);
    // this.timeout = setTimeout(() => store.dispatch(requestTimedOut()), 5000);
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${userState.authToken}`
      },
      body: formBody
    })
      .then(r => r.json().then(data => ({ data, headers: r.headers })))
      .then(res => s.next(res))
      .catch(reqError =>
        s.next({ code: -1, msg: getErrorMessage(reqError.message) })
      );

    return s.asObservable();
  }

  deleteRequest(url) {
    const s = new Subject();
    const { user: userState } = store.getState();
    // this.timeout = setTimeout(() => store.dispatch(requestTimedOut()), 5000);
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/application/json',
        Authorization: `Bearer ${userState.authToken}`
      }
    })
      .then(r => ({ data: r, headers: r.headers }))
      .then(res => s.next(res))
      .catch(reqError =>
        s.next({ code: -1, msg: getErrorMessage(reqError.message) })
      );

    return s.asObservable();
  }

  static tokenValid(token) {
    if (!token) {
      return true;
    }
    const decoded = jwtDecode(token);
    if (new Date().getTime() < new Date(decoded.exp * 1000)) {
      return true;
    }
    return false;
  }

  static getFormBody(data) {
    const formBody = [];
    Object.keys(data).forEach(k => {
      const encodedKey = encodeURIComponent(k);
      const encodedValue = encodeURIComponent(data[k]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });
    return formBody.join('&');
  }
}
export default new Api();
/* eslint-enable class-methods-use-this */
