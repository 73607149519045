// @format
/* @flow */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DoorGraphic from './DoorGraphic';

type ItemProps = {
  door: Door,
  status: string,
  isOwner?: boolean,
  connected: boolean
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '15rem'
  },
  doorImg: {
    maxWidth: '80%',
    maxHeight: 400,
    margin: 'auto'
  }
}));

const DoorListItem = ({ door, status, isOwner, connected }: ItemProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} md={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RouterLink to={`/powerpet/detail/${door.id}`}>
            <Paper className={classes.paper}>
              <Typography>{door.name}</Typography>
              {!isOwner && (
                <Typography>{`Owned by ${door.ownerName}`}</Typography>
              )}
              <DoorGraphic doorStatus={status} />
              <Typography>
                {connected ? 'Connected' : 'Not Connected'}
              </Typography>
            </Paper>
          </RouterLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

DoorListItem.defaultProps = {
  isOwner: true
};
export default DoorListItem;
