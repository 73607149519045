// @format
/* @flow */
import { connect } from 'react-redux';

import Login from './Login';

import { submitLogin, resetLoginFailed } from '../../redux/modules/user';

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = dispatch => ({
  submitLogin: (email: string, password: string) =>
    dispatch(submitLogin(email, password)),
  resetLoginFailed: () => dispatch(resetLoginFailed())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
