// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import PetsIcon from '@material-ui/icons/Pets';
import BookIcon from '@material-ui/icons/Book';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Link as RouterLink } from 'react-router-dom';
import { resetUserState } from '../../redux/modules/user';

import logo from '../../assets/img/logo-outline.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    opacity: 1,
    marginRight: 36,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.slowFade
    })
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '3rem'
  },
  logo: {
    display: 'block',
    maxHeight: '3rem'
  },
  hide: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.slowFade
    })
    // display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      // width: theme.spacing(9) + 1,
      width: theme.spacing(7) + 1
    },
    [theme.breakpoints.down('sm')]: {
      width: 0
      // width: theme.spacing(9) + 1,
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  toolbarElement: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  profileWrap: {
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

type Props = {
  location: Location,
  logout: typeof resetUserState
};

const Header = ({ location, logout }: Props) => {
  const { pathname } = location;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const [firstPath] = pathname.split('/').filter(p => p !== '');
  if (firstPath === 'auth') {
    return null;
  }
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Grid
            container
            alignItems="center"
            alignContent="space-between"
            direction="row"
          >
            <div className={classes.toolbarElement}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open
                })}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logoWrap}>
                <img
                  src={logo}
                  className={classes.logo}
                  alt="High Tech Pet Logo"
                />
              </div>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component={RouterLink} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <ListItem button component={RouterLink} to="/powerpet">
            <ListItemIcon>
              <SettingsRemoteIcon />
            </ListItemIcon>
            <ListItemText primary={'Power Pet'} />
          </ListItem>
          <ListItem button component={RouterLink} to="/bluefang">
            <ListItemIcon>
              <PetsIcon />
            </ListItemIcon>
            <ListItemText primary={'Bluefang'} />
          </ListItem>
          <ListItem button component={RouterLink} to="/manuals">
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary={'Manuals'} />
          </ListItem>
          <ListItem button component={RouterLink} to="/support">
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={'Support'} />
          </ListItem>
          <ListItem button component={RouterLink} to="/account">
            <ListItemIcon>
              <PersonPinIcon />
            </ListItemIcon>
            <ListItemText primary={'My Account'} />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="http://hitecpet.com"
            target="_blank"
          >
            <ListItemIcon>
              <LocalGroceryStoreIcon />
            </ListItemIcon>
            <ListItemText primary={'Store'} />
          </ListItem>
          <ListItem button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(resetUserState())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
