/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import { combineEpics, ofType } from 'redux-observable';
import {
  switchMap,
  // mergeMap,
  map,
  // withLatestFrom,
  // delay,
  // takeUntil,
  catchError
} from 'rxjs/operators';

// import { of, BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import apiService, { REQUEST_TIMED_OUT } from '../../utils/api'; // eslint-disable-line

const SELECT_COLLAR = 'collar/SELECT_COLLAR';
const DESELECT_COLLAR = 'collar/DESELECT_COLLAR';

const COLLARS_NEED_LOAD = 'collar/COLLARS_NEED_LOAD';
const COLLARS_LOAD_SUCCESS = 'collar/COLLARS_LOAD_SUCCESS';
const COLLARS_LOAD_FAILED = 'collar/COLLARS_LOAD_FAILED';

const ACTIVITY_NEEDS_LOAD = 'collar/ACTIVITY_NEEDS_LOAD';
const ACTIVITY_LOAD_SUCCESS = 'collar/ACTIVITY_LOAD_SUCCESS';
const ACTIVITY_LOAD_FAILED = 'collar/ACTIVITY_LOAD_FAILED';

const RESET_COLLAR_STATE = 'collar/RESET_COLLAR_STATE';

export const selectCollar = collar => ({
  type: SELECT_COLLAR,
  payload: collar
});
export const deselectCollar = () => ({ type: DESELECT_COLLAR });

export const collarsNeedLoad = () => ({ type: COLLARS_NEED_LOAD });
export const collarsLoadSuccess = collars => ({
  type: COLLARS_LOAD_SUCCESS,
  payload: collars
});
export const collarsLoadFailed = () => ({ type: COLLARS_LOAD_FAILED });

export const activityNeedsLoad = (dogId, interval, date) => ({
  type: ACTIVITY_NEEDS_LOAD,
  payload: { dogId, interval, date }
});
export const activityLoadSuccess = activity => ({
  type: ACTIVITY_LOAD_SUCCESS,
  payload: activity
});
export const activityLoadFailed = () => ({ type: ACTIVITY_LOAD_FAILED });

export const resetCollarState = () => ({ type: RESET_COLLAR_STATE });

const initialState = {
  collars: [],
  selectedCollar: '',
  fetchingInfo: false,
  loadingCollars: false,
  loadingCollarsFailed: false,
  loadedActivity: {},
  selectedInterval: 'day',
  activityDate: '01/01/2019',
  loadingActivity: false,
  loadingActivityFailed: false,
  loadingActivitySuccess: false
};

const collar = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_COLLAR:
      return {
        ...state,
        selectedCollar: action.payload.id
      };
    case DESELECT_COLLAR:
      return {
        ...state,
        selectedCollar: ''
      };
    case COLLARS_NEED_LOAD:
      return {
        ...state,
        loadingCollars: true,
        loadingCollarsFailed: false
      };
    case COLLARS_LOAD_SUCCESS:
      return {
        ...state,
        collars: action.payload,
        loadingCollars: false,
        loadingCollarsFailed: false
      };
    case COLLARS_LOAD_FAILED:
      return {
        ...state,
        loadingCollars: false,
        loadingCollarsFailed: true
      };

    case ACTIVITY_NEEDS_LOAD: {
      const { interval, date } = action.payload;
      return {
        ...state,
        // loadedActivity: {},
        selectedInterval: interval,
        activityDate: date,
        loadingActivity: true,
        loadingActivityFailed: false,
        loadingActivitySuccess: false
      };
    }
    case ACTIVITY_LOAD_SUCCESS:
      return {
        ...state,
        loadedActivity: action.payload,
        loadingActivity: false,
        loadingActivityFailed: false,
        loadingActivitySuccess: true
      };
    case ACTIVITY_LOAD_FAILED:
      return {
        ...state,
        loadedActivity: {},
        loadingActivity: false,
        loadingActivityFailed: true,
        loadingActivitySuccess: false
      };
    case RESET_COLLAR_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
const loadCollarsEpic = action$ =>
  action$.pipe(
    ofType(COLLARS_NEED_LOAD),
    switchMap(() =>
      apiService.sendRequest('dogs/', 'GET').pipe(
        map(result => {
          const { data } = result;
          if (data && Array.isArray(data)) {
            return collarsLoadSuccess(data);
          }
          // TEMPORARY LOAD A FAKE DOOR EVERY FAILURE
          // return doorsLoadSuccess(require('../../testdoor.json'));
          return collarsLoadFailed();
        }),
        catchError(err => of(collarsLoadFailed(err)))
      )
    )
  );

const loadActivityEpic = action$ =>
  action$.pipe(
    ofType(ACTIVITY_NEEDS_LOAD),
    switchMap(({ payload: { interval, date, dogId } }) =>
      apiService
        .sendRequest(
          `account/reports/activity?interval=${interval}&date=${date}&dog=${dogId}`,
          'GET'
        )
        .pipe(
          map(({ data }) => {
            if (data) {
              return activityLoadSuccess(data);
            }
            // TEMPORARY LOAD A FAKE DOOR EVERY FAILURE
            // return doorsLoadSuccess(require('../../testdoor.json'));
            return activityLoadFailed(data);
          }),
          catchError(err => of(activityLoadFailed(err)))
        )
    )
  );

export const collarEpics = combineEpics(loadCollarsEpic, loadActivityEpic);

export default collar;
