// @format
/* @flow */
import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import Typography from '@material-ui/core/Typography';

import List from './List';
import Detail from './Detail';

type Props = {
  door: DoorState
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    textAlign: 'center'
  },
  container: {}
}));
const PowerPet = (props: Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Route
        exact
        path="/powerpet"
        render={routeProps => <List {...routeProps} {...props} />}
      />
      <Route
        path="/powerpet/detail/:id"
        render={routeProps => <Detail {...props} {...routeProps} />}
      />
    </Container>
  );
};
export default PowerPet;
