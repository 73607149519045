import { store } from './App';

import {
  resetDoorConnectionState,
  connectMQTTServer,
  connectRemoteDoor
} from './redux/modules/door';

const APP_STARTUP = 0;
const APP_RUN = 1;

let appState = APP_STARTUP;

function monitorConnections() {
  const { door } = store.getState();
  const { mqttOnline, doors, connectedDoors } = door;
  if (mqttOnline) {
    const doorsNotConnected = doors.filter(
      d => connectedDoors.indexOf(d.id) === -1
    );
    doorsNotConnected.forEach(d => {
      store.dispatch(connectRemoteDoor(d));
    });
  } else {
    store.dispatch(connectMQTTServer());
  }
}

function mainLoop() {
  switch (appState) {
    case APP_STARTUP:
      store.dispatch(resetDoorConnectionState());
      store.dispatch(connectMQTTServer());
      appState++;
      break;
    default:
    case APP_RUN:
      monitorConnections();
      break;
  }
  setTimeout(() => mainLoop(), 1000);
}

export default mainLoop;
