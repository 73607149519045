// @format
/* @flow */
import { connect } from 'react-redux';

import PasswordReset from './PasswordReset';

import {
  resetLoginFailed,
  submitPasswordResetRequest,
  submitPasswordResetConfirm
} from '../../redux/modules/user';

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = dispatch => ({
  resetLoginFailed: () => dispatch(resetLoginFailed()),
  submitPasswordResetRequest: (email: string) =>
    dispatch(submitPasswordResetRequest(email)),
  submitPasswordResetConfirm: (
    email: string,
    token: string,
    password: string
  ) => dispatch(submitPasswordResetConfirm(email, token, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
