// @format
/* @flow */
import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
// import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  collar: Collar
};

// const useStyles = makeStyles(() => ({}));

const ContainmentHistory = ({ collar }: Props) => {
  // const classes = useStyles();
  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography>Containment History</Typography>
          <Typography>{`${collar.firstname}${collar.lastname}`}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ContainmentHistory;
