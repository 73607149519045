// @format
/* @flow */
import { connect } from 'react-redux';

import Settings from './Settings';

import {
  enableCmdLockout,
  disableCmdLockout,
  enableAutoRetract,
  disableAutoRetract,
  enableOutsideSensorSafetyLock,
  disableOutsideSensorSafetyLock,
  enableLowBatteryNotifications,
  disableLowBatteryNotifications,
  enableSensorOnIndoorNotifications,
  disableSensorOnIndoorNotifications,
  enableSensorOffIndoorNotifications,
  disableSensorOffIndoorNotifications,
  enableSensorOnOutdoorNotifications,
  disableSensorOnOutdoorNotifications,
  enableSensorOffOutdoorNotifications,
  disableSensorOffOutdoorNotifications,
  setHoldTime,
  setTimezone
} from '../../redux/modules/door';

const mapStateToProps = ({ user, door }) => ({
  user,
  door
});
const mapDispatchToProps = dispatch => ({
  enableCmdLockout: () => dispatch(enableCmdLockout()),
  disableCmdLockout: () => dispatch(disableCmdLockout()),
  enableAutoRetract: () => dispatch(enableAutoRetract()),
  disableAutoRetract: () => dispatch(disableAutoRetract()),
  enableOutsideSensorSafetyLock: () =>
    dispatch(enableOutsideSensorSafetyLock()),
  disableOutsideSensorSafetyLock: () =>
    dispatch(disableOutsideSensorSafetyLock()),
  enableLowBatteryNotifications: () =>
    dispatch(enableLowBatteryNotifications()),
  disableLowBatteryNotifications: () =>
    dispatch(disableLowBatteryNotifications()),
  enableSensorOnIndoorNotifications: () =>
    dispatch(enableSensorOnIndoorNotifications()),
  disableSensorOnIndoorNotifications: () =>
    dispatch(disableSensorOnIndoorNotifications()),
  enableSensorOffIndoorNotifications: () =>
    dispatch(enableSensorOffIndoorNotifications()),
  disableSensorOffIndoorNotifications: () =>
    dispatch(disableSensorOffIndoorNotifications()),
  enableSensorOnOutdoorNotifications: () =>
    dispatch(enableSensorOnOutdoorNotifications()),
  disableSensorOnOutdoorNotifications: () =>
    dispatch(disableSensorOnOutdoorNotifications()),
  enableSensorOffOutdoorNotifications: () =>
    dispatch(enableSensorOffOutdoorNotifications()),
  disableSensorOffOutdoorNotifications: () =>
    dispatch(disableSensorOffOutdoorNotifications()),
  setHoldTime: time => dispatch(setHoldTime(time)),
  setTimezone: tz => dispatch(setTimezone(tz))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
