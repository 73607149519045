// @format
/* @flow */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import px2Image from '../../assets/img/px2.png';

type Props = {
  doorStatus: string
};

const useStyles = makeStyles(theme => ({
  doorPanel: {
    transition: 'transform 800ms ease-in-out'
  },
  doorPanelHalfway: {
    transform: 'translateY(-25%)'
  },
  doorPanelAlmostTop: {
    transform: 'translateY(-45%)'
  },
  doorPanelOpen: {
    transform: 'translateY(-50%)'
  },
  doorPanelClosed: {}
}));

const DoorGraphic = ({ doorStatus }: Props) => {
  const animatePosition = '50';
  const classes = useStyles();
  let panelStatus = classes.doorPanelClosed;
  switch (doorStatus) {
    default:
    case 'DOOR_CLOSED':
    case 'DOOR_IDLE':
      panelStatus = classes.doorPanelClosed;
      break;
    case 'DOOR_RISING':
      panelStatus = classes.doorPanelHalfway;
      break;
    case 'DOOR_SLOWING':
      panelStatus = classes.doorPanelAlmostTop;
      break;
    case 'DOOR_KEEPUP':
    case 'DOOR_HOLDING':
      panelStatus = classes.doorPanelOpen;
      break;
    case 'DOOR_CLOSING_TOP_OPEN':
      panelStatus = classes.doorPanelHalfway;
      break;
    case 'DOOR_CLOSING_MID_OPEN':
    case 'DOOR_CLOSING':
      panelStatus = classes.doorPanelClosed;
      break;
  }
  return (
    <Grid container spacing={4} alignItems="center" justify="center">
      <Grid item xs={12}>
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          <defs>
            <mask
              id="Mask"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="440"
              height="1010"
            >
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
                fill="rgba(255, 255, 255, 0.75)"
              />
            </mask>
            <rect
              id="animatedRect"
              x="30"
              y={animatePosition}
              rx="0"
              ry="0"
              width="41"
              height="50"
            />
          </defs>
          <g mask="url(#Mask)">
            <use
              href="#animatedRect"
              fill="rgba(81, 65, 40, 0.5)"
              className={clsx(classes.doorPanel, panelStatus)}
            />
            <image
              x="25"
              y="0"
              width="50"
              height="100"
              preserveAspectRatio="xMidYMid"
              href={px2Image}
            />
          </g>
        </svg>
      </Grid>
    </Grid>
  );
};
export default DoorGraphic;
