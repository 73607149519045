// @format
/* @flow */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// import CollarGraphic from './CollarGraphic';
import bf30 from '../../assets/img/bf30.png';

type ItemProps = {
  collar: Collar,
  isOwner?: boolean
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  collarImg: {
    maxWidth: '80%',
    maxHeight: 400,
    margin: 'auto'
  }
}));

// <CollarGraphic collarStatus={status} />
const CollarListItem = ({ collar, isOwner }: ItemProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RouterLink to={`/bluefang/detail/${collar.id}`}>
            <Paper className={classes.paper}>
              <Typography>{`${collar.firstname}${collar.lastname}`}</Typography>
              <img
                src={bf30}
                alt={`${collar.firstname}${collar.lastname}`}
                className={classes.collarImg}
              />
              {!isOwner && (
                <Typography>{`Owned by ${collar.ownerName}`}</Typography>
              )}
            </Paper>
          </RouterLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

CollarListItem.defaultProps = {
  isOwner: true
};
export default CollarListItem;
