// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

type Props = {
  user: UserState,
  match: Match,
  // submitPasswordReset: typeof submitPasswordReset,
  // resetPasswordResetFailed: typeof resetPasswordResetFailed
  submitPasswordResetRequest: typeof submitPasswordResetRequest,
  submitPasswordResetConfirm: typeof submitPasswordResetConfirm,
  resetLoginFailed: typeof resetLoginFailed
};

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 445
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 445
    },
    margin: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 50
  },
  textField: {
    width: '80%'
  },
  actionWrap: {
    justifyContent: 'space-between'
  },
  submitButton: {
    marginLeft: 'auto'
  },
  progress: {
    margin: theme.spacing(2)
  },
  successText: {
    color: 'rgba(40, 210, 40, 1)'
  },
  errorText: {
    color: 'red'
  }
}));

const PasswordReset = ({
  user: {
    loggingIn,
    loginFailed,
    submittingForgotPassword,
    submittedForgotPassword,
    errorSubmittingForgotPassword
  },
  match: { params },
  resetLoginFailed,
  submitPasswordResetRequest,
  submitPasswordResetConfirm
}: // submitPasswordReset,
// resetPasswordResetFailed,
Props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorConfirmingReset, updateErrorMessage] = useState('');

  useEffect(() => {
    resetLoginFailed();
  }, [resetLoginFailed]);
  // const [password, setPassword] = useState('');
  const { token } = params;

  if (token) {
    return (
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          updateErrorMessage('');
          if (password === passwordConfirm) {
            submitPasswordResetConfirm(token, password);
          } else {
            updateErrorMessage('Passwords Must Match');
          }
        }}
      >
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h5" component="h2">
              Password Reset
            </Typography>
            <TextField
              error={errorSubmittingForgotPassword || errorConfirmingReset}
              disabled={
                submittingForgotPassword ||
                (submittedForgotPassword && !errorSubmittingForgotPassword)
              }
              id="standard-name"
              label="Password"
              className={classes.textField}
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              error={errorSubmittingForgotPassword || errorConfirmingReset}
              disabled={
                submittingForgotPassword ||
                (submittedForgotPassword && !errorSubmittingForgotPassword)
              }
              id="standard-name"
              label="Password Confirmation"
              className={classes.textField}
              type="password"
              value={passwordConfirm}
              onChange={({ target }) => setPasswordConfirm(target.value)}
              margin="normal"
              variant="outlined"
            />
            {submittingForgotPassword && (
              <CircularProgress className={classes.progress} />
            )}
            {submittedForgotPassword && !errorSubmittingForgotPassword && (
              <>
                <Typography className={classes.successText}>
                  Password Reset Submitted
                </Typography>
                <Typography className={classes.successText}>
                  Please check your email for further instructions
                </Typography>
              </>
            )}
            {errorConfirmingReset && (
              <Typography className={classes.errorText}>
                {errorConfirmingReset}
              </Typography>
            )}
            {submittedForgotPassword && errorSubmittingForgotPassword && (
              <Typography className={classes.errorText}>
                An Error ocurred, please try again
              </Typography>
            )}
          </CardContent>
          <CardActions className={classes.actionWrap}>
            <Link component={RouterLink} to="/auth/">
              Go Back
            </Link>
            <Button
              disabled={submittingForgotPassword}
              type="submit"
              className="submitButton"
              size="small"
              color="primary"
              variant="outlined"
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  }
  // useEffect(() => {
  //   resetPasswordResetFailed();
  // }, [resetPasswordResetFailed]);

  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        submitPasswordResetRequest(email);
        // submitPasswordReset(email, password);
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            Password Reset
          </Typography>
          <TextField
            error={errorSubmittingForgotPassword}
            disabled={
              submittingForgotPassword ||
              (submittedForgotPassword && !errorSubmittingForgotPassword)
            }
            id="standard-name"
            label="Email"
            className={classes.textField}
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            margin="normal"
            variant="outlined"
          />
          {submittingForgotPassword && (
            <CircularProgress className={classes.progress} />
          )}
          {submittedForgotPassword && !errorSubmittingForgotPassword && (
            <>
              <Typography className={classes.successText}>
                Password Reset Submitted
              </Typography>
              <Typography className={classes.successText}>
                Please check your email for further instructions
              </Typography>
            </>
          )}
          {submittedForgotPassword && errorSubmittingForgotPassword && (
            <Typography className={classes.errorText}>
              An Error ocurred, please try again
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.actionWrap}>
          <Link component={RouterLink} to="/auth/">
            Go Back
          </Link>
          <Button
            disabled={
              submittingForgotPassword ||
              (submittedForgotPassword && !errorSubmittingForgotPassword)
            }
            type="submit"
            className="submitButton"
            size="small"
            color="primary"
            variant="outlined"
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default PasswordReset;
