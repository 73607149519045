// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { userProfileNeedsLoad } from '../../redux/modules/user';
import { doorsNeedLoad } from '../../redux/modules/door';

type Props = {
  user: UserState,
  location: Location,
  userProfileNeedsLoad: typeof userProfileNeedsLoad,
  doorsNeedLoad: typeof doorsNeedLoad
};

const LoginGate = ({
  user,
  location: { pathname },
  userProfileNeedsLoad,
  doorsNeedLoad
}: Props) => {
  const [hasLoadedProfile, updateHasLoadedProfile] = useState(false);
  useEffect(() => {
    const { loggedIn, authToken, refreshToken } = user;
    if (loggedIn && authToken && refreshToken && !hasLoadedProfile) {
      updateHasLoadedProfile(true);
      userProfileNeedsLoad();
      doorsNeedLoad();
    } else {
      updateHasLoadedProfile(false);
    }
  }, [user]); // eslint-disable-line
  const [firstPath, ...rest] = pathname.split('/').filter(p => p !== ''); // eslinit-disable-line
  if (!user.loggedIn && firstPath !== 'auth') {
    return <Redirect to="/auth" />;
  }
  // redirect reset back to the new auth/reset
  if (firstPath === 'reset') {
    return <Redirect to={`/auth/${firstPath}/${rest.join('/')}`} />;
  }
  return <div />;
};

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = dispatch => ({
  userProfileNeedsLoad: () => dispatch(userProfileNeedsLoad()),
  doorsNeedLoad: () => dispatch(doorsNeedLoad())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginGate)
);
