// @format
/* @flow */
import { connect } from 'react-redux';

import Register from './Register';

import { submitRegister, resetRegisterFailed } from '../../redux/modules/user';

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = dispatch => ({
  submitRegister: (
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) => dispatch(submitRegister(firstname, lastname, email, password)),
  resetRegisterFailed: () => dispatch(resetRegisterFailed())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
