// @format
/* @flow */
import React from 'react';
// import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import RegisterContainer from './RegisterContainer';
import LoginContainer from './LoginContainer';
import PasswordResetContainer from './PasswordResetContainer';

import { Route } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

const useStyles = makeStyles(theme => ({
  container: {
    // height: '100vh',
  },
  logo: {
    display: 'block',
    width: '80%',
    margin: 'auto',
    marginBottom: 20
  }
}));

const Auth = (props: any) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Container maxWidth="xs">
        <img className={classes.logo} src={logo} alt="High Tech Pet Logo" />
      </Container>
      <Container>
        <Route exact path="/auth" component={LoginContainer} />
        <Route path="/auth/login" component={LoginContainer} />
        <Route path="/auth/register" component={RegisterContainer} />
        <Route exact path="/auth/reset" component={PasswordResetContainer} />
        <Route
          path="/auth/reset/:token"
          render={routeProps => (
            <PasswordResetContainer {...props} {...routeProps} />
          )}
        />
      </Container>
    </Grid>
  );
};

export default Auth;
