// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
// import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faCoins } from '@fortawesome/free-solid-svg-icons';

import PetsIcon from '@material-ui/icons/Pets';
// import TimerIcon from '@material-ui/icons/Timer';

import ActivityCircleWidget from './ActivityCircleWidget';
import ActivityGraphWidget from './ActivityGraphWidget';

import {
  format,
  // isValid,
  isSameDay,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  startOfYear,
  isWithinInterval,
  addDays,
  addWeeks,
  addMonths,
  addYears
} from 'date-fns';

import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';

type Props = {
  collar: CollarState,
  selectedCollar: string,
  activityNeedsLoad: typeof activityNeedsLoad
};

const defaultGoal = {
  minuteGoal: 1440,
  pointGoal: 4000,
  calorieGoal: 700
};

const useStyles = makeStyles(theme => ({
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  }
}));

const capitalize = s => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};
const DataTypes = () => {
  return [
    {
      name: 'Points',
      value: 'values'
    },
    {
      name: 'Calories',
      value: 'calories'
    },

    {
      name: 'Minutes',
      value: 'minutes'
    }
  ];
};
const Intervals = () => {
  return [
    {
      name: 'Day',
      value: 'day'
    },
    {
      name: 'Week',
      value: 'week'
    },
    {
      name: 'Month',
      value: 'month'
    },
    {
      name: 'Year',
      value: 'year'
    }
  ];
};
const intervalToDatepicker = (interval: string) => {
  switch (interval) {
    default:
    case 'day':
      return 'date';
    case 'week':
      return 'date';
    case 'month':
      return 'month';
    case 'year':
      return 'year';
  }
};
const Activity = ({ collar, selectedCollar, activityNeedsLoad }: Props) => {
  const {
    collars,
    loadedActivity,
    loadingActivity
    // loadingActivityFailed,
    // loadingActivitySuccess,
  } = collar;
  const classes = useStyles();
  const [dataType, updateDataType] = useState('values');
  const [interval, updateInterval] = useState('day');
  const [timeoutCheck, updateTimeoutCheck] = useState(null);
  // const [selectedDate, updateSelectedDate] = useState(new Date());
  const [selectedDate, updateSelectedDate] = useState(new Date(2018, 9, 23));
  const [visibleSelectedDate, updateVisibleSelectedDate] = useState(
    new Date(2018, 9, 23)
  );

  const chooseWeek = date => {
    updateSelectedDate(startOfWeek(new Date(date)));
  };
  const chooseMonth = date => {
    updateSelectedDate(startOfMonth(new Date(date)));
  };
  const chooseYear = date => {
    updateSelectedDate(startOfYear(new Date(date)));
  };
  const chooseInterval = date => {
    switch (interval) {
      default:
      case 'day':
        updateSelectedDate(date);
        break;
      case 'week':
        chooseWeek(date);
        break;
      case 'month':
        chooseMonth(date);
        break;
      case 'year':
        chooseYear(date);
        break;
    }
  };
  const addInterval = amount => {
    let nextDate = new Date(selectedDate);
    switch (interval) {
      default:
      case 'day':
        nextDate = addDays(nextDate, amount);
        break;
      case 'week':
        nextDate = addWeeks(nextDate, amount);
        break;
      case 'month':
        nextDate = addMonths(nextDate, amount);
        break;
      case 'year':
        nextDate = addYears(nextDate, amount);
        break;
    }
    updateVisibleSelectedDate(nextDate);
    chooseInterval(nextDate);
  };
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = new Date(date);
    let selectedDateClone = new Date(selectedDate);

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });
    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });
    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };
  const intervalDateLabelFormat = () => {
    switch (interval) {
      default:
      case 'day':
        return `${format(visibleSelectedDate, 'MMM do Y')}`;
      case 'week':
        return `Week of ${format(visibleSelectedDate, 'MMM do Y')}`;
      case 'month':
        return `${format(visibleSelectedDate, 'MMM Y')}`;
      case 'year':
        return `${format(visibleSelectedDate, 'Y')}`;
    }
  };
  useEffect(() => {
    const dateString = format(selectedDate, 'MM/dd/Y');
    clearTimeout(timeoutCheck);
    updateTimeoutCheck(
      setTimeout(() => {
        activityNeedsLoad(selectedCollar, interval, dateString);
      }, 100)
    );
  }, [selectedDate, interval]); // eslint-disable-line

  useEffect(() => {
    chooseInterval(selectedDate);
  }, [interval]); // eslint-disable-line
  useEffect(() => {
    clearTimeout(timeoutCheck);
  }, []); // eslint-disable-line
  let minuteProgress = 0;
  let pointsProgress = 0;
  let caloriesProgress = 0;
  let intervalMultiplier = 1;

  switch (interval) {
    case 'day':
      intervalMultiplier = 1;
      break;
    case 'week':
      intervalMultiplier = 7;
      break;
    case 'month':
      intervalMultiplier = 30;
      break;
    case 'year':
      intervalMultiplier = 365;
      break;
  }

  const [collarObject] = collars.filter(c => c.id === selectedCollar);
  const { fitnessGoals } = collarObject;

  // get latest goal for this date by sorting first, filtering dates after selected, and getting the last date
  // const activeGoal = fitnessGoals.sort.filter.slice(-1)
  let activeGoal = { ...defaultGoal };

  if (fitnessGoals && fitnessGoals.length > 0) {
    const [lastGoal] = fitnessGoals
      .sort((p, c) => {
        if (
          moment(p.date, 'MM/DD/YYYY').isAfter(moment(c.date, 'MM/DD/YYYY'))
        ) {
          return -1;
        }
        return 1;
      })
      .slice(-1);
    if (lastGoal) {
      activeGoal = { ...lastGoal };
    }
  }

  if (loadedActivity.minutes) {
    minuteProgress = loadedActivity.minutes.slice(1, 5).reduce((p, c) => p + c);
  }
  const minutePercentage = Math.min(
    100,
    (minuteProgress * 100) / (activeGoal.minuteGoal * intervalMultiplier)
  );
  if (loadedActivity.values) {
    pointsProgress = loadedActivity.values.slice(0, 5).reduce((p, c) => p + c);
  }
  const pointsPercentage = Math.min(
    100,
    (pointsProgress * 100) / (activeGoal.pointGoal * intervalMultiplier)
  );
  if (loadedActivity.calories) {
    caloriesProgress = loadedActivity.calories
      .slice(0, 5)
      .reduce((p, c) => p + c);
  }
  const caloriesPercentage = Math.min(
    100,
    (caloriesProgress * 100) / (activeGoal.calorieGoal * intervalMultiplier)
  );

  // const minutePercentage = 100;
  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography>Activity</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Grid item xs={2} container justify="flex-start">
            <IconButton
              onClick={() => {
                addInterval(-1);
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={8}
            container
            alignItems="center"
            alignContent="center"
            direction="row"
            justify="center"
          >
            <DatePicker
              views={[intervalToDatepicker(interval)]}
              openTo={intervalToDatepicker(interval)}
              label={capitalize(interval)}
              labelFunc={() => intervalDateLabelFormat()}
              helperText={`Select ${capitalize(interval)}`}
              value={visibleSelectedDate}
              onChange={date => updateVisibleSelectedDate(date)}
              onAccept={date => chooseInterval(date)}
              onClose={date => updateVisibleSelectedDate(date)}
              renderDay={interval === 'week' ? renderWrappedWeekDay : null}
              autoOk={false}
              disableFuture
            />
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <IconButton
              onClick={() => {
                addInterval(1);
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Grid
            item
            xs={6}
            container
            alignItems="center"
            alignContent="center"
            direction="row"
            justify="space-evenly"
          >
            <Typography>Interval</Typography>
            <Select
              value={interval}
              onChange={event => {
                const {
                  target: { value }
                } = event;
                updateInterval(value);
                // setHoldTime(value);
              }}
              inputProps={{
                name: 'interval',
                id: 'interval'
              }}
            >
              {Intervals().map(z => (
                <MenuItem key={z.value} value={z.value}>
                  {z.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems="flex-end"
            alignContent="flex-end"
            justify="flex-end"
          >
            <Select
              value={dataType}
              onChange={event => {
                const {
                  target: { value }
                } = event;
                updateDataType(value);
                // setHoldTime(value);
              }}
              inputProps={{
                name: 'dataType',
                id: 'dataType'
              }}
            >
              {DataTypes().map(z => (
                <MenuItem key={z.value} value={z.value}>
                  {z.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center" xs={12}>
          <Grid container item xs={12} sm={6} lg={4}>
            <Grid item xs={12}>
              <ActivityCircleWidget
                small={false}
                showVerb
                loading={false}
                verb="minutes"
                Icon={PetsIcon}
                progress={minuteProgress}
                percentage={minutePercentage}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCircleWidget
                small
                showVerb
                loading={false}
                verb="points"
                Icon={() => <FontAwesomeIcon icon={faCoins} />}
                progress={pointsProgress}
                percentage={pointsPercentage}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCircleWidget
                small
                showVerb
                loading={false}
                verb="calories"
                Icon={() => <FontAwesomeIcon icon={faFire} />}
                progress={caloriesProgress}
                percentage={caloriesPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <ActivityGraphWidget
            interval={loadedActivity.interval}
            data={{ ...loadedActivity }}
            dataType={dataType}
            loading={loadingActivity}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Activity;
