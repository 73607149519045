// @format
/* @flow */
import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  makeWidthFlexible
} from 'react-vis';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// import { Link as RouterLink } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
import './ActivityGraphWidget.scss';

import {
  getDay,
  getDate,
  getDaysInMonth,
  getISOWeeksInYear,
  parse,
  format
} from 'date-fns';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

type Props = {
  data: ActivityReport,
  dataType?: string,
  interval?: string,
  loading: boolean
};

const useStyles = makeStyles(() => ({
  graphWrap: {
    position: 'relative'
  },
  progressLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

function dayOfWeekLabels(index: number) {
  switch (index) {
    default:
      return '';
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
  }
}
function XAxisLabels(interval, value: number) {
  switch (interval) {
    default:
    case 'day':
      return value;
    case 'week':
      return dayOfWeekLabels(value);
  }
}
const getHourlyData = data => {
  const minDivisor = 5;
  if (!data || !data.days) {
    return [];
  }
  const minSums = new Array(1440 / minDivisor);
  let currentIndex = -1;

  data.days.forEach((d, i) => {
    if ((i / minDivisor) % 1 === 0) {
      currentIndex++;
      minSums[currentIndex] = {
        minutes: new Array(6).fill(0),
        values: new Array(6).fill(0),
        calories: new Array(6).fill(0),
        minCalories: new Array(6).fill(0)
      };
    } else {
      // combine minSums
      for (let i = 0; i < 6; i++) {
        minSums[currentIndex].minutes[i] += d.minutes[i];
        minSums[currentIndex].values[i] += d.values[i];
        minSums[currentIndex].calories[i] += d.calories[i];
        minSums[currentIndex].minCalories[i] += d.minCalories[i];
      }
    }
  });
  return minSums;
};

const getWeekData = data =>
  [...new Array(7)].map((pd, i) => {
    let placeholderDay = {
      minutes: [...new Array(5).fill(0)],
      values: [...new Array(5).fill(0)],
      calories: [...new Array(5).fill(0)]
    };

    if (data && data.days) {
      // find date that matches index
      data.days.forEach(d => {
        const dayOfWeek = getDay(parse(d.date, 'MM/dd/yyyy', new Date()));
        if (dayOfWeek === i) {
          placeholderDay = d;
        }
      });
    }
    return placeholderDay;
  });

const getMonthData = data => {
  const dataDate = parse(data.date.split('T')[0], 'yyyy-MM-dd', new Date());
  const daysInMonth = getDaysInMonth(dataDate);
  return [...new Array(daysInMonth)].map((pd, i) => {
    let placeholderDay = {
      minutes: [...new Array(5).fill(0)],
      values: [...new Array(5).fill(0)],
      calories: [...new Array(5).fill(0)],
      month: format(dataDate, 'Mo')
    };

    if (data && data.days) {
      // find date that matches index
      data.days.forEach(d => {
        const date = getDate(parse(d.date, 'MM/dd/yyyy', new Date()));
        if (date === i) {
          placeholderDay = d;
        }
      });
    }
    return placeholderDay;
  });
};
const getYearData = data =>
  [...new Array(7)].map((pd, i) => {
    let placeholderDay = {
      minutes: [...new Array(5).fill(0)],
      values: [...new Array(5).fill(0)],
      calories: [...new Array(5).fill(0)]
    };

    if (data && data.days) {
      // find date that matches index
      data.days.forEach(d => {
        const dayOfWeek = getDay(parse(d.date, 'MM/dd/yyyy', new Date()));
        if (dayOfWeek === i) {
          placeholderDay = d;
        }
      });
    }
    return placeholderDay;
  });

const formatDataForInterval = (interval, data, dataType) => {
  switch (interval) {
    default:
      return [];
    case 'day':
      const minOfDay = getHourlyData(data);
      const theType = dataType === 'calories' ? 'minCalories' : dataType;
      return [...new Array(5)].map((q, i) => ({
        key: i,
        data: minOfDay.map((d, index) => ({
          x: index,
          y: dataType === 'minutes' && i === 0 ? 0 : d[theType][i]
        }))
      }));
    case 'week':
      const daysOfWeek = getWeekData(data);
      return [...new Array(5)].map((q, i) => ({
        key: i,
        data: daysOfWeek.map((d, index) => ({
          x: index,
          y: dataType === 'minutes' && i === 0 ? 0 : d[dataType][i]
        }))
      }));
    case 'month':
      const days = getMonthData(data);
      return [...new Array(5)].map((q, i) => ({
        key: i,
        data: days.map((d, index) => ({
          x: index,
          y: dataType === 'minutes' && i === 0 ? 0 : d[dataType][i]
        }))
      }));
    case 'year':
      const daysOfYear = getYearData(data);
      return [...new Array(5)].map((q, i) => ({
        key: i,
        data: daysOfYear.map((d, index) => ({
          x: index,
          y: dataType === 'minutes' && i === 0 ? 0 : d[dataType][i]
        }))
      }));
  }
};
const ActivityGraphWidget = ({
  data,
  dataType = 'minutes',
  interval = 'day',
  loading
}: Props) => {
  const classes = useStyles();
  // data just needs x and y properties
  const formattedData = formatDataForInterval(interval, data, dataType);
  return (
    <>
      <Grid item xs={12} className={classes.graphWrap}>
        {loading && <CircularProgress className={classes.progressLoader} />}
        <FlexibleXYPlot height={300} stackBy="y">
          <HorizontalGridLines />
          {formattedData.map(d => (
            <VerticalBarSeries
              key={d.key}
              data={d.data}
              barWidth={0.25}
              animation={interval === 'day' ? null : { duration: 1000 }}
            />
          ))}
          <XAxis tickFormat={v => XAxisLabels(interval, v)} />
          <YAxis />
        </FlexibleXYPlot>
      </Grid>
    </>
  );
};

export default ActivityGraphWidget;
