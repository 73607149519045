import { combineEpics, ofType } from 'redux-observable';

import {
  switchMap,
  mergeMap,
  map,
  takeUntil,
  catchError,
  delay
} from 'rxjs/operators';

import { of } from 'rxjs';

import apiService, { REQUEST_TIMED_OUT } from '../../utils/api'; // eslint-disable-line

import history from '../../history';

const SUBMIT_LOGIN = 'user/SUBMIT_LOGIN';
const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
const LOGIN_FAILED = 'user/LOGIN_FAILED';

const LOGOUT = 'user/LOGOUT';

const SUBMIT_REGISTER = 'user/SUBMIT_REGISTER';
const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
const REGISTER_FAILED = 'user/REGISTER_FAILED';

const USER_PROFILE_NEEDS_LOAD = 'user/USER_PROFILE_NEEDS_LOAD';
const USER_PROFILE_LOAD_SUCCESS = 'user/USER_PROFILE_LOAD_SUCCESS';
const USER_PROFILE_LOAD_FAILED = 'user/USER_PROFILE_LOAD_FAILED';

const USER_PROFILE_NEEDS_UPDATE = 'user/USER_PROFILE_NEEDS_UPDATE';
const USER_PROFILE_UPDATE_SUCCESS = 'user/USER_PROFILE_UPDATE_SUCCESS';
const USER_PROFILE_UPDATE_FAILED = 'user/USER_PROFILE_UPDATE_FAILED';

const REFRESH_TOKEN = 'user/REFRESH_TOKEN';
const REFRESH_TOKEN_SUCCESS = 'user/REFRESH_TOKEN_SUCCESS';
const REFRESH_TOKEN_FAILED = 'user/REFRESH_TOKEN_FAILED';
const LOGIN_EXPIRED = 'user/LOGIN_EXPIRED';
const LOGIN_EXPIRED_REDIRECTED = 'user/LOGIN_EXPIRED_REDIRECTED';

const SUBMIT_PASSWORD_RESET_REQUEST = 'user/SUBMIT_PASSWORD_RESET_REQUEST';
const SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS =
  'user/SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS';
const SUBMIT_PASSWORD_RESET_REQUEST_FAILED =
  'user/SUBMIT_PASSWORD_RESET_REQUEST_FAILED';

const SUBMIT_PASSWORD_RESET_CONFIRM = 'user/SUBMIT_PASSWORD_RESET_CONFIRM';
const SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS =
  'user/SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS';
const SUBMIT_PASSWORD_RESET_CONFIRM_FAILED =
  'user/SUBMIT_PASSWORD_RESET_CONFIRM_FAILED';

const RESET_LOGIN_FAILED = 'user/RESET_LOGIN_FAILED';
const RESET_REGISTER_FAILED = 'user/RESET_LOGIN_FAILED';
const RESET_USER_STATE = 'user/RESET_USER_STATE';

export const submitLogin = (Email, Password) => ({
  type: SUBMIT_LOGIN,
  payload: { Email, Password }
});
export const loginSuccess = refreshToken => ({
  type: LOGIN_SUCCESS,
  payload: refreshToken
});
export const loginFailed = () => ({ type: LOGIN_FAILED });

export const submitRegister = (FirstName, LastName, Email, Password) => ({
  type: SUBMIT_REGISTER,
  payload: { FirstName, LastName, Email, Password }
});
export const registerSuccess = (Email, Password) => ({
  type: REGISTER_SUCCESS,
  payload: { Email, Password }
});
export const registerFailed = () => ({ type: REGISTER_FAILED });

export const userProfileNeedsLoad = () => ({ type: USER_PROFILE_NEEDS_LOAD });
export const userProfileLoadSuccess = user => ({
  type: USER_PROFILE_LOAD_SUCCESS,
  payload: user
});
export const userProfileLoadFailed = reason => ({
  type: USER_PROFILE_LOAD_FAILED,
  payload: reason
});

export const userProfileNeedsUpdate = user => ({
  type: USER_PROFILE_NEEDS_UPDATE,
  payload: user
});
export const userProfileUpdateSuccess = user => ({
  type: USER_PROFILE_UPDATE_SUCCESS,
  payload: user
});
export const userProfileUpdateFailed = reason => ({
  type: USER_PROFILE_UPDATE_FAILED,
  payload: reason
});

export const refreshToken = () => ({ type: REFRESH_TOKEN });
export const refreshTokenSuccess = token => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: token
});
export const refreshTokenFailed = reason => ({
  type: REFRESH_TOKEN_FAILED,
  payload: reason
});
export const loginExpired = () => ({ type: LOGIN_EXPIRED });
export const loginExpiredRedirected = () => ({
  type: LOGIN_EXPIRED_REDIRECTED
});

export const logout = () => ({ type: LOGOUT });

export const submitPasswordResetRequest = Email => ({
  type: SUBMIT_PASSWORD_RESET_REQUEST,
  payload: { Email }
});

export const submitPasswordResetRequestSuccess = () => ({
  type: SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS
});
export const submitPasswordResetRequestFailed = () => ({
  type: SUBMIT_PASSWORD_RESET_REQUEST_FAILED
});

export const submitPasswordResetConfirm = (Token, Password) => ({
  type: SUBMIT_PASSWORD_RESET_CONFIRM,
  payload: { Token, Password }
});
export const submitPasswordResetConfirmSuccess = () => ({
  type: SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS
});
export const submitPasswordResetConfirmFailed = () => ({
  type: SUBMIT_PASSWORD_RESET_CONFIRM_FAILED
});
// const SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS = 'user/SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS';
// const SUBMIT_PASSWORD_RESET_REQUEST_FAILED = 'user/SUBMIT_PASSWORD_RESET_REQUEST_FAILED';
//
// const SUBMIT_PASSWORD_RESET_CONFIRM = 'user/SUBMIT_PASSWORD_RESET_CONFIRM';
// const SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS = 'user/SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS';
// const SUBMIT_PASSWORD_RESET_CONFIRM_FAILED = 'user/SUBMIT_PASSWORD_RESET_CONFIRM_FAILED';

export const resetLoginFailed = () => ({ type: RESET_LOGIN_FAILED });
export const resetRegisterFailed = () => ({ type: RESET_REGISTER_FAILED });
export const resetUserState = () => ({ type: RESET_USER_STATE });

const initialState = {
  user: {},
  email: '',
  authToken: '',
  refreshToken: '',
  loggedIn: false,
  loggingIn: false,
  loginFailed: false,
  registering: false,
  registerFailed: false,
  registerSuccess: false,
  submittedUpdateProfile: false,
  submittingUpdateProfile: false,
  errorSubmittingUserProfile: false,
  submittingForgotPassword: false,
  submittedForgotPassword: false,
  errorSubmittingForgotPassword: false,
  failureReason: ''
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_LOGIN:
      return {
        ...state,
        email: action.payload.Email,
        loggedIn: false,
        loggingIn: true,
        loginFailed: false
      };
    case SUBMIT_PASSWORD_RESET_CONFIRM:
    case SUBMIT_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        submittingForgotPassword: true,
        submittedForgotPassword: false,
        errorSubmittingForgotPassword: false
      };
    case SUBMIT_PASSWORD_RESET_CONFIRM_SUCCESS:
    case SUBMIT_PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        submittingForgotPassword: false,
        submittedForgotPassword: true,
        errorSubmittingForgotPassword: false
      };
    case SUBMIT_PASSWORD_RESET_CONFIRM_FAILED:
    case SUBMIT_PASSWORD_RESET_REQUEST_FAILED:
      return {
        ...state,
        submittingForgotPassword: false,
        submittedForgotPassword: true,
        errorSubmittingForgotPassword: true
      };
    case SUBMIT_REGISTER:
      return {
        ...state,
        email: action.payload.Email,
        registering: true,
        registerSuccess: false,
        registerFailed: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        refreshToken: action.payload
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        registerSuccess: true,
        registerFailed: false
      };
    case REGISTER_FAILED:
      return {
        ...state,
        registering: false,
        registerSuccess: false,
        registerFailed: true
      };
    case LOGIN_EXPIRED:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginFailed: false,
        refreshToken: '',
        authToken: ''
      };
    case USER_PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        user: {
          id: action.payload.id,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
          timezone: action.payload.timezone,
          blockNotifications: action.payload.blockNotifications,
          iosPetDoorFbNotificationId: action.payload.iosPetDoorFbNotificationId,
          androidPetDoorFbNotificationId:
            action.payload.androidPetDoorFbNotificationId
        }
      };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        submittedUpdateProfile: true,
        submittingUpdateProfile: false,
        errorSubmittingUserProfile: false,
        user: {
          id: action.payload.id,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
          timezone: action.payload.timezone,
          blockNotifications: action.payload.blockNotifications,
          iosPetDoorFbNotificationId: action.payload.iosPetDoorFbNotificationId,
          androidPetDoorFbNotificationId:
            action.payload.androidPetDoorFbNotificationId
        }
      };
    case USER_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        submittedUpdateProfile: true,
        submittingUpdateProfile: false,
        errorSubmittingUserProfile: true
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        authToken: action.payload
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginFailed: true
      };
    case LOGOUT:
      return {
        ...state,
        authToken: '',
        refreshToken: '',
        loggedIn: false,
        loggingIn: false,
        loginFailed: false
      };
    case RESET_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: false,
        loggingIn: false,
        submittedForgotPassword: false,
        submittingForgotPassword: false,
        errorSubmittingForgotPassword: false
      };
    case RESET_REGISTER_FAILED:
      return {
        ...state,
        registering: false,
        registerFailed: false,
        registerSuccess: false,
        submittedForgotPassword: false,
        submittingForgotPassword: false,
        errorSubmittingForgotPassword: false
      };
    case RESET_USER_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const loginEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_LOGIN, REGISTER_SUCCESS),
    mergeMap(({ payload }) =>
      apiService.sendRequest('login', 'POST', payload).pipe(
        map(({ data, msg }) => {
          if (data.code === 1) {
            // NavigationService.navigate('Home', {});
            setTimeout(() => {
              history.push('/');
            }, 1500);
            return loginSuccess(data.refresh_token);
          }
          if (data && data.msg) {
            throw data.msg;
          } else {
            throw msg;
          }
        }),
        catchError(() => of(loginFailed())),
        takeUntil(action$.ofType(REQUEST_TIMED_OUT))
      )
    )
  );

const loginExpiredEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_EXPIRED),
    delay(1500),
    map(() => {
      // NavigationService.navigate('Home', {});
      history.push('/login');
      return loginExpiredRedirected();
    })
  );

const refreshTokenEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_SUCCESS, REFRESH_TOKEN),
    mergeMap(() =>
      apiService.getRefreshToken().pipe(
        map(({ data, code }) => {
          console.log('res is: ', data, code);
          if (code === 1) {
            // NavigationService.navigate('Home', {});
            return refreshTokenSuccess(data.auth_token);
          }
          throw data.msg;
        }),
        catchError(err => of(refreshTokenFailed(err))),
        takeUntil(action$.ofType(REQUEST_TIMED_OUT))
      )
    )
  );

const registerEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_REGISTER),
    mergeMap(({ payload }) =>
      apiService.sendRequest('register', 'POST', payload).pipe(
        map(({ data, msg }) => {
          if (data.code === 1) {
            const { Email, Password } = payload;
            // NavigationService.navigate('Home', {});
            return registerSuccess(Email, Password);
          }
          if (data && data.msg) {
            throw data.msg;
          } else {
            throw msg;
          }
        }),
        catchError(() => of(registerFailed())),
        takeUntil(action$.ofType(REQUEST_TIMED_OUT))
      )
    )
  );

const submitPasswordResetRequestEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_PASSWORD_RESET_REQUEST),
    mergeMap(({ payload }) =>
      apiService.sendRequest('passwordreset', 'POST', payload).pipe(
        map(({ data, msg }) => {
          if (data.code === 1) {
            // NavigationService.navigate('Home', {});
            return submitPasswordResetRequestSuccess();
          }
          if (data && data.msg) {
            throw data.msg;
          } else {
            throw msg;
          }
        }),
        catchError(() => of(submitPasswordResetRequestFailed())),
        takeUntil(action$.ofType(REQUEST_TIMED_OUT))
      )
    )
  );

const submitPasswordResetConfirmEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_PASSWORD_RESET_CONFIRM),
    mergeMap(({ payload }) =>
      apiService.sendRequest(`reset/${payload.Token}`, 'POST', payload).pipe(
        map(({ data, msg }) => {
          if (data.code === 1) {
            // NavigationService.navigate('Home', {});
            return submitPasswordResetConfirmSuccess();
          }
          if (data && data.msg) {
            throw data.msg;
          } else {
            throw msg;
          }
        }),
        catchError(() => of(submitPasswordResetConfirmFailed())),
        takeUntil(action$.ofType(REQUEST_TIMED_OUT))
      )
    )
  );
const loadProfileEpic = action$ =>
  action$.pipe(
    // ofType(USER_PROFILE_NEEDS_LOAD, REFRESH_TOKEN_SUCCESS, SYNC_RUN_SUCCESS),
    ofType(USER_PROFILE_NEEDS_LOAD),
    switchMap(() =>
      apiService.sendRequest('account/', 'GET').pipe(
        map(({ data }) => {
          if (data && data.id) {
            return userProfileLoadSuccess(data);
          }
          return userProfileLoadFailed(data);
        }),
        catchError(err => of(userProfileLoadFailed(err)))
      )
    )
  );

const userProfileNeedsUpdateEpic = action$ =>
  action$.pipe(
    ofType(USER_PROFILE_NEEDS_UPDATE),
    switchMap(({ payload }) =>
      apiService.sendRequest('account/', 'PATCH', payload).pipe(
        delay(1000),
        map(({ data }) => {
          if (data) {
            return userProfileUpdateSuccess(data);
          }
          return userProfileUpdateFailed(data);
        }),
        catchError(err => of(userProfileUpdateFailed(err)))
      )
    )
  );
export const userEpics = combineEpics(
  loginEpic,
  loginExpiredEpic,
  registerEpic,
  refreshTokenEpic,
  submitPasswordResetRequestEpic,
  submitPasswordResetConfirmEpic,
  loadProfileEpic,
  userProfileNeedsUpdateEpic
);
export default user;
