import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import user, { userEpics } from './user'; // eslint-disable-line
import door, { doorEpics } from './door'; // eslint-disable-line
import collar, { collarEpics } from './collar'; // eslint-disable-line

export const rootEpic = combineEpics(userEpics, doorEpics, collarEpics);

export const rootReducer = combineReducers({
  user,
  door,
  collar
});
