import { BehaviorSubject } from 'rxjs';

import {
  doorStatusUpdated,
  doorPongReceived,
  gotHWInfo,
  gotDoorPower,
  gotDoorACVoltage,
  gotDoorBattery,
  gotNotifications,
  gotTimersEnabled,
  gotScheduleList,
  setScheduleSuccess,
  deleteScheduleSuccess,
  doorGoingToLowPower,
  firmwareUpdateAvailable,
  firmwareUpdateStarted,
  firmwareUpdateSuccess,
  firmwareUpdateFailed,
  gotSchedule,
  gotDoorName,
  gotSettings,
  gotHoldTime,
  gotSensors,
  gotSensorTriggerVoltage,
  gotSleepSensorTriggerVoltage,
  gotWakeupCountThreshold,
  gotNoEnergyCountThreshold,
  gotTriggerValidCount,
  gotTimeC,
  gotTimeD,
  gotTimeF,
  insideChanged,
  outsideChanged,
  gotTimezone,
  gotTime,
  gotDoorHasRemoteID,
  setDoorRemoteIDSucess,
  gotDoorHasRemoteKey,
  setDoorRemoteKeySucess,
  gotDoorUseBetaFirmware,
  checkResetReasonReceived,
  invalidMessage,
  unknownMessage
} from '../redux/modules/door';

import { store } from '../App';

// eslint-disable no-fallthrough

const getResponseToMessage = (doorId, msg) => {
  const { door } = store.getState();
  const { promptedFirmwareUpdate } = door;

  if (!doorId || !msg.CMD || msg.success === undefined || msg.dir !== 'd2p') {
    return new BehaviorSubject(invalidMessage());
  }
  let response = invalidMessage(msg);
  switch (msg.CMD) {
    case 'PONG':
      if (msg.PONG) {
        response = doorPongReceived(new Date().valueOf());
      }
      break;
    case 'GET_DOOR_STATUS':
    case 'DOOR_STATUS':
      if (msg.door_status) {
        if (msg.delta) {
          response = doorStatusUpdated(doorId, msg.door_status, msg.delta);
        } else {
          response = doorStatusUpdated(doorId, msg.door_status, 0);
        }
      }
      break;
    case 'GET_HW_INFO':
      if (msg.fwInfo) {
        response = gotHWInfo(doorId, msg.fwInfo);
      }
      break;
    case 'SET_DOOR_NAME':
    case 'GET_DOOR_NAME':
      if (msg.name) {
        response = gotDoorName(doorId, msg.name);
      }
      break;
    case 'SET_HOLD_TIME':
    case 'GOT_HOLD_TIME':
      if (msg.holdTime) {
        response = gotHoldTime(doorId, msg.holdTime);
      }
      break;
    case 'DOOR_GOING_TO_LOW_POWER':
      if (msg.minutes !== undefined) {
        response = doorGoingToLowPower(doorId, msg.minutes);
      }
      break;

    case 'FIRMWARE_UPDATE_AVAILABLE':
      if (!promptedFirmwareUpdate) {
        response = firmwareUpdateAvailable(doorId);
      }
      break;
    // all messages that return settings
    case 'ENABLE_OUTSIDE_SENSOR_SAFETY_LOCK':
    case 'DISABLE_OUTSIDE_SENSOR_SAFETY_LOCK':
    case 'ENABLE_CMD_LOCKOUT':
    case 'DISABLE_CMD_LOCKOUT':
    case 'ENABLE_AUTORETRACT':
    case 'DISABLE_AUTORETRACT':
    case 'GET_SETTINGS':
      if (msg.settings) {
        const {
          inside,
          outside,
          holdOpenTime,
          timersEnabled,
          power_state, // eslint-disable-line
          tz,
          outsideSensorSafetyLock,
          allowCmdLockout,
          doorOptions,
          sensorTriggerVoltage,
          sleepSensorTriggerVoltage
        } = msg.settings;
        response = gotSettings(doorId, {
          powerOn: power_state === 'true', // eslint-disable-line
          insideOn: inside === 'true',
          outsideOn: outside === 'true',
          holdOpenTime,
          timersEnabled: timersEnabled === 'true',
          timezone: tz,
          outsideSensorSafetyLock: outsideSensorSafetyLock === 'true',
          allowCmdLockout: allowCmdLockout === 'true',
          doorOptions,
          sensorTriggerVoltage,
          sleepSensorTriggerVoltage
        });
      }
      break;
    case 'ENABLE_LOW_BATTERY_NOTIFICATIONS':
    case 'DISABLE_LOW_BATTERY_NOTIFICATIONS':
    case 'ENABLE_SENSOR_ON_INDOOR_NOTIFICATIONS':
    case 'ENABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS':
    case 'DISABLE_SENSOR_ON_INDOOR_NOTIFICATIONS':
    case 'DISABLE_SENSOR_OFF_INDOOR_NOTIFICATIONS':
    case 'ENABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS':
    case 'ENABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS':
    case 'DISABLE_SENSOR_ON_OUTDOOR_NOTIFICATIONS':
    case 'DISABLE_SENSOR_OFF_OUTDOOR_NOTIFICATIONS':
    case 'GET_NOTIFICATIONS':
    case 'SET_NOTIFICATIONS':
      if (msg.notifications) {
        const {
          sensorOnIndoorNotificationsEnabled,
          sensorOffIndoorNotificationsEnabled,
          sensorOnOutdoorNotificationsEnabled,
          sensorOffOutdoorNotificationsEnabled,
          lowBatteryNotificationsEnabled
        } = msg.notifications;

        response = gotNotifications(doorId, {
          sensorOnIndoorNotificationsEnabled:
            sensorOnIndoorNotificationsEnabled === 'true',
          sensorOffIndoorNotificationsEnabled:
            sensorOffIndoorNotificationsEnabled === 'true',
          sensorOnOutdoorNotificationsEnabled:
            sensorOnOutdoorNotificationsEnabled === 'true',
          sensorOffOutdoorNotificationsEnabled:
            sensorOffOutdoorNotificationsEnabled === 'true',
          lowBatteryNotificationsEnabled:
            lowBatteryNotificationsEnabled === 'true'
        });
      }
      break;
    case 'GET_POWER':
    case 'POWER_ON':
    case 'POWER_OFF':
      if (msg.power_state) {
        response = gotDoorPower(doorId, msg.power_state === 'true');
      }
      break;
    case 'GET_AC_VOLTAGE':
      if (msg.acVoltage) {
        response = gotDoorACVoltage(doorId, msg.acVoltage / 0.141630901287554);
      }
      break;
    case 'GET_DOOR_BATTERY':
      if (msg.batteryPercent !== undefined) {
        response = gotDoorBattery(
          doorId,
          msg.batteryPercent,
          msg.acPresent === 'true',
          msg.batteryPresent === 'true'
        );
      }
      break;
    case 'ENABLE_TIMERS':
    case 'DISABLE_TIMERS':
    case 'GET_TIMERS_ENABLED':
      if (msg.timersEnabled) {
        response = gotTimersEnabled(doorId, msg.timersEnabled === 'true');
      }
      break;
    case 'GET_SCHEDULE_LIST':
      if (msg.schedules) {
        response = gotScheduleList(doorId, msg.schedules);
      }
      break;
    case 'GET_SCHEDULE':
      if (msg.schedule) {
        response = gotSchedule(doorId, msg.schedule);
      }
      break;
    case 'SET_SCHEDULE':
      // console.log('got a schedule back:', msg.schedule);
      if (msg.schedule) {
        response = setScheduleSuccess(doorId, msg.schedule);
      }
      break;
    case 'DELETE_SCHEDULE':
      if (msg.index != null) {
        response = deleteScheduleSuccess(doorId, msg.index);
      }
      break;
    case 'GET_SENSORS':
      if (msg.inside !== undefined && msg.outside !== undefined) {
        response = gotSensors(doorId, {
          inside: msg.inside === 1,
          outside: msg.outside === 1
        });
      }
      break;
    case 'SET_SENSOR_TRIGGER_VOLTAGE':
    case 'GET_SENSOR_TRIGGER_VOLTAGE':
      if (msg.sensorTriggerVoltage) {
        response = gotSensorTriggerVoltage(doorId, msg.sensorTriggerVoltage);
      }
      break;
    case 'SET_SLEEP_SENSOR_TRIGGER_VOLTAGE':
    case 'GET_SLEEP_SENSOR_TRIGGER_VOLTAGE':
      if (msg.sleepSensorTriggerVoltage) {
        response = gotSleepSensorTriggerVoltage(
          doorId,
          msg.sleepSensorTriggerVoltage
        );
      }
      break;
    case 'SET_WAKEUP_COUNT_THRESHOLD':
    case 'GET_WAKEUP_COUNT_THRESHOLD':
      if (msg.wakeupCountThreshold) {
        response = gotWakeupCountThreshold(doorId, msg.wakeupCountThreshold);
      }
      break;
    case 'SET_NO_ENERGY_COUNT_THRESHOLD':
    case 'GET_NO_ENERGY_COUNT_THRESHOLD':
      if (msg.noEnergyCountThreshold) {
        response = gotNoEnergyCountThreshold(
          doorId,
          msg.noEnergyCountThreshold
        );
      }
      break;
    case 'SET_TRIGGER_VALID_COUNT':
    case 'GET_TRIGGER_VALID_COUNT':
      if (msg.triggerValidCount) {
        response = gotTriggerValidCount(doorId, msg.triggerValidCount);
      }
      break;
    case 'SET_TIME_C':
    case 'GET_TIME_C':
      if (msg.timeC) {
        response = gotTimeC(doorId, msg.timeC);
      }
      break;
    case 'SET_TIME_D':
    case 'GET_TIME_D':
      if (msg.timeD) {
        response = gotTimeD(doorId, msg.timeD);
      }
      break;
    case 'SET_TIME_F':
    case 'GET_TIME_F':
      if (msg.timeF) {
        response = gotTimeF(doorId, msg.timeF);
      }
      break;
    case 'ENABLE_INSIDE':
    case 'DISABLE_INSIDE':
      if (msg.inside !== undefined) {
        response = insideChanged(doorId, msg.inside === 1);
      }
      break;
    case 'ENABLE_OUTSIDE':
    case 'DISABLE_OUTSIDE':
      if (msg.outside !== undefined) {
        response = outsideChanged(doorId, msg.outside === 1);
      }
      break;
    case 'SET_TIMEZONE':
    case 'GET_TIMEZONE':
      if (msg.tz) {
        response = gotTimezone(doorId, msg.tz);
      }
      break;
    case 'GET_TIME':
      if (msg.time) {
        response = gotTime(doorId, msg.time);
      }
      break;
    case 'HAS_REMOTE_ID':
      if (msg.has_id !== undefined) {
        response = gotDoorHasRemoteID(doorId, msg.has_id === 'true');
      }
      break;
    case 'SET_DOOR_ID':
      if (msg.success === 'true') {
        response = setDoorRemoteIDSucess(doorId);
      }
      break;
    case 'HAS_REMOTE_KEY':
      if (msg.has_key !== undefined) {
        response = gotDoorHasRemoteKey(doorId, msg.has_key === 'true');
      }
      break;
    case 'SET_USE_BETA_FIRMWARE':
    case 'GET_USE_BETA_FIRMWARE':
      if (msg.useFirmwareBetas !== undefined) {
        response = gotDoorUseBetaFirmware(
          doorId,
          msg.useFirmwareBetas === 'true'
        );
      }
      break;
    case 'SET_MQTT_KEY':
      if (msg.success === 'true') {
        response = setDoorRemoteKeySucess();
      }
      break;
    case 'CHECK_RESET_REASON':
      if (msg.resetReason !== undefined) {
        response = checkResetReasonReceived(doorId, msg.resetReason);
      }
      break;
    case 'START_FIRMWARE_UPDATE':
      if (msg.success === 'true') {
        response = firmwareUpdateStarted(door);
      } else if (msg.success === 'false') {
        response = firmwareUpdateFailed(doorId);
      }
      break;
    case 'FIRMWARE_STATUS':
      if (msg.success === 'true') {
        response = firmwareUpdateSuccess(doorId);
      } else if (msg.success === 'false') {
        response = firmwareUpdateFailed(doorId);
      }
      break;
    default:
      response = unknownMessage(msg);
      break;
  }
  return new BehaviorSubject(response);
};

export default getResponseToMessage;
