// @format
/* @flow */
import { connect } from 'react-redux';

import Activity from './Activity';

import { activityNeedsLoad } from '../../redux/modules/collar';

const mapStateToProps = ({ user, collar }) => ({
  user,
  collar
});
const mapDispatchToProps = dispatch => ({
  activityNeedsLoad: (dogId, interval, date) =>
    dispatch(activityNeedsLoad(dogId, interval, date))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activity);
