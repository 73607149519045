// @format
/* @flow */
import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// type Props = {};

const Home = () => (
  <Container>
    <Typography variant="h4">Homepage</Typography>
  </Container>
);

export default Home;
