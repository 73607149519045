// @format
/* @flow */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import RemoteContainer from './RemoteContainer';
import SettingsContainer from './SettingsContainer';

import history from '../../history';

type Props = {
  door: DoorState,
  // user: UserState,
  match: Match,
  // door: Door,
  selectDoor: typeof selectDoor,
  deselectDoor: typeof actionCreatorNoParams,
  getDoorInfo: typeof actionCreatorNoParams
};
const useStyles = makeStyles(theme => ({
  backButton: {
    flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  locationWrap: {
    marginBottom: 20
  }
}));

const Detail = ({
  door,
  match: { params },
  selectDoor,
  deselectDoor,
  getDoorInfo
}: Props) => {
  const classes = useStyles();
  const { id } = params;
  const { doors, waitingResponse, fetchingInfo, currentDoorOpenStatus } = door;
  const [selectedDoor] = doors.filter(d => d.id === id);

  const selectedDoorStatus = currentDoorOpenStatus[selectedDoor.id];

  const [timerHandle, updateTimerHandle] = useState(null);
  const [waitingResponseWithTime, updateWaitingResponseWithTime] = useState(
    false
  );

  useEffect(() => {
    const getDoorInfoTimer = setTimeout(() => {
      getDoorInfo();
    }, 1000);
    selectDoor(selectedDoor);
    // set a timer for
    return () => {
      deselectDoor();
      clearTimeout(getDoorInfoTimer);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!waitingResponse && !fetchingInfo) {
      clearTimeout(timerHandle);
      const waitingTimer = setTimeout(() => {
        updateWaitingResponseWithTime(false);
      }, 500);
      updateTimerHandle(waitingTimer);
    } else {
      updateWaitingResponseWithTime(true);
    }
    return () => {
      clearTimeout(timerHandle);
    };
  }, [waitingResponse, fetchingInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2} sm={1}>
          <IconButton
            color="inherit"
            aria-label="Back"
            onClick={() => history.goBack()}
            edge="start"
            className={classes.backButton}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h5">{selectedDoor.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justify="space-between"
          >
            {fetchingInfo && waitingResponseWithTime && (
              <>
                <Typography>Fetching door settings...</Typography>
                <CircularProgress className={classes.progress} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        alignItems="center"
        className={classes.locationWrap}
      >
        <Grid item xs={6} sm={4}>
          <Typography>{selectedDoor.location}</Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography>{selectedDoor.roomName}</Typography>
        </Grid>
      </Grid>
      <RemoteContainer
        selectedDoor={selectedDoor}
        doorStatus={selectedDoorStatus}
      />
      <SettingsContainer
        selectedDoor={selectedDoor}
        waitingResponse={waitingResponseWithTime}
      />
    </>
  );
};

export default Detail;
