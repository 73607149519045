// @format
/* @flow */
import { connect } from 'react-redux';

import Bluefang from './Bluefang';

import {
  selectCollar,
  deselectCollar,
  collarsNeedLoad
} from '../../redux/modules/collar';

const mapStateToProps = ({ user, collar }) => ({
  user,
  collar
});
const mapDispatchToProps = dispatch => ({
  selectCollar: (collar: Collar) => dispatch(selectCollar(collar)),
  deselectCollar: () => dispatch(deselectCollar()),
  collarsNeedLoad: () => dispatch(collarsNeedLoad())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bluefang);
